import React, { useState, useEffect, useRef } from 'react';

import ConfettiExplosion from 'react-confetti-explosion';

import { Balloon } from "./components/Balloon"
import { Input } from "./components/Input"
import { Menu } from "./components/Menu"
import { Header } from "./components/Header"
import { Appro } from "./components/Appro"
import { Debug } from './components/Debug'
import { Time } from "./components/Time"


import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/scss/App.scss";

import { startGame, updateState, checkState, setNickname, getNewMaster, getStats, validateTicket} from './services/UserService'

const App = () => {
  const [delay, setDelay] = useState(5000);
  const [serverToBeUpdated, setServerToBeUpdated] = useState(false);

  let [state, setState] = React.useState({
    uuid: '',
    masterkey: '',
    waitingApi: false,
    lang: "ita",
    accessible: false,
    menuopen: false,
    ttw: 500,
    face: "wait",

    extraopen: false, extracontent: '',

    mission: 9, temp_mission: 9, // usato nel login prima di entrare // 9 e 9

    answer: '',
    answer2: '',
    answer3: '',
    answer4: '',
    answerfeedback: [], // ["w","r","r","w"]

    ticket: '', // manually insert code
    nick: '',
    loginfeedback: false,
    points: 100, increment: 0,

    input: false,

    stats: [],
    starttime: 0,
    endtime: 0,

    suggestions : {
/* 1 */  'u_1_m1' : ['h_1'],
         'u_2_m1' : ['h_2', 'h_3'],
/* 2 */  'u_2_m2' : ['h_1', 'h_2'],
/* 3 */  'u_1_m3' : ['h_1', 'h_2'],
/* 4 */  'u_2_m4' : ['h_1', 'h_2', 'h_3'],
         'u_4_m4' : ['h_4', 'h_5'],
/* 5 */  'u_2_m5' : ['h_1', 'h_2'],
         'u_3_m5' : ['h_3'],
/* 6 */  'u_2_m6' : ['h_1', 'h_2', 'h_3'],
/* 7 */  'u_2_m7' : ['h_1', 'h_2'],
         'u_4_m7' : ['h_3'],
         'u_5_m7' : ['h_4'],
         'u_6_m7' : ['h_5'],
         'u_7_m7' : ['h_6'],
/* 8 */  'u_1_m8' : ['h_1'],
         'u_2_m8' : ['h_2', 'h_3']
    },

    tempChatHistory: [
      [], // 0. intro
      [], // 1. mission 1
      [], // 2. mission 2
      [], // 3. mission 3
      [], // 4. mission 4
      [], // 5. mission 5
      [], // 6. mission 6
      [], // 7. mission 7
      [], // 8. mission 8
      [], // 9. homa page
      [], // 10. finale
      []  // 11. Timeout
   ],

    chatHistory: [
      [], // 0. intro
      [], // 1. mission 1
      [], // 2. mission 2
      [], // 3. mission 3
      [], // 4. mission 4
      [], // 5. mission 5
      [], // 6. mission 6
      [], // 7. mission 7
      [], // 8. mission 8
      [], // 9. homa page
      [], // 10. finale
      []  // 11. Timeout
   ],

   approAvailable : [false, false, false, false, false, false, false, false, false, false, false],
   missionsDone: [false, false, false, false, false, false, false, false, false],

   chatRoads : [
    /* INTRO */   ["m_1", "u_1", "m_2a", "m_2", "m_3", "m_4", "u_1b", "m_5", "m_6", "m_7", "m_8", "u_2", "m_9", "m_99"],

    /* 1 */  ["m_0a", "m_0b", "m_0c", "m_1", "u_1", "m_2", "m_2a", "m_3", "m_4", "u_2", "f_1", "m_51", "u_8", "m_99"],
    /* 2 */  ["m_0a", "m_0b", "m_0c", "m_1", "m_2", "u_0a", "m_3", "m_3a", "m_4", "u_0b", "m_5", "m_6", "u_1", "m_7", "m_8", "m_10", "u_2", "f_1", "m_51", "u_8", "m_99"],
    /* 3 */  ["m_0a", "m_0b", "m_1", "m_2", "u_0a", "m_3", "m_4", "m_5", "u_0b", "m_6", "m_7", "m_8", "m_9", "u_0", "m_10", "m_10a", "m_11", "u_1", "f_1", "m_51", "u_8", "m_99"],
    /* 4 */  ["m_0a", "m_0b", "m_0c", "m_1", "u_1", "m_2", "m_3", "u_2", "f_1", "m_53", "u_10", "m_4", "m_5", "m_5a", "m_6", "u_3", "m_7", "m_8", "m_9", "u_4", "f_2", "m_51", "u_8", "m_99"],
    /* 5 */  ["m_0a", "m_0b", "m_0c", "m_1", "m_2", "m_3", "u_1", "m_4a", "m_4", "m_5", "m_6", "m_7", "m_8", "u_1b", "m_9a", "m_9b", "u_2", "f_1", "m_10", "m_11", "u_3", "f_2", "m_51", "u_8", "m_99"],
    /* 6 */  ["m_0a", "m_0b", "m_0c", "m_1", "u_1", "m_2a", "m_2", "m_3", "u_2", "f_1", "m_51", "u_8", "m_99"],
    /* 7 */  ["m_0a", "m_0b", "m_0c", "m_1", "u_1", "m_2", "m_2a", "m_3", "u_1a", "m_4", "m_5", "u_2", "f_1", "m_53", "u_10", "m_6", "m_6b", "u_3", "m_7b", "m_7c", "m_8", "u_4", "f_2", "m_54", "u_11", "m_9a", "m_9", "u_5", "m_10", "m_11", "u_5a", "m_12", "m_13", "u_6", "m_14", "m_15", "u_7", "f_3", "m_51", "u_8", "m_99"],
    /* 8 */  ["m_0a", "m_0b", "m_1", "u_1", "m_2a", "m_2", "u_2", "f_1", "m_51", "u_8", "m_99"],

    /* 9.HOME */  ["m_0a", "m_0b", "m_1", "u_1", "m_2", "u_2", "i_1"],
    /* 10.FINALE */  ["m_0", "m_1", "u_0", "m_4", "m_5", "m_6", "m_7", "m_8", "m_9", "u_3", "m_3", "u_1", "m_2", "u_2", "m_29", "m_10"],
    /* 11.TIMEOUT */  ["m_0", "u_0", "m_1", "u_1", "m_29"]
   ]
  })

  // w => wait
  // z => wait in suggestion cycle
  // m => meta
  // u => user
  // h => help asked
  // s => suggestion given
  // f => eureka
  // i => placeholder to insert something

  // const conteOpened = ["m_7a", "m_8", "u_4", "f_2", "m_54", "u_11", "m_9a", "m_9", "u_5", "m_10", "m_11", "u_5a", "m_12", "m_13", "u_6", "m_14", "m_15", "u_7", "f_3", "m_51", "u_8", "m_99"];
  // const conteClosed = ["m_7b", "m_7c", "m_8", "u_4", "f_2", "m_54", "u_11", "m_9a", "m_9", "u_5", "m_10", "m_11", "u_5a", "m_12", "m_13", "u_6", "m_14", "m_15", "u_7", "f_3", "m_51", "u_8", "m_99"];

  const chatFragmentGest = ["m_3", "u_3", "i_2"];
  const chatFragmentGestOk = ["m_4", "u_4"];
  const chatFragmentGestWannabe = ["m_5", "u_4"];
  const chatFragmentGestWannabeNoNick = ["m_6", "u_5", "m_7", "u_4"];
  const chatFragmentMaster = ["m_6", "u_5", "m_7", "u_4"];
  const chatFragmentMasterNoMore = ["m_7b"];


  // gli step che devo seguire quando chiedo un suggerimento (ci entro in handleSuggest)
  const stepsSuggestion = [
         [],
/* 1 */  ["h_1", "s_1", "u_1", "h_2", "s_2", "u_2", "h_3", "s_3", "u_2"],
/* 2 */  ["h_1", "s_1", "u_2", "h_2", "s_2", "u_2"],
/* 3 */  ["h_1", "s_1", "u_1", "h_2", "s_2", "u_1"],
/* 4 */  ["h_1", "s_1", "u_2", "h_2", "s_2", "u_2", "h_3", "s_3", "u_2", "h_4", "s_4", "u_4", "h_5", "s_5", "u_4"],
/* 5 */  ["h_1", "s_1", "u_2", "h_2", "s_2", "u_2", "h_3", "s_3", "u_3"],
/* 6 */  ["h_1", "s_1", "u_2", "h_2", "s_2", "u_2", "h_3", "s_3", "u_2"],
/* 7 */  ["h_1", "s_1", "u_2", "h_2", "s_2", "u_2", "h_3", "s_3", "u_4", "h_4", "s_4", "u_5", "h_5", "s_5", "u_6", "h_6", "s_6", "u_7"],
/* 8 */  ["h_1", "s_1", "u_1", "h_2", "s_2", "u_2", "h_3", "s_3", "u_2"]
  ];

  const body = document.body;
  let ttwStandard = 500; // time to wait
  let ttwWait = 500;

  let nextStep; // used in
  let prevStep; // used in
  let newChatHistory;
  let newChatHistoryW;
  let nextFace;
  let nextTtw;

  const containerRef = useRef(null)
  const uuidRef = useRef(state.uuid);
  let intervalRef = useRef(null);
  let intervalCheckRef = useRef(null);
  let intervalRefW = useRef(null);

  uuidRef.current = state.uuid;

  const answers= {
    u_2_mission_1: ["80"],
    u_2_mission_2: ["155-d1421", "155 - d1421", "155 – d1421", "155–d1421"],
    u_1_mission_3: [["giallo", "yellow", "jaune"], ["rosso", "red", "rouge"], ["bianco", "white", "blanc"]],
    u_2_mission_4: ["tchou", "t c h o u"],
    u_4_mission_4: [["wd", "w d", "ar", "a r"], ["ar", "a r", "wd", "w d"]],
    u_2_mission_5: ["12"],
    u_3_mission_5: ["5"],
    u_2_mission_6: [["power distributor", "powerdistributor"],["water accumulator", "wateraccumulator"],["+28v battery", "+28 v battery", "+28vbattery"],["gb heat exchanger", "gbheatexchanger"]],
    u_2_mission_7: ["nodo piano", "nodopiano", "piano", "square knot", "squareknot", "square", "noeud plat", "noeudplat", "plat", "nœud plat", "nœudplat"],
    u_4_mission_7: ["3"],
    u_7_mission_7: ["1", "01", "n 01", "n. 01"],
    u_2_mission_8: ["ab", "a b", "a,b", "a, b"]
  }
  const newOrder = [7,3,5,2,6,1,8,4]

  const allAppro = {
    m_7_f_1 : 'ebe',
    m_7_f_2 : 'biancamano',
    m_7_f_3 : 'convertiplano',
    m_3_f_1 : 'v2',
    m_5_f_2 : 'toti',
    m_2_f_1 : 'kreched',
    m_6_f_1 : 'apollo11',
    m_1_f_1 : 'icosaedro',
    m_8_f_1 : 'filettatrice',
    m_4_f_1 : 'programma101',
    m_4_f_2 : 'elea'
  }

  const singleAnswers= ["u_2_mission_1", "u_2_mission_2", "u_2_mission_4", "u_2_mission_5", "u_3_mission_5", "u_2_mission_7", "u_4_mission_7", "u_7_mission_7", "u_2_mission_8"];
  const multiple2Answers= ["u_4_mission_4"];
  const multiple3Answers= ["u_1_mission_3"];
  const multiple4Answers= ["u_2_mission_6"];

  const getNewChatHistory = (starting, ind, ns, rl = false) => {
     //rl = remove last (if prev is w or z)
     let chatHistoryVar;
     if (rl){
        chatHistoryVar = starting.map(function (nested, index) {
           if (index != ind){
               return nested;
           }
           else {
               return nested.map(function (element) {
                  if (element === getLastArray(starting[index])) {
                       return ns;
                    } else {
                       return element;
                  }
               });
           }
        })
     }

     else{
           chatHistoryVar = starting.map(function (nested, index) {
           if (ind != index){
               return nested;
           }
           else {
               return [
                 ...starting[index],
                 ns
              ]
           }
        })
     }
     return chatHistoryVar;
 }

  const handleAccessibleClick = () => {
     handleToggleMenu();
     setState(prevState => ({
      ...prevState,
      accessible: !state.accessible
     }));
  }

  const handleSuggest = (e, i = null) => {
      e.stopPropagation();

      let decrement_points = ((i === "h_1" && state.mission == 1) ||
                              (i === "h_1" && state.mission == 3) ||
                              (i === "h_1" && state.mission == 4) ||
                              (i === "h_1" && state.mission == 6) ||
                              (i === "h_4" && state.mission == 7) ||
                              (i === "h_5" && state.mission == 7) ||
                              (i === "h_1" && state.mission == 8) ||
                              (i === "h_2" && state.mission == 8)) ? 10 :
                             ((i === "h_1" && state.mission == 2) ||
                              (i === "h_2" && state.mission == 1) ||
                              (i === "h_4" && state.mission == 4) ||
                              (i === "h_1" && state.mission == 5) ||
                              (i === "h_3" && state.mission == 5) ||
                              (i === "h_1" && state.mission == 7) ||
                              (i === "h_3" && state.mission == 7)) ? 20 :
                             ((i === "h_2" && state.mission == 3) ||
                              (i === "h_2" && state.mission == 4) ||
                              (i === "h_2" && state.mission == 6) ||
                              (i === "h_3" && state.mission == 8)) ? 30 :
                             ((i === "h_3" && state.mission == 1) ||
                              (i === "h_2" && state.mission == 2) ||
                              (i === "h_3" && state.mission == 4) ||
                              (i === "h_5" && state.mission == 4) ||
                              (i === "h_2" && state.mission == 5) ||
                              (i === "h_3" && state.mission == 6) ||
                              (i === "h_2" && state.mission == 7) ||
                              (i === "h_6" && state.mission == 7)) ? 50 : 100; // incremento di 1 se apro indizio 1, 2 per inidizio 2 e 3 per 3

      const new_p = state.points - decrement_points;

      let indSugg = `${state.input}_m${state.mission}`;
      let newSuggRoom = state.suggestions[indSugg].filter((val, index) => val != i)

      let newSuggestions = {
        ...state.suggestions,
        [indSugg]: newSuggRoom
      }

      let nextStep = i; // h_1 o h_2 ...

      let newChatHistory = getNewChatHistory(state.chatHistory, state.mission, nextStep);

      setState(prevState => ({
        ...prevState,
        chatHistory: newChatHistory,
        suggestions: newSuggestions,
        input: false,
        points: new_p,
        increment: -decrement_points,
        answerfeedback: []
      }));
  };

  const handleChangeAnswer = (e, i = 1) => {
    e.persist();
    let r1 = (i === 1) ? e.target.value : state.answer;
    let r2 = (i === 2) ? e.target.value : state.answer2;
    let r3 = (i === 3) ? e.target.value : state.answer3;
    let r4 = (i === 4) ? e.target.value : state.answer4;
    setState(prevState => ({
      ...prevState,
      answer: r1,
      answer2: r2,
      answer3: r3,
      answer4: r4,
      answerfeedback: []
    }));
  }

  const handleClickMenu = (e, newpage) => {
    if (state.menuopen === true) {
      handleToggleMenu();
    }

    if (state.extraopen === true) {
      //handleToggleExtra();
    }

    setState(prevState => ({
      ...prevState,
      page: newpage
    }));
  }

  const handleStartMission = () => {
     if (state.masterkey != 'guest'){
        handleChangeMission(state.temp_mission, true);
        // setState(prevState => ({
        //    ...prevState,
        //    input: false,
        //    chatHistory: state.tempChatHistory
        // }));
     }
     else{
        setState(prevState => ({
           ...prevState,
           input: false,
           waitingApi: true
        }));
     }
     setServerToBeUpdated(true);
  }

  const handleWannaBeMaster = (e, wanna) => {
     e.preventDefault();
     let newChatRoad;
     let newnick;
     newChatHistory = getNewChatHistory(state.chatHistory, state.mission, state.input);

     if (wanna == "masterMenu"){
        setState(prevState => ({
           ...prevState,
           waitingApi: true,
           menuopen: false
        }));

        getNewMaster(state.uuid)
          .then(response => {
             if (response.data){
                setState(prevState => ({
                   ...prevState,
                   masterkey: response.data.master_key,
                }));

                checkState(uuidRef.current).then(response => {
                  if (response.data.game_mission >= 0 && response.data.game_mission < 9){

                     // se last di response.data.chat[7] è in un ramo Conte
                     // ricompongo chatHistory e poi la imposto nello stato

                     // let newChatRoad = state.chatRoads;
                     //
                     // if(response.data.chat[7].includes("m_7a")){
                     //    newChatRoad = insertArrInBdimArr(state.chatRoads, "i_1", 7, conteOpened);
                     // }
                     // else if(response.data.chat[7].includes("m_7b")){
                     //    newChatRoad = insertArrInBdimArr(state.chatRoads, "i_1", 7, conteClosed);
                     // }


                     setState(prevState => ({
                       ...prevState,
                       waitingApi: false,
                       points: response.data.game_points,
                       chatHistory: response.data.chat,
                       // chatRoads: newChatRoad,
                       starttime: new Date(Date.now() - response.data.time*1000)
                    }));
                    handleChangeMission(response.data.game_mission);
                  }
               })
            }
          });
     }

     else if (wanna == "loginGuest"){
        newChatRoad = insertArrInBdimArr(state.chatRoads, "i_2", state.mission, chatFragmentGestOk);
        setState(prevState => ({
           ...prevState,
           chatHistory: newChatHistory,
           input: false
        }));
     }
     else if (wanna == "loginMaster"){
        setState(prevState => ({
           ...prevState,
           input: false
        }));

        if (state.nick === "" || state.nick === null || state.nick === undefined){
           newChatRoad = insertArrInBdimArr(state.chatRoads, "i_2", state.mission, chatFragmentGestWannabeNoNick);
           newnick = "";
        }
        else{
           newChatRoad = insertArrInBdimArr(state.chatRoads, "i_2", state.mission, chatFragmentGestWannabe);
           newnick = state.nick;
        }

        getNewMaster(state.uuid)
          .then(response => {

             let ttt = 0;
             if(response.data.game_mission) {
                ttt= response.data.game_mission;
             }

             let tch;
             if (response.data.chat.length <= 1){
                tch = state.tempChatHistory;
             }
             else{
                tch = response.data.chat;
             }

             // se last di response.data.chat è in un ramo Conte
             // ricompongo chatHistory e poi la imposto nellos tato
             // let newChatRoad = state.chatRoads;
             // if(response.data.chat[7].includes("m_7a")){
             //    newChatRoad = insertArrInBdimArr(state.chatRoads, "i_1", 7, conteOpened);
             // }
             // else if(response.data.chat[7].includes("m_7b")){
             //    newChatRoad = insertArrInBdimArr(state.chatRoads, "i_1", 7, conteClosed);
             // }


             setState(prevState => ({
                ...prevState,
                masterkey: response.data.master_key,
                points: response.data.game_points,
                chatHistory: newChatHistory,
                tempChatHistory: tch,
                temp_mission: ttt,
                // chatRoads: newChatRoad,
                nick: newnick
             }));
          });
     }
  }

  const handleToggleExtra = (event, content) => {

    if (state.waitingApi === false) {
      //disableMenu();

      if (state.extraopen === false) {
        setState(prevState => ({
          ...prevState,
          extraopen: true,
        }));

        body.classList.add("noscroll");

        setState(prevState => ({
          ...prevState,
          extracontent: content
        }));

        if (state.menuopen === true) {
          setState(prevState => ({
            ...prevState,
            menuopen: false
          }));
        }

      }
      else if (content !== undefined) {

        setState(prevState => ({
          ...prevState,
          extraopen: false,
        }));


        setTimeout(() => {
          setState(prevState => ({
            ...prevState,
            extraopen: true,
            extracontent: content
          }));
        }, 350);
      }

      else  {
        setState(prevState => ({
          ...prevState,
          extraopen: false,
        }));

        body.classList.remove("noscroll");

      }
    }
  }

  const handleToggleMenu = () => {

    if (state.video !== false) { // stoppo il video se ne sta andando uno
      setState(prevState => ({
        ...prevState,
        video: false
      }));
    }

   if (state.menuopen === false) {
     setState(prevState => ({
       ...prevState,
       menuopen: true,
     }));

     //body.classList.add("noscroll");

     if (state.extraopen !== false) {
       setState(prevState => ({
         ...prevState,
         extraopen: false
       }));
     }
   } else if (state.menuopen === true) {
     setState(prevState => ({
       ...prevState,
       menuopen: false,
     }));
     //body.classList.remove("noscroll");

    }
  }

  const scrollTop = () => {

     if (containerRef.current){

          const { offsetHeight, scrollHeight, scrollTop } = containerRef.current;

            let scrollRef = setTimeout(
              () => {
                   containerRef.current.scrollTo(0, 0);
              }, 0
            );
         // }
      }
  }

  const winningPoints = () => {
     let wp =
        (state.mission == 1 || state.mission == 2 || state.mission == 3 || state.mission == 4 || state.mission == 7) ? 100 :
        (state.mission == 5) ? 200 :
        (state.mission == 6 || state.mission == 8) ? 300 : 500;
     return wp;
 }

  const handleSubmitAnswer = (e, n, i) => {
    e.preventDefault();
    let nn = i + '_mission_' + n;
    let af = false;
    let newChatHistory;
    let new_input; //new input
    let new_p; //new points
    let increment_points;
    let lowerCaseAnswer = state.answer.toLowerCase().trim();
    let lowerCaseAnswer2 = state.answer2.toLowerCase().trim();
    let lowerCaseAnswer3 = state.answer3.toLowerCase().trim();
    let lowerCaseAnswer4 = state.answer4.toLowerCase().trim();

    if (singleAnswers.includes(nn)){
      if(lowerCaseAnswer == '' || lowerCaseAnswer == undefined) return;
      else if  (!answers[nn].includes(lowerCaseAnswer)){
         af = ["w"];
         new_p = state.points - 20;
         new_input = state.input;
         newChatHistory = getNewChatHistory(state.chatHistory, state.mission, `n_${state.answer}`);
      }
      else {
         af = ["r"];
         increment_points = winningPoints();
         new_p = state.points + increment_points;
         new_input = false;
         newChatHistory = getNewChatHistory(state.chatHistory, state.mission, state.input);
      }
    }
    else if (multiple2Answers.includes(nn)){
      if (lowerCaseAnswer == '' || lowerCaseAnswer == undefined ||
          lowerCaseAnswer2 == '' || lowerCaseAnswer2 == undefined ) return;
      else if
         (!answers[nn][0].includes(lowerCaseAnswer) ||
          !answers[nn][1].includes(lowerCaseAnswer2)) {
         af = ["r","r"];
         new_p = state.points - 20;
         new_input = state.input;

         if (!answers[nn][0].includes(lowerCaseAnswer)) af[0] = 'w';
         if (!answers[nn][1].includes(lowerCaseAnswer2)) af[1] = 'w';

         newChatHistory = getNewChatHistory(state.chatHistory, state.mission, `n_${state.answer} - ${state.answer2}`);

      }
      else{
         af = ["r","r"];
         new_input = false;
         increment_points = winningPoints();
         new_p = state.points + increment_points;
         newChatHistory = getNewChatHistory(state.chatHistory, state.mission, state.input);
      }
    }
    else if (multiple3Answers.includes(nn)){
      if (lowerCaseAnswer == '' || lowerCaseAnswer == undefined ||
          lowerCaseAnswer2 == '' || lowerCaseAnswer2 == undefined ||
          lowerCaseAnswer3 == '' || lowerCaseAnswer3 == undefined ) return;
      else if
         (!answers[nn][0].includes(lowerCaseAnswer) ||
          !answers[nn][1].includes(lowerCaseAnswer2) ||
          !answers[nn][2].includes(lowerCaseAnswer3)) {
         af = ["r","r","r"];
         new_p = state.points - 20;
         new_input = state.input;

         if (!answers[nn][0].includes(lowerCaseAnswer)) af[0] = 'w';
         if (!answers[nn][1].includes(lowerCaseAnswer2)) af[1] = 'w';
         if (!answers[nn][2].includes(lowerCaseAnswer3)) af[2] = 'w';

         newChatHistory = getNewChatHistory(state.chatHistory, state.mission, `n_${state.answer} - ${state.answer2} - ${state.answer3}`);
      }
      else{
         af = ["r","r","r"];
         new_input = false;
         increment_points = winningPoints();
         new_p = state.points + increment_points;
         newChatHistory = getNewChatHistory(state.chatHistory, state.mission, state.input);
      }
    }
    else if (multiple4Answers.includes(nn)){
      if (lowerCaseAnswer == '' || lowerCaseAnswer == undefined ||
          lowerCaseAnswer2 == '' || lowerCaseAnswer2 == undefined ||
          lowerCaseAnswer3 == '' || lowerCaseAnswer3 == undefined ||
          lowerCaseAnswer4 == '' || lowerCaseAnswer4 == undefined) return;
      else if
         (!answers[nn][0].includes(lowerCaseAnswer) ||
          !answers[nn][1].includes(lowerCaseAnswer2) ||
          !answers[nn][2].includes(lowerCaseAnswer3) ||
          !answers[nn][3].includes(lowerCaseAnswer4)){
         af = ["r","r","r","r"];
         new_p = state.points - 20;
         new_input = state.input;

         if (!answers[nn][0].includes(lowerCaseAnswer)) af[0] = 'w';
         if (!answers[nn][1].includes(lowerCaseAnswer2)) af[1] = 'w';
         if (!answers[nn][2].includes(lowerCaseAnswer3)) af[2] = 'w';
         if (!answers[nn][3].includes(lowerCaseAnswer4)) af[3] = 'w';

         newChatHistory = getNewChatHistory(state.chatHistory, state.mission, `n_${state.answer} - ${state.answer2} - ${state.answer3} - ${state.answer4}`);
      }
      else{
         af = ["r","r","r","r"];
         new_input = false;
         increment_points = winningPoints();
         new_p = state.points + increment_points;
         newChatHistory = getNewChatHistory(state.chatHistory, state.mission, state.input);
      }
    }

    setState(prevState => ({
      ...prevState,
      answer: '',
      answer2: '',
      answer3: '',
      answer4: '',
      answerfeedback: af,
      chatHistory: newChatHistory,
      input: new_input,
      points: new_p,
      increment: increment_points
    }));

    scrollTop();
  }

  const handleChangeLang  = (e, lang, login) => {
     e.preventDefault();
     if (lang == "ita" || lang == "eng" || lang == "fra"){
        setState(prevState => ({
         ...prevState,
         lang: lang,
         menuopen: false
       }));
    }
    if(login){
        newChatHistory = getNewChatHistory(state.chatHistory, state.mission, state.input);

        setState(prevState => ({
         ...prevState,
         chatHistory: newChatHistory,
         input: false
       }));
     }
  }

  const handleGoHaed = (e) => {
     e.preventDefault();
     newChatHistory = getNewChatHistory(state.chatHistory, state.mission, state.input);

     setState(prevState => ({
       ...prevState,
       chatHistory: newChatHistory,
       input: false
     }));
  };

  const handleConteAccessible = (e, open) => {

     e.preventDefault();

     if (open == false){
        newChatHistory = getNewChatHistory(state.chatHistory, state.mission, state.input+"#b");
     }

     else{
        newChatHistory = getNewChatHistory(state.chatHistory, state.mission, state.input+"#a");
     }

     setState(prevState => ({
       ...prevState,
       chatHistory: newChatHistory,
       input: false
     }));
  };

  const handleOpenAppro = (e, open) => {
     let newChatRoad;
     e.preventDefault();
     let nn = 'm_' + state.mission + '_' + getLastButOneArray(state.chatHistory[state.mission]);

     if (open == false){
        newChatHistory = getNewChatHistory(state.chatHistory, state.mission, state.input+"#b");
     }

     else{
        newChatHistory = getNewChatHistory(state.chatHistory, state.mission, state.input+"#a");

        if (state.chatRoads[state.mission].indexOf("u_98") === -1){
           newChatRoad = insertArrInBdimArr(state.chatRoads, state.input, state.mission, [state.input, "u_98"]);
        }
        else if (state.chatRoads[state.mission].indexOf("u_97") === -1){
           newChatRoad = insertArrInBdimArr(state.chatRoads, state.input, state.mission, [state.input, "u_97"]);
        }
        else if (state.chatRoads[state.mission].indexOf("u_96") === -1){
           newChatRoad = insertArrInBdimArr(state.chatRoads, state.input, state.mission, [state.input, "u_96"]);
        }
        handleToggleExtra(e, allAppro[nn]);

        setState(prevState => ({
         ...prevState,
         chatRoads : newChatRoad
       }));
     }

     setState(prevState => ({
       ...prevState,
       chatHistory: newChatHistory,
       input: false
     }));
  };

  const handleSubmitNick = (e) => {
     e.preventDefault();

     if(state.nick === '' ){
      setState(prevState => ({
         ...prevState,
         nick: ''
      }));
      return;
     }

     newChatHistory = getNewChatHistory(state.chatHistory, state.mission, state.input);

     setState(prevState => ({
       ...prevState,
       waitingApi: true
     }))

     setNickname(state.uuid, state.nick, state.masterkey)
      .then(response => {
         if (response.data){
            setState(prevState => ({
               ...prevState,
               chatHistory: newChatHistory,
               input: false,
               waitingApi: false
           }))
        }
        else{
           let newChatRoad = insertArrInBdimArr(state.chatRoads, "m_7", state.mission, ["m_7b"]);
           setState(prevState => ({
             ...prevState,
             chatHistory: newChatHistory,
             input: false,
             waitingApi: false,
             chatRoads : newChatRoad,
             masterkey: "guest"
           }));
        }
     });
  }

  const handleChangeNick = (e) => {
      const re = /^[0-9\b]+$/;
      let result = e.target.value.replace(/[^A-Za-z0-9]/g, '');
      setState(prevState => ({
        ...prevState,
        nick: result
      }));
  }

  const timeoutGame = () => {
     clearTimeout(intervalRef.current);
     clearTimeout(intervalRefW.current);

     setServerToBeUpdated(false);
     // stoppo gli aggiornamenti con il server perché la partita è finita e mi tornerebbe errore
     setState(prevState => ({
       ...prevState,
       mission: 11,
       input: false
     }));
  }

  const handleWinGame = () => {
     validateTicket(state.uuid, state.points, state.masterkey)
     .then(response => {
       getStats().then(responseGS => {
          if (responseGS.data){
            setState(prevState => ({
              ...prevState,
              stats: responseGS.data,
              mission: 10,
              input: false
            }));
            setServerToBeUpdated(false);
          }
       });
     });
  }

  const handleChangeMission = (i, start) => {
    if (i == undefined) i = state.mission + 1;
    else if (i == -1) i = state.mission - 1;
    if (i == 10){
      getStats().then(responseGS => {
         if (responseGS.data){
           setState(prevState => ({
             ...prevState,
             stats: responseGS.data,
             mission: 10,
             input: false,
             waitingApi: false
           }));
           setServerToBeUpdated(false);
         }
      });
      return;
    }

    else if (i == 11){
      getStats().then(responseGS => {
         if (responseGS.data){
           setState(prevState => ({
             ...prevState,
             stats: responseGS.data,
             mission: 11,
             input: false,
             waitingApi: false
           }));
           setServerToBeUpdated(false);
         }
      });
      return;
    }

    else if (i == state.mission) {
      setState(prevState => ({
        ...prevState,
        menuopen: false
      }));
      return;
    }

    if (start){
      newChatHistory = removeAllWInBdimArr(state.tempChatHistory);
    }
    else{
      newChatHistory = removeAllWInBdimArr(state.chatHistory);
    }
    clearTimeout(intervalRef.current);
    clearTimeout(intervalRefW.current);

    setState(prevState => ({
      ...prevState,
      mission: i,
      input: false,
      chatHistory: newChatHistory,
      menuopen: false
    }));
  }

  const handleSubmitTicket = (e) => {

    e.preventDefault();

    if(state.ticket === '' ){
      setState(prevState => ({
        ...prevState,
        loginfeedback: "empty",
        ticket: ''
      }));
      return;
    }

    setState(prevState => ({
      ...prevState,
      waitingApi: true
    }));

    startGame(state.ticket)
     .then(response => {
        if(response.data && response.data.status === 'start_game') {
          let keyAPI;
          let newChatRoad;
          let newNick;

          if (response.data.guest == true ) { keyAPI = "guest"; }
          if (response.data.guest !== true ) { keyAPI = response.data.master_key}

          newChatHistory = getNewChatHistory(state.chatHistory, state.mission, state.input);

          if (keyAPI == "guest"){
             newChatRoad = insertArrInBdimArr(state.chatRoads, "i_1", state.mission, chatFragmentGest);
             newNick = response.data.nickname;
          }
          else {
             newChatRoad = insertArrInBdimArr(state.chatRoads, "i_1", state.mission, chatFragmentMaster);
             newNick = '';
          }

          let ttt = 0;
          if(response.data.game_mission) {
             ttt= response.data.game_mission;
          }

          setState(prevState => ({
             ...prevState,
             uuid: response.data.uuid,
             temp_mission: ttt,
             input: false,
             points: response.data.game_points,
             chatHistory: newChatHistory,
             chatRoads : newChatRoad,
             waitingApi: false,
             // ticket: '',
             nick: newNick,
             starttime: new Date(Date.now() - response.data.time*1000),
             endtime: 0,
             masterkey: keyAPI
          }));
        }
        else if(response.data && response.data.status === 'time_out'){
           handleChangeMission(11);
        }
        else if(response.data && response.data.status === 'validated'){
           handleChangeMission(10);
        }
        else {
          // codice sbagliato = non presente o eliminato
          setState(prevState => ({
          ...prevState,
          ticket: '',
          nick: '',
          loginfeedback: "code",
          waitingApi: false
         }));
        }
     });
  }

  const handleChangeTicket = (e) => {
      let ticket;
      if (e.target.name === 'code') {
          ticket = e.target.value;
      }
      setState(prevState => ({
        ...prevState,
        loginfeedback: false,
        ticket: ticket
      }));
  }

  const handleTimeIsOver = () => {
      //handleChangeMission(9)
      //validateTicket(state.uuid, state.points)
  }

  const handleStopTime = (time) => {
     //     clearTimeout(intervalRef.current);
     //     setState(prevState => ({
     //       ...prevState,
     //       endtime: time,
     //     }));
     //     validateTicket(state.uuid, state.points)
  }

  // in utility
  const nextInArray = (arr, val) => {
     let valInd = arr.indexOf(val);
     return arr[valInd + 1];
  }

  const getLastNotN = (arr) => {
    let indArr = arr.length-1;
    while(arr[indArr].startsWith("n_")){
      indArr -= 1;
    }
    return arr[indArr];
  }

  const getLastArray = (arr) => {
     return arr[arr.length - 1];
  }

  const getLastButOneArray = (arr) => {
     return arr[arr.length - 2];
  }

  const cleanHomeArrInBdimArr = (arr) => {
       arr[9] = [];
       return arr;
   }

  const insertArrInBdimArr = (arr, index_ele, ms, arr_to_insert) => {
     let stepsStandardMission = arr[ms];
     let indexPos = stepsStandardMission.indexOf(index_ele);
     if(indexPos === -1) return arr;

     stepsStandardMission.splice(indexPos, 1, ...arr_to_insert);
     arr[ms] = stepsStandardMission;
     return arr;
 }

  const removeAllWInBdimArr = arr => {
    for (let i = 0; i < arr.length; i++){
      if (arr[i].length > 0){
         if (getLastArray(arr[i]).startsWith("w_")){
            arr[i].pop();
         }
      }
   }
    return arr;
 }

  const removeLastInBdimArr = (arr, ms) => {
    let actualMission = arr[ms];
    actualMission.pop();
    arr[ms] = actualMission;
    return arr;
 }

  const updateApproAvailable = () => {
    let newAppro = [];
    state.approAvailable.map(function (nested, index) {

      // 0 1 2 3 4 5 6 7 8 9 10
      // 1 2 3 4 4 5 6 7 7 7 8

      let referredMission; // a quale missione corrisponde
      if (index === 0 || index === 1 || index === 2 || index === 3) referredMission = index+1;
      else if (index === 4 || index === 5 || index === 6 || index === 7) referredMission = index;
      else if (index === 8 || index === 9) referredMission = 7;
      else if (index === 10) referredMission = 8;
      if (state.chatHistory[referredMission]){ // se nella chat della missione c'è almeno uno step
         // controllo anche se si è raggiunto lo step f1 o f2 o f3
         if ((index === 0 || index === 1 || index === 2 || index === 3 || index === 5 ||
             index === 6 || index === 7 || index === 10) && state.chatHistory[referredMission].includes("f_1")){
              newAppro[index]= true;
         }

         else if ((index === 4 || index === 8) && state.chatHistory[referredMission].includes("f_2")){
              newAppro[index]= true;
         }

         else if (index === 9 && state.chatHistory[referredMission].includes("f_3")){
              newAppro[index]= true;
         }

         else{
            newAppro[index]= false;
         }
      }
   });

   setState(prevState => ({
      ...prevState,
      approAvailable: newAppro
    }));
 }

  const updateMissionsDone = () => {
    let newMissionsDone = [];

    state.missionsDone.map(function (nested, index) {
      if (state.chatHistory[index]){
         if (index === 4 && state.chatHistory[index].includes("f_2")){
            newMissionsDone[index]= true;
         }
         else if (index === 7 && state.chatHistory[index].includes("f_3")){
            newMissionsDone[index]= true;
         }
         else if (index != 7 && index != 4 && state.chatHistory[index].includes("f_1")){
              newMissionsDone[index]= true;
         }
         else{
            newMissionsDone[index]= false;
         }
      }
   });

   setState(prevState => ({
      ...prevState,
      missionsDone: newMissionsDone
   }));
 }

   const updateInterval = () => {
    if(state.masterkey == "guest"){
       checkState(uuidRef.current).then(response => {
          if (response.data && response.data.status === 'time_out'){
             handleChangeMission(11);
          }
          else if (response.data && response.data.status === 'validated'){
             // portami alla fine del gioco
             handleChangeMission(10);
          }
          else if (response.data && response.data.status === 'start_game'){

              if((JSON.stringify(response.data.chat) != JSON.stringify(state.chatHistory)) && response.data.chat.length !== 0){
                if (response.data.game_mission >= 0 && response.data.game_mission < 9){
                   setState(prevState => ({
                    ...prevState,
                    input: false,
                    waitingApi: false,
                    points: response.data.game_points,
                    chatHistory: response.data.chat,
                    starttime: new Date(Date.now() - response.data.time*1000),
                    mission: response.data.game_mission
                  }));
                  //handleChangeMission(response.data.game_mission);
               }
             }
           }
           else{
              //console.log("connessione persa ", response);
           }
      })
   }
   else{
      let newChatHistory = cleanHomeArrInBdimArr(state.chatHistory);

      updateState(state.uuid, state.mission, newChatHistory, state.points, state.masterkey).then(response => {
        if (response.data && response.data.status === 'time_out'){
           handleChangeMission(11);
        }
        else if (response.data && response.data.status === 'validated'){
           handleChangeMission(10);
        }
        else if (response.data && response.data.status === 'start_game' && response.data.guest === true){
           setState(prevState => ({
             ...prevState,
             masterkey: "guest",
             input: false
           }));
        }
        else if(response.data && response.data.status === 'start_game' && response.data.guest !== true){
           // TODO
           // setState(prevState => ({
           //   ...prevState,
           //   starttime: new Date(Date.now() - response.data.time*1000),
           // }));
        }
        else{
           //console.log("connessione persa ", response);
        }

      });
   }

}

  const goNextStep = () => {
     if(state.mission >= 0){
       if (state.chatHistory.length < state.mission || state.chatHistory[state.mission].length === 0) prevStep = "empty";
       else prevStep = getLastArray(state.chatHistory[state.mission]);

       if (prevStep.startsWith('n_')){
          setState(prevState => ({
              ...prevState,
              face: "sad"
           }));

           // clearTimeout(intervalRef.current);
           // // reimposto faccia standard in modo da riavere animazione header
           // intervalRef.current = setTimeout(
           //  () => {
           //    setState(prevState => ({
           //     ...prevState,
           //     face: "wait"
           //   }));
           // }, 1000);

          if (!state.input){
             prevStep = getLastNotN(state.chatHistory[state.mission]);
          }
          else {
             return;
          }
       }

       else if (prevStep.startsWith('w_') ||
                prevStep.startsWith('m_99') ||
               (prevStep.startsWith('m_10') && state.mission === 10) ||
               (prevStep.startsWith('m_29') && state.mission === 11)){
          return;
       }

       // prendo il passo successivo allo step attuale (l'ultimo dell'array state)
      if (prevStep.includes("#")) prevStep = prevStep.substring(prevStep.indexOf('#'), - 1);


      if (prevStep.startsWith('h_') || prevStep.startsWith('s_')){
         nextStep = nextInArray(stepsSuggestion[state.mission], prevStep);

         if(nextStep.startsWith('s_')){
            newChatHistoryW = getNewChatHistory(state.chatHistory, state.mission, "w_");
            newChatHistory = getNewChatHistory(state.chatHistory, state.mission, nextStep);
         }
         else{
            newChatHistory = getNewChatHistory(state.chatHistory, state.mission, nextStep);
         }

      }
      else{

         nextStep = nextInArray(state.chatRoads[state.mission], prevStep);

         if (nextStep.startsWith('m_') || nextStep.startsWith('f_') || nextStep.startsWith('s_') || prevStep.startsWith('empty')){
            newChatHistoryW = getNewChatHistory(state.chatHistory, state.mission, "w_");
            newChatHistory = getNewChatHistory(state.chatHistory, state.mission, nextStep);
         }

         else{
            newChatHistory = getNewChatHistory(state.chatHistory, state.mission, nextStep);
         }
      }

      // COMMNETO PERCHé LO FACCIO SEMPRE CON USE EFFECT
      // if(prevStep.startsWith('f_')){
      //    updateMissionsDone();
      // }

      // if (prevStep.startsWith('n_')) { // lo verifico prima
      //    nextFace = "sad";
      //    nextTtw = ttwStandard;
      // }


      if (nextStep.startsWith('u_')) {
         nextFace = "wait";
         nextTtw = ttwStandard; // per resettare dopo m_2a e dopo f
      }

      else if (nextStep.startsWith('f_')) {
         nextFace = "smile";
         nextTtw = 2500;
      }

      // else if (prevStep.startsWith('f_')) {
      //    nextFace = "smile";
      //    nextTtw = ttwStandard;
      // }

      else if((prevStep == "m_2a" && state.mission == 0) ||
              (prevStep == "m_2a" && state.mission == 1) ||
              (prevStep == "m_3a" && state.mission == 2) ||
              (prevStep == "m_10a" && state.mission == 3) ||
              (prevStep == "m_5a" && state.mission == 4) ||
              (prevStep == "m_4a" && state.mission == 5) ||
              (prevStep == "m_2a" && state.mission == 6) ||
              (prevStep == "m_2a" && state.mission == 7) ||
              (prevStep == "m_2a" && state.mission == 8)){
         nextFace = "";
         nextTtw = 5000;
      }

      else if (nextStep.startsWith('m_99')) {
         nextFace = "wait";
         nextTtw = ttwStandard; // per resettare dopo m_2a e dopo f
      }

      else if (nextStep.startsWith('m_') || nextStep.startsWith('s_')) {
         nextFace = "speak";
         nextTtw = ttwStandard; // per resettare dopo m_2a e dopo f
      }

      else {
         // console.log('DOVE MI TROVO? nextStep = ', nextStep);
      }

      setState(prevState => ({
          ...prevState,
          face: nextFace,
          ttw: nextTtw
       }));


     // remove input form or buttons
     if (prevStep.startsWith('u_')){
         setState(prevState => ({
             ...prevState,
             input: false
          }));
     }

     if(nextStep.startsWith('u_')){
        intervalRef.current = setTimeout(
          () => {
               setState(prevState => ({
                ...prevState,
                input: nextStep
              }));
              scrollTop();
          }, nextTtw
       );
     }
     else if (nextStep.startsWith('m_') || nextStep.startsWith('f_') || nextStep.startsWith('s_') || prevStep.startsWith('empty')){
        intervalRefW.current = setTimeout(
           () => {
             setState(prevState => ({
                 ...prevState,
                 chatHistory: newChatHistoryW
             }));
             scrollTop();
          }, nextTtw
        );

        intervalRef.current = setTimeout(
           () => {
             setState(prevState => ({
                 ...prevState,
                 chatHistory: newChatHistory,
             }));
             scrollTop();
          }, nextTtw+ttwWait
        );
     }
     else {
        intervalRef.current = setTimeout(
            () => {
               setState(prevState => ({
                  ...prevState,
                  chatHistory: newChatHistory
               }));
               scrollTop();
            }, nextTtw
         );
     }

     return () => {
       clearTimeout(intervalRef.current);
       clearTimeout(intervalRefW.current);
     };
  }
}

  const useInterval = (callback, delay) => {
   const savedCallback = useRef();

   // Remember the latest callback.
   useEffect(() => {
     savedCallback.current = callback;
   }, [callback]);

   // Set up the interval.
   useEffect(() => {
     function tick() {
      savedCallback.current();
     }
     if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
     }
   }, [delay]);
 }

  useEffect(() => {
      scrollTop();
      if (!(state.mission != 9 && state.mission != 10 && state.mission != 11 && state.masterkey == "guest")) {
         goNextStep();
      }
      updateApproAvailable();
      updateMissionsDone();
  }, [state.chatHistory, state.mission]);

  useInterval(() => {
    updateInterval();
  }, serverToBeUpdated ? delay : null);



    return (
      <div className={`App ${state.accessible ? "accessible": ""}`}>
         {(state.mission >= 0 && false) && (
            <Debug
               uuid={state.uuid} appro={state.approAvailable} mk={state.masterkey} tk={state.ticket} nk={state.nick} lang={state.lang} points={state.points} mission={state.mission} onChangeMission={handleChangeMission} chatHistory={state.chatHistory[state.mission]} input={state.input} mdone={state.missionsDone} suggestions={state.suggestions[`${state.input}_m${state.mission}`]} />
         )}

         {(state.waitingApi) && (
            <div className="overlay"></div>
         )}

         <Header
            lang={state.lang}
            mission={state.mission}
            face={state.face} />

         {(state.mission <= 11) && (
            <Menu
               lang={state.lang}
               mk={state.masterkey}
               menuvisible={state.menuvisible}
               menuopen={state.menuopen}
               mission={state.mission}
               appro={state.approAvailable}
               mdone={state.missionsDone}
               onToggleExtra={handleToggleExtra}
               onToggleMenu={handleToggleMenu}
               onChangeLang={handleChangeLang}
               onClickMenu={handleClickMenu}
               onChangeMission={handleChangeMission}
               onWannaBeMaster={handleWannaBeMaster}
               onChangeLangue={handleChangeLang}
               accessible={state.accessible}
               onAccessibleClick = {handleAccessibleClick}
               page={state.page} />
         )}

         {(state.mission <= 8 || state.mission === 9 || state.mission === 10 || state.mission === 11) && (
            <Appro lang={state.lang} open={state.extraopen} content={state.extracontent} video={state.video} onClose={handleToggleExtra} onClickMenu={handleClickMenu} />
         )}

         {(state.face == "smile") &&
            <div className="pointsContainer">
               <div className="points">{state.increment}</div>
            </div>
         }

         {(state.mission <= 8) && (
            <Time
               lang={state.lang}
               starttime={state.starttime}
               points={state.points}
               mission={state.mission}
               mk={state.masterkey} />
         )}

         <div className="appContainer" ref={containerRef}>
            <div className="chatContainer">
               {state.chatHistory[state.mission] && (
                  state.chatHistory[state.mission].map((value, index) => (
                     <Balloon key={index}
                        mk={state.masterkey}
                        mdone={state.missionsDone}
                        nk={state.nick}
                        tk={state.ticket}
                        lang={state.lang}
                        mission={state.mission}
                        type={value.charAt(0)}
                        clickon={value}
                        stats={state.stats}
                        onWinGame = {handleWinGame}
                        onToggleExtra={handleToggleExtra}
                        onChangeMission={handleChangeMission} />
                  ))
               )}

               {state.input && (
                  <Input
                     input={state.input}
                     mission={state.mission}
                     ticket={state.ticket}
                     mk={state.masterkey}
                     nick={state.nick}
                     lang={state.lang}
                     answer={state.answer} answer2={state.answer2} answer3={state.answer3} answer4={state.answer4} answerfeedback={state.answerfeedback}
                     onChangeAnswer={handleChangeAnswer}
                     onSubmitAnswer={handleSubmitAnswer}
                     suggestions={state.suggestions[`${state.input}_m${state.mission}`]}
                     onSuggest={handleSuggest}
                     onGoHaed={handleGoHaed}
                     onChangeLang={handleChangeLang}
                     onOpenAppro={handleOpenAppro}
                     loginfeedback={state.loginfeedback}
                     mdone={state.missionsDone}
                     onStartMission={handleStartMission}
                     onWannaBeMaster={handleWannaBeMaster}
                     onChangeNick={handleChangeNick}
                     onConteAccessible={handleConteAccessible}
                     onSubmitNick={handleSubmitNick}
                     onChangeTicket={handleChangeTicket} onSubmitTicket={handleSubmitTicket}
                     onChangeMission={handleChangeMission} />
               )}
            </div>
         </div>
      </div>
    );
}

export default App;
