import React from 'react'
import { CommonSvgContent } from "../content/CommonSvgContent"
import { IconsSvgContent } from "../content/IconsSvgContent"

export const ApproContent = (props) => {
   const string = props.stringToRet;
   const lang = props.lang;

   return (
      <>
         {(string === 'creditsTitle' && lang === 'ita') && (<h2>Credits</h2>)}
         {(string === 'creditsText' && lang === 'ita') && (
            <>
               <IconsSvgContent stringToRet="iconM1free" />
               <h4>UN PROGETTO DI</h4>
               <CommonSvgContent stringToRet="logoMuseo" />

               <h4>VISUAL DESIGN E DEVELOPMENT</h4>
               <CommonSvgContent stringToRet="logoMarimento" />

               <h4>SI RINGRAZIA</h4>
               <p>Tutti coloro che hanno testato il gioco e che hanno contribuito a renderlo migliore</p>
            </>
         )}

         {(string === 'creditsTitle' && lang === 'eng') && (<h2>Credits</h2>)}
         {(string === 'creditsText' && lang === 'eng') && (
            <>
               <IconsSvgContent stringToRet="iconM1free" />
               <h4>A project by</h4>
               <CommonSvgContent stringToRet="logoMuseo" />

               <h4>VISUAL DESIGN AND DEVELOPMENT</h4>
               <CommonSvgContent stringToRet="logoMarimento" />

               <h4>SPECIAL THANKS</h4>
               <p>To everyone who tested the game and helped us improve it</p>

            </>
         )}

         {(string === 'creditsTitle' && lang === 'fra') && (<h2>Crédits</h2>)}
         {(string === 'creditsText' && lang === 'fra') && (
            <>
               <IconsSvgContent stringToRet="iconM1free" />
               <h4>UN PROJET DE</h4>
               <CommonSvgContent stringToRet="logoMuseo" />

               <h4>GRAPHISME ET DÉVELOPPEMENT</h4>
               <CommonSvgContent stringToRet="logoMarimento" />

               <h4>REMERCIEMENTS À</h4>
               <p>Tous ceux qui ont testé le jeu et qui ont contribué à son amélioration.</p>
            </>
         )}

         {(string === 'icosaedroTitle' && lang === 'ita') && (<h2>Icosaedro elevato vacuo</h2>)}
         {(string === 'icosaedroSubtitle' && lang === 'ita') && (<h3>Italia, 1976</h3>)}
         {(string === 'icosaedroText' && lang === 'ita') && (
            <>
               <p>Nel suo trattato “De divina proportione” Luca Pacioli, grande amico di Leonardo, analizza i solidi citati da Platone, come questo icosaedro. Il matematico toscano riteneva il disegno fondamentale per rendere comprensibili queste entità, espressione neoplatonica di una forma dell’universo in cui matematica, teologia e filosofia sono collegate. Coinvolse l’amico spiegandogli gli “Elementi” di Euclide, che Leonardo fuse nei suoi disegni con gli insegnamenti pratici appresi nella bottega del Verrocchio e i trattati sulla prospettiva di Leon Battista Alberti e di Piero della Francesca. <br />
               I poliedri di Leonardo e Luca Pacioli rappresentano una pietra miliare nell’evoluzione del disegno prospettico dei solidi, mostrandone chiaramente sia il fronte che il retro. Leonardo li raffigura in una versione piena e una vacua, cioè vuota. Nella prima, le facce sono opache e si vedono solo quelle anteriori. Nella seconda, sono rappresentati gli spigoli dei poliedri costituiti da “assicelle” di varie sezioni: questo stratagemma permette di vedere chiaramente anche le facce nascoste, nella corretta visione assonometrica. Alcuni poliedri sono infine definiti “elevati” poiché da ogni faccia si innalzano dei triangoli che formano delle vere e proprie punte. È il caso di questo icosaedro realizzato dallo scultore e matematico Ugo Adriano Graziotti.</p>
               <p class="credits">Icosaedro regolare stellare Inv. IGB-8465 | © 2019 Alessandro Nassiri | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci </p>
            </>
         )}

         {(string === 'filettatriceTitle' && lang === 'ita') && (<h2>Macchina per filettare le viti</h2>)}
         {(string === 'filettatriceSubtitle' && lang === 'ita') && (<h3>Italia, 1956</h3>)}
         {(string === 'filettatriceText' && lang === 'ita') && (
            <>
               <p>Questa macchina per produrre viti in serie, in modo veloce e preciso, testimonia l’importanza di questi elementi di fissaggio nell’ambito della tecnologia dell’epoca.<br />
               Leonardo descrive nel dettaglio il funzionamento: la barra in legno da filettare è nel centro, fissata alla ruota dentata centrale che dà il moto a tutta la macchina. Questa prima ruota ingrana su due ruote laterali che mettono in movimento due lunghe viti per far avanzare il carrello centrale, dove si trova una lama obliqua. Il movimento combinato di taglio e di rotazione creano il filetto sulla barra. Accanto alla macchina vi sono una serie di ruote dentate alternative che consentono di creare viti con passi diversi.</p>
               <p class="credits">Macchina utensile per fabbricare le viti. (metà sec. XX) Inv. 00030 | © 2019 Luciano Romano | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'eleaTitle' && lang === 'ita') && (<h2>Tavolo di comando (console) dell’ELEA 9003</h2>)}
         {(string === 'eleaSubtitle' && lang === 'ita') && (<h3>Italia, 1959</h3>)}
         {(string === 'eleaText' && lang === 'ita') && (
            <>
               <p>L’ELEA 9003 è il primo computer commerciale sviluppato in Italia per la Olivetti di Ivrea (Torino), ad opera di una squadra interdisciplinare di progettisti guidata dall’ingegnere italo-cinese Mario Tchou (1924-1961).<br />
               Elea significa “Elaboratore Elettronico Automatico” e all’epoca i computer, detti “elaboratori”, erano molto diversi da quelli di oggi: occupavano intere stanze e non avevano monitor, mouse e un vero e proprio sistema operativo..<br />
               Quello esposto al Museo è un componente dell’ELEA 9003,un esemplare del tavolo di comando o console. I tasti di questa interfaccia utente, con i comandi a facile portata della persona che ne fa uso, presentano una simbologia studiata per essere utilizzata internazionalmente. Il suo aspetto fu ideato da una squadra di progettisti guidata dall’architetto Ettore Sottsass (1917-2007) e la console, in particolare, fu curata dal designer olandese Andries Van Onck (1928–2018). Il progetto dell’ELEA ricevette il Compasso d’Oro nel 1959. Sul piano tecnologico, ELEA era un sistema molto avanzato per l’epoca, costruito interamente utilizzando transistor, una tecnologia che stava affiancandosi alle più usate valvole termoioniche.<br />
               Il sistema ELEA 9003 era utilizzato in Italia da aziende di punta per scopi gestionali. Fu prodotto da Olivetti in circa quaranta esemplari, personalizzati per ciascun cliente.</p>
               <p class="credits">Console di comando per sistema Olivetti Elea 9003 – Olivetti Elea 9003 (1958) Inv. D-1230 | © 2019 Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'programma101Title' && lang === 'ita') && (<h2>P101</h2>)}
         {(string === 'programma101Subtitle' && lang === 'ita') && (<h3>Italia, anni ‘60</h3>)}
         {(string === 'programma101Text' && lang === 'ita') && (
            <>
               <p>La P101 è una calcolatrice programmabile prodotta tra il 1965 e il 1971 dalla Olivetti di Ivrea (Torino). Il capo progettista fu Pier Giorgio Perotto che lavorò insieme a Giovanni De Sandre e Gastone Garziera. Il design esterno era dell’architetto Mario Bellini.<br />
               All’epoca solo i grandi elaboratori elettronici erano programmabili. La P101 poteva compiere operazioni più complesse rispetto a normali calcolatrici grazie a una memoria di tipo magnetostrittivo,  di capacità più limitata rispetto alle memorie a nuclei di ferrite dei grandi calcolatori mainframe che erano però ingombranti e costosi. <br />
               La memoria si azzerava quando la macchina veniva spenta. La funzione che svolgeva era di memoria "cache", fondamentale per immagazzinare i risultati delle ultime operazioni e renderli velocemente accessibili alle successive istanze del programma. Il dispositivo di lettura/registrazione per i programmi era una cartolina magnetica, che si inseriva nella macchina.
               Tutta l'architettura della P101 si basava su transistor, organizzati in ”micromoduli”, con una tecnica brevettata a nome di Perotto e di Eduardo Ecclesia.<br />
               Le calcolatrici P101 vennero usate nel 1968 dalla NASA per effettuare calcoli per l’atterraggio sulla Luna. Poiché poteva essere poggiata su una scrivania, molti la considerano un antecedente del personal computer.</p>
               <p class="credits">Calcolatore programmabile da tavolo Olivetti Programma 101 Inv. IGB-15801 | © 2012 Alessandro Nassiri | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'apollo11Title' && lang === 'ita') && (<h2>Bandiera degli Stati Uniti d’America della missione Apollo11</h2>)}
         {(string === 'apollo11Subtitle' && lang === 'ita') && (<h3>Stati Uniti d’America, 1969. Collezione Mario Spada</h3>)}
         {(string === 'apollo11Text' && lang === 'ita') && (
            <>
               <p>La conquista della Luna avvenne nel momento di massima crisi della guerra fredda, il lungo periodo caratterizzato dall’acceso confronto fra le due potenze uscite vittoriose dalla Seconda Guerra mondiale: Stati Uniti e ’Unione Sovietica. In quegli anni vennero sviluppate le necessarie competenze per raggiungere lo Spazio che divenne il nuovo “ambiente” in cui rivaleggiare. Era quella la nuova frontiera ed era lì che, primeggiando, si poteva dimostrare al mondo la propria superiorità scientifica e tecnologica accrescendo il proprio prestigio internazionale e il predominio culturale. Questo aspetto è evidente nell’insieme di oggetti “patriottici” usati durante il progetto Apollo, come la piccola bandiera degli Stati Uniti portata sulla Luna nel corso della storica missione Apollo 11 e autografata da Michael Collins, il pilota del modulo di comando Columbia. Il programma Apollo, che durò dal 1963 al 1972, impiegò 20.000 aziende del settore privato che misero “a profitto” il loro contributo menzionando i loro apparati nella sterminata quantità di comunicati stampa prodotti in quegli anni. Alcune decisero di distinguersi realizzando gadget promozionali di vario tipo come la tracking wheel, creata da IBM in occasione del lancio di Apollo 11, per aiutare i media a seguire le fasi più importanti della missione descrivendo quali fossero le componenti dei sistemi IBM in uso in quel preciso momento nella logica del “senza di noi questa cosa non si sarebbe potuta fare”.</p>
               <p class="credits">Patch delle missioni Apollo 11, 12, 13, 14, 15, 16 e 17 (1969-1972); Inv. D-1747 | © 2014 Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'krechedTitle' && lang === 'ita') && (<h2>Prototipo di tuta per esplorazione lunare detta Krechet</h2>)}
         {(string === 'krechedSubtitle' && lang === 'ita') && (<h3>Unione Sovietica, inizio anni ’60. Collezione Mario Spada</h3>)}
         {(string === 'krechedText' && lang === 'ita') && (
            <>
               <p>Questa tuta è uno degli oggetti storici più interessanti della storia dell’astronautica, rarissima testimonianza del progetto sovietico destinato a portare l’uomo sulla Luna. Come gli Stati Uniti, negli anni ‘60 anche l’Unione Sovietica si impegnò attivamente nello sviluppo di tecnologie e conoscenze indispensabili per compiere questa incredibile missione. Ma il lanciatore destinato a vincere la gravità terrestre e arrivare sulla Luna, il colossale N1, fallì i collaudi. Il 20 luglio 1969, superando l’Unione Sovietica, la missione americana raggiunse per prima la Luna e riuscì a replicare il successo altre cinque volte. Da quel momento il progetto sovietico venne abbandonato e, per quasi 50 anni, venne negata la sua stessa esistenza nascondendo o addirittura distruggendo molti materiali. Questa tuta, sopravvissuta, è il segno tangibile di quel progetto. Avrebbe dovuto essere indossata da chi per primo avrebbe “conquistato” il suolo lunare compiendo l’esplorazione della superficie del nostro satellite. Il suo aspetto ricorda non poco quello delle tute da palombaro e non è difficile immaginare che proprio dall’esperienza acquisita nell’esplorazione dei fondali marini, ambienti ostili quanto la Luna, l’ingegneria sovietica sia partita  per capire come realizzare una tuta lunare.</p>
               <p class="credits">Tuta Krechet per esplorazione lunare. (anni ’60 del XX secolo) Inv. D-1421 | © 2014 Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'totiTitle' && lang === 'ita') && (<h2>S685.600 Locomotiva a vapore</h2>)}
         {(string === 'totiSubtitle' && lang === 'ita') && (<h3>Italia, 1908</h3>)}
         {(string === 'totiText' && lang === 'ita') && (
            <>
               <p>Il successo di questa motrice è dovuto alla capacità di unire potenza e velocità, oltre che essere economica nella gestione e di facile condotta per macchinisti e fuochisti.<br />
               Nasce negli anni Trenta come trasformazione delle Gr 680 e si diffonde lungo tutte le linee principali, a partire dalla tratta Roma-Napoli, per trainare i treni rapidi. La novità rispetto al precedente gruppo 680 è rappresentata dal surriscaldatore che sfrutta il calore sprigionato dalla caldaia per scaldare il vapore ottenendo così maggiore potenza.<br />
               Le 685 terminano il loro servizio nel 1968. La lettera "S" che precede il nome della locomotiva sta per "speciale" e indica un'ulteriore modifica del sistema di distribuzione del vapore nei cilindri, progetto dell'Ingegner Caprotti. </p>
               <p class="credits">S685.600 Locomotiva a vapore Inv. D 316 | 1908 | © Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'ebeTitle' && lang === 'ita') && (<h2>Brigantino Goletta – nave scuola Ebe / San Giorgio</h2>)}
         {(string === 'ebeSubtitle' && lang === 'ita') && (<h3>Italia, 1918</h3>)}
         {(string === 'ebeText' && lang === 'ita') && (
            <>
               <p>Impostata nel dicembre del 1918 nel Cantiere Benetti di Viareggio, venne varata il 21 agosto 1921 con il nome di San Giorgio. Prima usata come piccolo cabotaggio tra Genova e Torre del Greco, nel 1937 fu aggiunto un motore ausiliario in grado di garantire la velocità di 4 nodi anche in mancanza di vento.. Tra la fine degli anni ‘30 e i primi anni ’40, gestì spesso il trasporto di merci nella linea Cagliari - Napoli - Genova, tratte percorse in pochi giorni di navigazione. Occasionalmente faceva scalo, sempre per il trasporto merci, anche in nord Africa. Allo scoppio della Seconda Guerra mondiale venne requisita dalla Marina Militare e trasformata in dragamine. Solo alla fine della Guerra venne restituita e tornò a essere usata come nave da carico. Successivamente, nel 1952, la Marina Militare italiana la riacquistò e la trasformò in nave scuola per nocchieri, con il nome di Ebe. Dalla stiva venne ricavato un grande locale per l’alloggio dell’equipaggio e fu montato un nuovo motore ausiliario. Sostituita nel 1958 dalla nave scuola Palinuro, dal 1960 la Ebe fu messa in disarmo nella Base di Portoferraio. Trasferita a La Spezia in attesa di essere demolita, venne infine acquistata dal Museo. Nel 1963, nel Cantiere Argo Carpentieri, cominciò il recupero dello scafo, che venne sezionato in novanta pezzi e trasportato a Milano insieme agli altri elementi grazie a 25 autocarri. Arrivato al Museo, fu rimontato all'interno del padiglione Aeronavale, dove si trova oggi.</p>
               <p class="credits">Nave scuola Ebe nv. IGB 9676 | 1921 (primo quarto XX sec.) © 2014 Marco Gualtieri | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'biancamanoTitle' && lang === 'ita') && (<h2>Stucco in gesso rappresentante il mito di Giasone (Sale delle feste - Conte Biancamano)</h2>)}
         {(string === 'biancamanoSubtitle' && lang === 'ita') && (<h3>Italia, 1947</h3>)}
         {(string === 'biancamanoText' && lang === 'ita') && (
            <>
               <p>Questo stucco decora il soffitto della sala delle feste di prima classe del transatlantico Conte Biancamano. Il fregio a basso rilievo, realizzato da Marcello Mascherini e composto di tre anelli concentrici: all’esterno il cielo, con uccelli e gabbiani; all’interno il mare, con pesci e molluschi in lotta; al centro la terra, con il mito del “vello d’oro”.<br />
               La sala si trova a prua, facilmente raggiungibile da tutti gli alloggi di prima classe e degli ufficiali superiori.<br />
               Il Biancamano, considerato la prima città galleggiante nazionale, si rivelò l'ultimo transatlantico italiano costruito all'estero. Dotato di ogni comfort e lussuosamente allestito, effettuò il suo viaggio inaugurale sulla linea Genova-Napoli-New York il 20 novembre 1925. Dopo 10 anni di servizio, il governo italiano lo destinò al trasporto di truppe e persone in Africa Orientale e l'anno successivo tornò a viaggiare verso il Sud America e l'estremo Oriente, sulla rotta Genova-Shanghai via Suez. Nel 1940 riprese la tratta atlantica tra Genova e Valparaiso via Panama, per sostituire la motonave Orazio, distrutta da un incendio. Con la Seconda Guerra mondiale fu requisito dall'esercito statunitense vicino Panama. Ribattezzato "Hermitage", fu adibito al trasporto di truppe americane sui fronti di Pacifico e Atlantico. Recuperò i suoi fasti alla fine del conflitto mondiale, quando tornò allo Stato italiano che lo riallestì presso i cantieri navali di Monfalcone (Gorizia). Il Biancamano riprese il mare lungo la rotta Genova-New York nel marzo 1950 con una moderna prua inclinata in avanti, fumaioli aerodinamici e livrea bianca. Proprio su questa stessa rotta, 10 anni dopo effettuerà il suo ultimo viaggio.</p>
               <p class="credits">Sala da ballo Conte Biancamano | 1947 (prima metà XX sec.) Inv. IGB 07081 | © Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'convertiplanoTitle' && lang === 'ita') && (<h2>Modello di convertiplano tipo AW609</h2>)}
         {(string === 'convertiplanoSubtitle' && lang === 'ita') && (<h3>Italia, 1998</h3>)}
         {(string === 'convertiplanoText' && lang === 'ita') && (
            <>
               <p>Il convertiplano è un velivolo che genera portanza e spinta per mezzo di uno o più propulsori ad elica montati su gondole motore rotanti, o rotori basculanti. Questo mezzo combina la capacità di portanza verticale dell'elicottero con la velocità e l'autonomia di un convenzionale aereo ad eliche. Per il volo in verticale, le eliche sono ruotate verso l’alto, generando portanza esattamente come in un elicottero. Man mano che il velivolo acquisisce velocità, le eliche sono progressivamente inclinate in avanti, con il piano di rotazione che alla fine diventa verticale. In questo modo l'ala fornisce la portanza e l'elica fornisce la spinta comportandosi da motore come in un aereo.<br />
               Il modello da galleria del vento fu sviluppato inizialmente nell'ambito del programma di collaborazione Agusta-Bell, dotato di profili intercambiabili dei bordi d'attacco dei piani di coda e delle ali. I differenti profili servono per verificare la formazione di turbolenze attorno al velivolo, in presenza di ghiaccio. Il modello può essere riconfigurato per tutte le fasi di volo, decollo, conversione e volo traslato.</p>
               <p class="credits">Modello per galleria del vento di convertiplano (tiltrotor) tipo AW609 | 1998 (ultimo quarto XX sec) Inv. IGB 16264 | © Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'v2Title' && lang === 'ita') && (<h2>Componenti di razzo V2</h2>)}
         {(string === 'v2Subtitle' && lang === 'ita') && (<h3>Germania, anni ’40. Collezione Mario Spada</h3>)}
         {(string === 'v2Text' && lang === 'ita') && (
            <>
               <p>A partire dagli anni ’30 del XX secolo l’antico sogno di staccarsi da terra per raggiungere lo Spazio passò dal futuribile al fattibile. A partire dalle teorie del russo Konstantin Ciolkovskij, vennero approfondite la conoscenza dei materiali, della combustione e dell’aerodinamica. Fra i pionieri: Robert Goddard, negli Stati Uniti, Hermann Oberth, in Germania, Robert Esnault-Pelterie, in Francia e Sergej Korolev in Russia. Nel periodo tra le due guerre, la ricerca missilistica era oramai pienamente avviata. Il progetto di maggior successo vide la luce nella Germania nazista e venne sviluppato per fini bellici. Fu Wernher von Braun a realizzare il primo razzo inteso in senso moderno. Le V2 diventarono in breve il simbolo del terrore portato dalla guerra soprattutto perché pensate per colpire a sorpresa e a grandi distanze le città e i civili. Furono i primi missili balistici a lungo raggio e i primi manufatti in grado di raggiungere lo Spazio superando il limite di Kármán dei 100 km di altezza, considerata convenzionalmente la quota alla quale inizia lo Spazio extraterrestre. Al termine della guerra, le V2 sopravvissute vennero sequestrate dagli Stati Uniti e dall’Unione Sovietica e utilizzate sia per fini scientifici che per lo sviluppo di lanciatori di nuova generazione come l’R-7 sovietico usato per portare il primo uomo nello Spazio (Yurij Gagarin) e il Redstone americano usato per lanciare il primo astronauta a stelle e strisce (Alan Shepard).</p>
               <p class="credits">Elemento di V2. (anni ’40 del XX secolo) Inv. D-1271 | © 2014 Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {/* ENG */}

         {(string === 'icosaedroTitle' && lang === 'eng') && (<h2>Hollow elevated icosahedron</h2>)}
         {(string === 'icosaedroSubtitle' && lang === 'eng') && (<h3>Italy, 1976</h3>)}
         {(string === 'icosaedroText' && lang === 'eng') && (
            <>
               <p>In his book “De divina proportione”, Luca Pacioli, one of Leonardo’s close friends, analysed Plato’s solids, like this icosahedron. The Tuscan mathematician believed this was a fundamental drawing for an understanding of these entities, Neoplatonic expressions of forms in the Universe in which mathematics, theology and philosophy were all related. He discussed them with his friend, explaining Euclid’s “Elements”, which Leonardo merged in his drawings with the practical teachings he had learned in the bottega of Verrocchio and in Leon Battista Alberti’s and Piero della Francesca’s treatises on perspective. <br />
               Leonardo’s and Luca Pacioli’s polyhedrons constitute a milestone in the perspective drawing of solids, as they clearly show both the front and the back. Leonardo drew both solid and hollow versions. In the former, the faces are opaque and we only see the ones in front. In the latter, the corners of the polyhedrons are shown, consisting of “slats” of various sections. This strategy enabled him to clearly show even the hidden faces, as in a proper axonometric projection. A few polyhedrons are called “elevated” because each face is elevated with a triangle coming to a point, as is the case of this icosahedron created by the sculptor and mathematician Ugo Adriano Graziotti.</p>
               <p class="credits">Hollow elevated icosahedron | © 2019 Alessandro Nassiri | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci </p>
            </>
         )}

         {(string === 'filettatriceTitle' && lang === 'eng') && (<h2>Thread rolling machine for screws</h2>)}
         {(string === 'filettatriceSubtitle' && lang === 'eng') && (<h3>Italy, 1956</h3>)}
         {(string === 'filettatriceText' && lang === 'eng') && (
            <>
               <p>This machine, for the rapid and precise mass production of screws, highlights the importance of these fasteners in the technology of the time.<br />
               Leonardo described in detail how they worked: the wooden cylinder to be threaded is in the center, attached to the central gear which drives the entire machine. This first gear turns the two lateral gears that put in motion two long screws that move the central carriage forward, where there is a slanted blade. The combined movement of the cut and the rotation create the threading on the screw. Next to the machine is a series of alternative gearwheels to create different size threads.</p>
               <p class="credits">Thread rolling machine for screws | © 2019 Luciano Romano | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'eleaTitle' && lang === 'eng') && (<h2>ELEA 9003 console</h2>)}
         {(string === 'eleaSubtitle' && lang === 'eng') && (<h3>Italy, 1959</h3>)}
         {(string === 'eleaText' && lang === 'eng') && (
            <>
               <p>The ELEA 9003 was the first business computer developed in Italy for Olivetti of Ivrea (Turin) by an interdisciplinary team of researchers led by the Italo-Chinese engineer Mario Tchou (1924-1961). <br />
               In Italian, ELEA stands for Elaboratore Elettronico Automatico (“Automatic Electronic Computer”). In its era, computers were very different from the ones we know today: a single computer took up an entire room and did not have a monitor, mouse or even a real operating system...<br />
               The desktop console on display in the Museum was one component of the ELEA 9003. The keys of this user interface, with user-friendly commands, are based on symbols designed for an international usership. Its design was created by a team of designers led by the architect Ettore Sottsass (1917-2007) and, in particular, the console was styled by the Dutch designer Andries Van Onck (1928–2018). The ELEA won the Compasso d’Oro in 1959 for its design. From a technological standpoint, the ELEA was highly advanced for its time, made entirely with transistors, a technology that was emerging as an alternative to the more commonly used vacuum tubes.<br />
               The ELEA 9003 system was used by top companies in Italy for management processes. Olivetti produced about forty models, each of which was customised for the customer.</p>
               <p class="credits">ELEA 9003 console | © 2019 Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'programma101Title' && lang === 'eng') && (<h2>P101</h2>)}
         {(string === 'programma101Subtitle' && lang === 'eng') && (<h3>Italy, 1960s</h3>)}
         {(string === 'programma101Text' && lang === 'eng') && (
            <>
               <p>The P101 was a programmable desktop calculator produced between 1965 and 1971 by Olivetti in Ivrea (Turin). It was invented by Pier Giorgio Perotto, lead engineer, working with Giovanni De Sandre and Gastone Garziera, and its exterior was designed by the architect Mario Bellini.<br />
               At the time, only large electronic processors were programmable, but the P101 could perform more complex operations than normal calculators thanks to its magnetostrictive memory, which had a smaller capacity than the ferrite cores of large mainframe computers but with the advantage that it was smaller and less expensive.<br />
               Its memory cleared when the machine was switched off. It had a “cache” memory, which was fundamental for storing the results of the last operations and making them quickly accessible for successive steps in the program. Magnetic cards, inserted into the machine, were used to read/store programs.<br />
               The P101’s entire architecture was based on transistors organised into “micromodules”, using a technique patented by Perotto and Eduardo Ecclesia.<br />
               NASA used P101 calculators in 1968 for the Moon landing calculations. Since it could sit on a desk, many consider it a predecessor of the personal computer.</p>
               <p class="credits">P101 | © 2012 Alessandro Nassiri | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'apollo11Title' && lang === 'eng') && (<h2>American flag of the Apollo 11 mission</h2>)}
         {(string === 'apollo11Subtitle' && lang === 'eng') && (<h3>United States of America, 1969. Collection of Mario Spada</h3>)}
         {(string === 'apollo11Text' && lang === 'eng') && (
            <>
               <p>The Moon landing occurred at the height of the Cold War, the long period of hostility between the two superpowers that had won World War II: the United States and the Soviet Union. During the Cold War, these two rivals were developing the knowledge and technology needed to travel to space, which had become their battlefield. Space was the next frontier and it was there that, by taking the lead, they could show the world their scientific and technological superiority, thereby increasing their prestige in the international arena and their cultural dominance. <br />
               This can be clearly seen in the series of “patriotic” objects used during Project Apollo, like the small American flag taken to the Moon during the historic Apollo 11 mission and signed by Michael Collins, the pilot of the Columbia command module.<br />
 The Apollo program, which ran from 1963 to 1972, relied on the work of 20,000 private-sector companies, which publicised their contribution to the program by mentioning the devices they had developed in the countless press releases generated in those years. A few of these companies even created marketing gadgets like IBM’s tracking wheel for the Apollo 11 launch, to help the media follow the most important phases of the mission, with a description of the IBM system components that were being used at each precise moment, conveying the idea that “this would not have been possible without us”.</p>
               <p class="credits">American flag of the Apollo 11 mission | © 2014 Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'krechedTitle' && lang === 'eng') && (<h2>Prototype of a space suit for moon exploration, called the Krechet</h2>)}
         {(string === 'krechedSubtitle' && lang === 'eng') && (<h3>Soviet Union, early 1960s. Collection of Mario Spada</h3>)}
         {(string === 'krechedText' && lang === 'eng') && (
            <>
               <p>This space suit is one of the most interesting objects in the history of astronautics. It is an extremely rare artefact of the Soviet program to land a man on the Moon.<br />
 Like the United States, in the 1960s the Soviet Union was actively involved in the development of the technologies and knowledge needed to complete this incredible mission. But the launcher meant to defy gravity and reach the Moon, the colossal N1, failed its tests. On July 20, 1969, the Americans beat the Soviet Union, reaching the Moon first, and would return there another five times. <br />
               From that moment on the Soviets abandoned their program and, for nearly 50 years, denied its very existence, concealing or even destroying many materials. This space suit, which survived, is tangible evidence of the program. It would have been worn by whoever was the first to touch down on the surface of our satellite. It looks a lot like the kind of suits that deep sea divers wear and it is not difficult to imagine that Soviet engineers designed this moon suit based on the experience gained in the exploration of the deep seas, environments as hostile as the Moon.</p>
               <p class="credits">Prototype of a space suit for moon exploration, called the Krechet | © 2014 Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'totiTitle' && lang === 'eng') && (<h2>S685.600 Steam locomotive</h2>)}
         {(string === 'totiSubtitle' && lang === 'eng') && (<h3>Italy, 1908</h3>)}
         {(string === 'totiText' && lang === 'eng') && (
            <>
               <p>This engine's success was due to the successful combination of power and speed. It was also cheap to operate and easy to use for drivers and firemen.<br />
               Created in the 1930s as a transformation of the Gr 680, it was used to pull fast trains on all main lines, including the Rome-Naples route. The novelty of this train compared to the previous 680 group was its superheater, which used heat from the boiler to heat the steam, providing additional power.<br />
               The 685 was taken out of service in 1968. The letter "S" in front of the locomotive’s name stands for "Special”. It indicates a design modification - by engineer Caprotti - of the system that distributed steam through the cylinders.</p>
               <p class="credits">S685.600 Steam Locomotive Inv. D 316 | 1908 | © Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'ebeTitle' && lang === 'eng') && (<h2>Brigantine schooner - training ship Ebe / San Giorgio</h2>)}
         {(string === 'ebeSubtitle' && lang === 'eng') && (<h3>Italy, 1918</h3>)}
         {(string === 'ebeText' && lang === 'eng') && (
            <>
               <p>This ship’s keel was laid in December 1918 at the Benetti shipyard in Viareggio and it was launched on 21 August 1921 with the name San Giorgio. First used as a small ship for coastal navigation between Genoa and Torre del Greco, an auxiliary engine was added in 1937 to ensure speeds of up to 4 knots even in conditions without wind... Between the late 1930s and the early 1940s, the ship often handled cargo transport along the Cagliari - Naples - Genoa route, which it could cover in a few days of sailing. It occasionally made port calls in North Africa as well, carrying cargo. When World War II broke out, the ship was requisitioned by the Navy and converted into a minesweeper. It was not until the end of the war that it was returned and resumed operations as a cargo ship. In 1952, the Italian Navy took it back and turned it into a training ship for helmsmen, renaming it Ebe. A large space was created in the cargo hold for the crew and a new auxiliary engine was mounted. Replaced in 1958 by another training ship, Palinuro, Ebe was decommissioned at the Portoferraio base in 1960. It was then transferred to La Spezia to await demolition, but was instead purchased by the Museum. Work began at the Argo Carpentieri shipyard in 1963 to recover the hull, which was sectioned into 90 pieces and carried to Milan, along with the ship’s other parts, in 25 trucks. Once it arrived at the Museum, it was reassembled inside the Air and Water Transport Building, where it still resides today.</p>
               <p class="credits">Brigantine schooner - training ship Ebe / San Giorgio | © 2014 Marco Gualtieri | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'biancamanoTitle' && lang === 'eng') && (<h2>Plaster depicting the myth of Jason (Ballroom - Conte Biancamano)</h2>)}
         {(string === 'biancamanoSubtitle' && lang === 'eng') && (<h3>Italy, 1947</h3>)}
         {(string === 'biancamanoText' && lang === 'eng') && (
            <>
               <p>This plaster decorates the ceiling of the first-class ballroom on the Conte Biancamano ocean liner. The bas-relief created by Marcello Mascherini consists of three concentric rings: the sky forms the outer ring, with seagulls and other birds, and within it is the sea, with fish and molluscs in a struggle, then the Earth at the centre, featuring the myth of the Golden Fleece. <br />
               The ballroom is at the bow of the ship, easily accessible from all the cabins of the first-class passengers and high-ranking officials.<br />
               The Biancamano, considered Italy’s first floating city, was the last Italian ocean liner built abroad. Equipped with every comfort and luxuriously outfitted, it set sail for its maiden voyage from Genoa to Naples and on to New York on 20 November 1925. After a decade of service, the Italian government ordered it to be used to transport troops and people to East Africa, and the following year it began sailing to South America and the Far East on the Genoa-Shanghai via Suez route. In 1940, the ocean liner returned to the Atlantic, sailing between Genoa and Valparaiso via Panama as the replacement for the Orazio motor vessel that had been destroyed in a fire. During the Second World War, the Biancamano was requisitioned by the US army near Panama. Renamed the “Hermitage”, it was used to transport US troops to the Pacific and Atlantic fronts. It was restored to its original splendour at the end of the war, when it was returned to the Italian government, which had it refurbished at the shipyard in Montefalcone (Gorizia). The Biancamano returned to sea on the Genoa-New York route in March 1950 with a modern, raked bow, aerodynamic funnels and a white hull. It would sail its last voyage on this same route ten years later.</p>
               <p class="credits">Plaster depicting the myth of Jason (Ballroom - Conte Biancamano) | © Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'convertiplanoTitle' && lang === 'eng') && (<h2>Model of the AW609 tiltrotor</h2>)}
         {(string === 'convertiplanoSubtitle' && lang === 'eng') && (<h3>Italy, 1998</h3>)}
         {(string === 'convertiplanoText' && lang === 'eng') && (
            <>
               <p>A tiltrotor is an aircraft that generates lift and propulsion by way of one or more powered rotors mounted on rotating shafts. This vehicle combines the helicopter’s capacity for vertical lift with the speed and range of a conventional propeller plane. For vertical flight, the rotors are angled so the plane of rotation is horizontal, generating lift exactly like a helicopter. As the aircraft gains speed, they progressively tilt forward, until the tilt angle eventually becomes vertical. In this way, the wings provide the lift and the rotor provides the propulsion, like an aeroplane engine.<br />
               The wind tunnel model was initially developed as part of the Agusta-Bell partnership and featured interchangeable leading edges on the wings and tails. The different leading edges were necessary to test the formation of turbulence around the aircraft when there was ice. The model can be reconfigured for every stage of flight, take-off, conversion and vertical flight.</p>
               <p class="credits">Model of the AW609 tiltrotor | © Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'v2Title' && lang === 'eng') && (<h2>Components of a V2 rocket</h2>)}
         {(string === 'v2Subtitle' && lang === 'eng') && (<h3>Germany, 1940s. Collection of Mario Spada</h3>)}
         {(string === 'v2Text' && lang === 'eng') && (
            <>
               <p>Starting in the 1930s, the long-held dream of lifting off the ground and travelling into space went from futuristic to feasible. The theories of the Soviet rocket scientist Konstantin Tsiolkovsky paved the way for an understanding of materials, combustion and aerodynamics. Some of the pioneers in this field were Robert Goddard in the United States, Hermann Oberth in Germany, Robert Esnault-Pelterie in France and Sergei Korolev in Russia.<br />
 Missile research was fully under way between the two wars. The most successful project took shape in Nazi Germany with the aim of developing an instrument of war. Wernher von Braun created the first modern rocket, the V2, which soon came to symbolise the terror that accompanied war, as it had been designed to be launched at significant distances for surprise attacks on cities and civilians. These V2s were the first long-range ballistic missiles and the first man-made objects to travel into space, crossing the Kármán line, an invisible boundary 100 km above the mean sea level conventionally considered the edge of space. At the end of the war, the United States and the Soviet Union retrieved the surviving V2s and used them for scientific research and to develop next generation launchers, like the Soviet R-7 that was used to carry the first man into space (Yurij Gagarin) and the American Redstone that was used to launch the first US astronaut (Alan Shepard).</p>
               <p class="credits">Components of a V2 rocket | © 2014 Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {/* FRA */}

         {(string === 'icosaedroTitle' && lang === 'fra') && (<h2>Icosaèdre surélevé creux</h2>)}
         {(string === 'icosaedroSubtitle' && lang === 'fra') && (<h3>Italie, 1976</h3>)}
         {(string === 'icosaedroText' && lang === 'fra') && (
            <>
               <p>Dans son traité « De divina proportione », Luca Pacioli, grand ami de Léonard de Vinci, analyse les solides cités par Platon, comme cet icosaèdre. Le mathématicien toscan considérait que le dessin était fondamental pour rendre compréhensibles ces entités, qui sont l’expression néoplatonique d'une forme de l'univers dans lequel les mathématiques, la théologie et la philosophie sont liées. Il impliqua son ami en lui expliquant les « Éléments » d'Euclide, que Léonard de Vinci fusionna dans ses dessins avec les enseignements pratiques appris dans l'atelier du Verrocchio et les traités sur la perspective de Leon Battista Alberti et de Piero della Francesca. <br />
               Les polyèdres de Leonardo de Vinci et Luca Pacioli représentent une étape importante dans l'évolution du dessin en perspective des solides, en montrant clairement leurs faces avant et arrière. Léonard de Vinci les représente dans une version pleine et une version creuse, c'est-à-dire vide. Dans la première, les faces sont opaques et seules les faces antérieures sont visibles. La seconde représente les bords des polyèdres constitués de « bardeaux » de différentes sections : ce stratagème permet de voir clairement également les faces cachées, dans une vision axonométrique correcte. Certains polyèdres sont enfin définis comme « élevés » car de chaque face s'élèvent des triangles qui forment de véritables pointes. C'est le cas de cet icosaèdre réalisé par le sculpteur et mathématicien Ugo Adriano Graziotti.</p>
               <p class="credits">Icosaèdre surélevé creux | © 2019 Alessandro Nassiri | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci </p>
            </>
         )}

         {(string === 'filettatriceTitle' && lang === 'fra') && (<h2>Machine à fileter les vis</h2>)}
         {(string === 'filettatriceSubtitle' && lang === 'fra') && (<h3>Italie, 1956</h3>)}
         {(string === 'filettatriceText' && lang === 'fra') && (
            <>
               <p>Cette machine permettant de produire des vis en série, rapidement et avec précision, témoigne de l'importance de ces éléments de fixation dans la technologie de l'époque.<br />
               Leonardo de Vinci décrit en détail le fonctionnement : la barre en bois à fileter est au centre, fixée à la roue dentée centrale qui donne le mouvement à toute la machine. Cette première roue engage deux roues latérales qui mettent en mouvement deux longues vis pour faire avancer le chariot central, où se trouve une lame oblique. Le mouvement combiné de coupe et de rotation crée le filet sur la barre. À côté de la machine se trouve une série de roues dentées alternatives qui permettent de créer des vis avec des pas différents.</p>
               <p class="credits">Machine à fileter les vis | © 2019 Luciano Romano | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'eleaTitle' && lang === 'fra') && (<h2>Table de commande (console) ELEA 9003</h2>)}
         {(string === 'eleaSubtitle' && lang === 'fra') && (<h3>Italie, 1959</h3>)}
         {(string === 'eleaText' && lang === 'fra') && (
            <>
               <p>L'ELEA 9003 a été le premier ordinateur commercial développé en Italie pour Olivetti à Ivrea (Turin) par une équipe interdisciplinaire de concepteurs dirigée par l'ingénieur italo-chinois Mario Tchou (1924-1961). <br />
               Elea signifie « Ordinateur Électronique Automatique » et à l'époque les ordinateurs, dits « calculateurs », étaient très différents de ceux d'aujourd’hui : ils occupaient des pièces entières et n'avaient pas d’écrans, de souris et de véritable système d'exploitation.<br />
               Celle qui est exposée au Musée est un élément de l'ELEA 9003, un exemplaire de la table de commandement ou pupitre. Les touches de cette interface utilisateur, avec les commandes à portée de main de la personne qui l'utilise, présentent une symbologie conçue pour être utilisée au niveau international. Son apparence a été conçue par une équipe de designers dirigée par l'architecte Ettore Sottsass (1917-2007) et le pupitre, en particulier, a été dessiné par le designer néerlandais Andries Van Onck (1928-2018). Le projet ELEA a obtenu le Compasso d'Oro en 1959. En termes de technologie, ELEA était un système très avancé pour l'époque, construit entièrement à l'aide de transistors, une technologie qui prenait sa place à côté des valves thermo-ioniques plus largement utilisées.<br />
               Le système ELEA 9003 a été utilisé en Italie par des entreprises de premier plan à des fins de gestion. Il a été produit par Olivetti en une quarantaine d'exemplaires, personnalisés pour chaque client.</p>
               <p class="credits">Table de commande (console) ELEA 9003 | © 2019 Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'programma101Title' && lang === 'fra') && (<h2>P101</h2>)}
         {(string === 'programma101Subtitle' && lang === 'fra') && (<h3>Italie, années 1960</h3>)}
         {(string === 'programma101Text' && lang === 'fra') && (
            <>
               <p>La P101 est une calculatrice programmable produite entre 1965 et 1971 par Olivetti à Ivrea (Turin). Le designer en chef était Pier Giorgio Perotto, qui a travaillé avec Giovanni De Sandre et Gastone Garziera. L'architecture extérieure est l'œuvre de l'architecte Mario Bellini.<br />
               À l'époque, seuls les grands calculateurs étaient programmables. La P101 pouvait effectuer des opérations plus complexes que des calculatrices normales grâce à une mémoire de type magnétostrictif, d’une capacité plus limitée que les mémoires à noyaux de ferrite des grandes calculatrices mainframe qui étaient pourtant encombrantes et coûteuses.<br />
               La mémoire a été réinitialisée lorsqu’on a éteint la machine. La fonction qu'il exerçait était la mémoire « cache », essentielle pour stocker les résultats des dernières opérations et les rendre rapidement accessibles aux instances ultérieures du programme. Le dispositif de lecture/enregistrement des programmes était une carte magnétique insérée dans la machine.<br />
               Toute l'architecture de la P101 était basée sur des transistors, organisés en « micromodules », avec une technique brevetée au nom de Perotto et Eduardo Ecclesia.<br />
               Les calculatrices P101 ont été utilisées en 1968 par la NASA pour effectuer des calculs en vue de l'alunissage. Comme elle pouvait être placée sur un bureau, beaucoup la considèrent comme un antécédent de l'ordinateur personnel.</p>
               <p class="credits">P101 | © 2012 Alessandro Nassiri | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'apollo11Title' && lang === 'fra') && (<h2>Drapeau des États-Unis d'Amérique de la mission Apollo 11</h2>)}
         {(string === 'apollo11Subtitle' && lang === 'fra') && (<h3>États-Unis d'Amérique, 1969. Collection Mario Spada</h3>)}
         {(string === 'apollo11Text' && lang === 'fra') && (
            <>
               <p>La conquête de la Lune s'est déroulée en pleine guerre froide, cette longue période caractérisée par la confrontation acharnée entre les deux puissances sorties victorieuses de la Seconde Guerre mondiale : États-Unis et Union soviétique. Au cours de ces années, les compétences nécessaires ont été développées pour atteindre l'espace qui est devenu le nouvel « environnement » dans lequel rivaliser. C'était la nouvelle frontière et c'était là que les nations, en se distinguant, pouvaient démontrer au monde leur supériorité technologique en augmentant leur prestige international et leur prédominance culturelle. Cet aspect est évident dans l'ensemble des objets « patriotiques » utilisés lors du projet Apollo, tels que le petit drapeau américain porté sur la Lune au cours de la mission historique Apollo 11 et dédicacé par Michael Collins, le pilote du module de commande Columbia. <br />
               Le programme Apollo, qui a duré de 1963 à 1972, a employé 20 000 entreprises du secteur privé qui ont mis leur contribution « à profit » en mentionnant leurs appareils dans l'immense quantité de communiqués de presse produits au cours de ces années. Certaines ont décidé de se démarquer en créant des gadgets promotionnels de différents types tels que la tracking wheel, créée par IBM lors du lancement d'Apollo 11, pour aider les médias à suivre les phases les plus importantes de la mission en décrivant quels étaient les composants des systèmes IBM utilisés à ce moment précis dans la logique du « sans nous, cela n'aurait pas pu être fait ».</p>
               <p class="credits">Drapeau des États-Unis d'Amérique de la mission Apollo 11 | © 2014 Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'krechedTitle' && lang === 'fra') && (<h2>Prototype de combinaison d'exploration lunaire appelée Krechet</h2>)}
         {(string === 'krechedSubtitle' && lang === 'fra') && (<h3>Union soviétique, début des années 60. Collection Mario Spada</h3>)}
         {(string === 'krechedText' && lang === 'fra') && (
            <>
               <p>Cette combinaison est l'un des objets historiques les plus intéressants de l'histoire de l'astronautique, un témoignage très rare du projet soviétique destiné à envoyer l’homme sur la Lune.<br />
               Comme les États-Unis, dans les années 1960, l’Union soviétique s'est également activement engagée dans le développement des technologies et des connaissances indispensables à l'accomplissement de cette incroyable mission. Mais le lanceur destiné à vaincre la gravité terrestre et à atteindre la Lune, le colossal N1, a échoué aux essais. Le 20 juillet 1969, dépassant l’Union soviétique, la mission américaine est arrivée sur la Lune pour la première fois et a réussi à répliquer le succès cinq fois de plus. <br />
               À partir de ce moment, le projet soviétique a été abandonné et, pendant près de 50 ans, son existence même a été niée, en cachant ou même en détruisant une grande quantité de matériel. Cette combinaison, qui a survécu, est le signe tangible de ce projet. Elle aurait dû être portée par celui qui aurait « conquis » le premier le sol lunaire en explorant la surface de notre satellite. Son apparence rappelle beaucoup celle des combinaisons de bombardier et il n'est pas difficile d'imaginer que c'est précisément de l'expérience acquise dans l'exploration des fonds marins, des environnements aussi hostiles que la Lune, que l’ingénierie soviétique a commencé à comprendre comment réaliser une combinaison lunaire.</p>
               <p class="credits">Prototype de combinaison d'exploration lunaire appelée Krechet | © 2014 Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'totiTitle' && lang === 'fra') && (<h2>S685.600 Locomotive à vapeur</h2>)}
         {(string === 'totiSubtitle' && lang === 'fra') && (<h3>Italie, 1908</h3>)}
         {(string === 'totiText' && lang === 'fra') && (
            <>
               <p>Le succès de cette locomotive est dû à sa capacité à combiner puissance et vitesse, tout en étant économique et facile à utiliser pour les mécaniciens et les conducteurs.<br />
               Née dans les années 1930 de la conversion des locomotives Gr 680, elle s'est répandue sur toutes les grandes lignes, à commencer par la ligne Rome-Naples, pour tracter des trains rapides. Le surchauffeur, qui utilise la chaleur dégagée par la chaudière pour chauffer la vapeur, est une innovation par rapport au groupe 680 précédent, ce qui permet d'augmenter la puissance.<br />
               Les locomotives 685 ont terminé leur vie utile en 1968. La lettre « S » précédant le nom de la locomotive signifie « spécial » et indique une nouvelle modification du système de distribution de la vapeur dans les cylindres, un projet de l'ingénieur Caprotti.</p>
               <p class="credits">S685.600 Locomotive à vapeur  Inv. D 316 | 1908 | © Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'ebeTitle' && lang === 'fra') && (<h2>Brigantin Goélette - navire-école Ebe / San Giorgio</h2>)}
         {(string === 'ebeSubtitle' && lang === 'fra') && (<h3>Italie, 1918</h3>)}
         {(string === 'ebeText' && lang === 'fra') && (
            <>
               <p>Mis en place en décembre 1918 dans le chantier Benetti de Viareggio, il a été lancé le 21 août 1921 sous le nom de San Giorgio. D'abord utilisé comme petit cabotage entre Gênes et Torre del Greco, en 1937, un moteur auxiliaire a été ajouté pour assurer la vitesse de 4 nœuds également en l'absence de vent. À la fin des années 1930 et au début des années 1940, elle a souvent géré le transport de marchandises sur la ligne Cagliari-Naples-Gênes, des trajets parcourus en quelques jours de navigation. Occasionnellement, il fait également des escales, toujours pour le fret, en Afrique du Nord. Au début de la Seconde Guerre mondiale, il a été réquisitionné par la marine et transformé en dragueur de mines. Ce n'est qu'à la fin de la guerre qu'il a été remis en service comme cargo. Plus tard, en 1952, la marine italienne l'a racheté et l'a transformé en navire-école pour les maîtres d’équipage, sous le nom d'Ebe. Un grand local a été aménagé dans la soute pour loger l'équipage et on a monté un nouveau moteur auxiliaire. Remplacé en 1958 par le navire école Palinuro, à partir de 1960, l'Ebe a été placé en désarmement à la Base de Portoferraio. Déplacé à La Spezia en attendant sa démolition, il a finalement été acheté par le Musée. En 1963, dans le chantier Argo Carpentieri, commença la récupération de la coque, qui a été découpée en quatre-vingt-dix pièces et transportée à Milan avec les autres éléments avec 25 camions. À son arrivée au Musée, elle a été remontée à l'intérieur du Pavillon Aeronavale, où elle se trouve aujourd'hui.</p>
               <p class="credits">Brigantin Goélette - navire-école Ebe / San Giorgio | © 2014 Marco Gualtieri | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'biancamanoTitle' && lang === 'fra') && (<h2>Stuc en plâtre représentant le mythe de Jason (Salle des fêtes - Conte Biancamano)</h2>)}
         {(string === 'biancamanoSubtitle' && lang === 'fra') && (<h3>Italie, 1947</h3>)}
         {(string === 'biancamanoText' && lang === 'fra') && (
            <>
               <p>Ce stuc décore le plafond de la salle des fêtes de première classe du paquebot Conte Biancamano. La frise à bas relief a été réalisée par Marcello Mascherini et composée de trois anneaux concentriques : à l'extérieur le ciel, avec des oiseaux et des mouettes ; à l'intérieur la mer, avec des poissons et des mollusques en lutte ; au centre la terre, avec le mythe de la « Toison d’or ».<br />
               La salle est située à l'avant, facilement accessible depuis tous les logements de première classe et des officiers supérieurs.<br />
               Le Biancamano, considéré comme la première ville flottante nationale, s'est révélé être le dernier transatlantique italien construit à l'étranger. Doté de tout le confort et luxueusement aménagé, il effectua son voyage inaugural sur la ligne Gênes-Naples-New York le 20 novembre 1925. Après 10 ans de service, le gouvernement italien l'a affecté au transport de troupes et de personnes en Afrique Orientale et l'année suivante, il est retourné en Amérique du Sud et en Extrême-Orient, sur la route Gênes-Shanghai via Suez. En 1940, la route atlantique entre Gênes et Valparaiso via Panama a repris pour remplacer le bateau à moteur Orazio, détruit par un incendie. Avec la Seconde Guerre mondiale, il a été réquisitionné par l'armée américaine près du Panama. Rebaptisé « Hermitage », il fut utilisé pour le transport de troupes américaines sur les fronts du Pacifique et de l'Atlantique. Il récupéra ses fastes à la fin du conflit mondial, quand il retourna à l'État italien qui le rétablit auprès des chantiers navals de Monfalcone (Gorizia). Le Biancamano a repris la mer le long de la route Gênes-New York en mars 1950 avec une proue moderne inclinée vers l'avant, des cheminées aérodynamiques et une livrée blanche. C'est précisément sur cette même route, 10 ans plus tard, qu'il effectuera son dernier voyage.</p>
               <p class="credits">Stuc en plâtre représentant le mythe de Jason (Salle des fêtes - Conte Biancamano) | © Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'convertiplanoTitle' && lang === 'fra') && (<h2>Modèle réduit de convertiplane type AW609</h2>)}
         {(string === 'convertiplanoSubtitle' && lang === 'fra') && (<h3>Italie, 1998</h3>)}
         {(string === 'convertiplanoText' && lang === 'fra') && (
            <>
               <p>Le convertiplane est un avion qui génère de la portance et de la poussée au moyen d'un ou plusieurs propulseurs à hélice montés sur des nacelles rotatives ou des rotors basculants. Ce moyen de transport combine la capacité de portance verticale de l'hélicoptère avec la vitesse et l'autonomie d'un avion à hélices conventionnel. Pour le vol vertical, les hélices sont tournées vers le haut, générant une portance exactement comme dans un hélicoptère. Au fur et à mesure que l'avion acquiert de la vitesse, les hélices sont progressivement inclinées vers l'avant, le plan de rotation devenant finalement vertical. De cette façon, l'aile fournit la portance et l'hélice fournit la poussée en se comportant comme un moteur dans un avion.<br />
               Le modèle de soufflerie a été initialement développé dans le cadre du programme de collaboration Agusta-Bell, équipé de profils interchangeables des bords d'attaque des plans de queue et des ailes. Les différents profils servent à vérifier la formation de turbulences autour de l'avion, en présence de glace. Le modèle peut être reconfiguré pour toutes les phases de vol, de décollage, de conversion et de vol déplacé.</p>
               <p class="credits">Modèle réduit de convertiplane type AW609 | © Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}

         {(string === 'v2Title' && lang === 'fra') && (<h2>Composants de la fusée V2</h2>)}
         {(string === 'v2Subtitle' && lang === 'fra') && (<h3>Allemagne, années 1940. Collection Mario Spada</h3>)}
         {(string === 'v2Text' && lang === 'fra') && (
            <>
               <p>À partir des années 1930, le vieux rêve de s'éloigner de la terre et d'atteindre l'espace est passé du futuriste au réalisable. La connaissance des matériaux, de la combustion et de l'aérodynamique a été approfondie à partir des théories du Russe Konstantin Ciolkovskij. Parmi les pionniers : Robert Goddard, aux États-Unis, Hermann Oberth, en Allemagne, Robert Esnault-Pelterie, en France, et Sergei Korolev, en Russie.<br />
 Dans l'entre-deux-guerres, la recherche sur les fusées était déjà bien avancée. Le projet le plus réussi a vu le jour en Allemagne nazie et a été développé à des fins militaires. C'est Wernher von Braun qui a construit la première fusée au sens moderne du terme. Les V2 sont rapidement devenus le symbole de la terreur apportée par la guerre, principalement parce qu'ils sont conçus pour frapper les villes et les civils par surprise et à grande distance. Il s’agit des premières fusées balistiques à longue portée et les premiers objets capables d'atteindre l'espace au-delà de la limite de Kármán de 100 km d'altitude, conventionnellement considérée comme l'altitude à laquelle commence l'Espace extraterrestre. À la fin de la guerre, les V2 survivantes ont été saisies par les États-Unis et l'Union soviétique et utilisées à la fois à des fins scientifiques et pour le développement de lanceurs de nouvelle génération tels que le R-7 soviétique utilisé pour emmener le premier homme dans l’espace (Yurij Gagarin) et le Redstone américain utilisé pour lancer le premier astronaute américain (Alan Shepard).</p>
               <p class="credits">Composants de la fusée V2 | © 2014 Staff | Museo Nazionale della Scienza e della Tecnologia Leonardo da Vinci</p>
            </>
         )}
      </>
   )
}
