import React, { useEffect, useRef } from "react";
// import { useLocation } from 'react-router-dom'

import { BalloonContent } from "./content/BalloonContent"
import { CommonContent } from "./content/CommonContent"
import { CommonSvgContent } from "./content/CommonSvgContent"



import gsap from 'gsap';
import "./../assets/scss/Menu.scss";

export const Menu = React.forwardRef((props, ref) => {

   let menuContainer = useRef(null)
   // const location = useLocation();

   const [isActive, setIsActive] = React.useState([false,false]);

   const handleAccordionClick = (ind) => {
      let newIsActive = [false,false, false];
      newIsActive[ind] = !isActive[ind];
      setIsActive(newIsActive);
   }

   // let [appro, setAppro] = React.useState("FFFFFFFF");
   //
   // React.useEffect(() => {
   //    let as="";
   //    props.appro.map(function (nested, index) {
   //      if (nested){
   //           as += "V";
   //      }
   //      else{
   //         as += "F";
   //      }
   //   });
   //   setAppro(as);
   // }, [props.appro]);

   // apro e chiudo il menu a seconda dello stato di menuopen nel componente App
   useEffect(() => {
      if (props.menuopen === true) {
         gsap.to(menuContainer, { duration: .5, top: -(menuContainer.offsetHeight)+73 });
         handleAccordionClick(-1);
      } else if (props.menuopen === false) {
         gsap.to(menuContainer, { duration: .5, top: 0 });
      }
   },[props.menuopen])

   return (
      <div className={`menu ${props.menuvisible ? "show" : "show"} ${props.menuopen ? "open" : ""}`}>
         <div ref={(el) => (menuContainer = el)} className={`menuContainer `}>
            <div className="menuCta">
               <button className="btn burger" onClick={props.onToggleMenu}>
                  <svg className="burgersvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 74">
                     <rect className="cls-1" width="74" height="74" x="0" y="0"/>
                     <rect className="cls-2" width="5" height="5" y="28" x="35" />
                     <rect className="cls-3" width="5" height="5" y="37" x="35" />
                     <rect className="cls-4" width="5" height="5" y="46" x="35" />

                     <line className="cls-5" x1="18.87" y1="30.25" x2="56.87" y2="30.25"/>
                     <line className="cls-7" x1="18.87" y1="45.84" x2="56.87" y2="45.84"/>

                  </svg>
               </button>

            </div>
         </div>


         <div className="menuOverture">
            <CommonSvgContent stringToRet="logo" />
            {(props.mk !== "guest" && props.mission <= 8  && props.mission >= 1) && (
               <div className="menuList">
                  <div className="accBlock">
                     <button className="clickAccbtn" onClick={(e) => handleAccordionClick(0)}>
                        <CommonContent lang={props.lang} stringToRet="elencoMissioni" />
                     </button>
                     <button className={`openAccbtn ${isActive[0] ? "clicked": ""}`} onClick={(e) => handleAccordionClick(0)}><span>></span></button>
                  </div>
                  <div className={`missionDesc ${isActive[0] ? "visible": ""}`}>

                     <button
                     className={`btn ${props.mission == 7 ? "now":  props.mdone[7] ? "hide": ""}`}
                        {...(!props.mdone[7] ? { onClick:() => {props.onChangeMission(7)}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="missione1" />
                     </button>
                     <button
                     className={`btn ${props.mission == 3 ? "now":  props.mdone[3] ? "hide": ""}`}
                        {...(!props.mdone[3] ? { onClick:() => {props.onChangeMission(3)}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="missione2" />
                     </button>
                     <button
                     className={`btn ${props.mission == 5 ? "now":  props.mdone[5] ? "hide": ""}`}
                        {...(!props.mdone[5] ? { onClick:() => {props.onChangeMission(5)}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="missione3" />
                     </button>
                     <button
                     className={`btn ${props.mission == 2 ? "now":  props.mdone[2] ? "hide": ""}`}
                        {...(!props.mdone[2] ? { onClick:() => {props.onChangeMission(2)}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="missione4" />
                     </button>
                     <button
                       className={`btn ${props.mission == 6 ? "now":  props.mdone[6] ? "hide": ""}`}
                        {...(!props.mdone[6] ? { onClick:() => {props.onChangeMission(6)}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="missione5" />
                     </button>
                     <button
                        className={`btn ${props.mission == 1 ? "now":  props.mdone[1] ? "hide": ""}`}
                        {...(!props.mdone[1] ? { onClick:() => {props.onChangeMission(1)}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="missione6" />
                     </button>
                     <button
                     className={`btn ${props.mission == 8 ? "now":  props.mdone[8] ? "hide": ""}`}
                        {...(!props.mdone[8] ? { onClick:() => {props.onChangeMission(8)}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="missione7" />
                     </button>
                     <button
                     className={`btn ${props.mission == 4 ? "now":  props.mdone[4] ? "hide": ""}`}
                        {...(!props.mdone[4] ? { onClick:() => {props.onChangeMission(4)}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="missione8" />
                     </button>

                  </div>
               </div>
            )}
            {(props.mission <= 8 && props.mission >= 1) && (
               <div className="menuList">
                  <div className="accBlock">
                     <button className="clickAccbtn" onClick={(e) => handleAccordionClick(2)}>
                        <CommonContent lang={props.lang} stringToRet="approfondimenti" />
                     </button>
                     <button className={`openAccbtn ${isActive[2] ? "clicked": ""}`} onClick={(e) => handleAccordionClick(2)}><span>></span></button>
                  </div>

                  <div className={`missionDesc ${isActive[2] ? "visible": ""}`}>

                     <button
                        className={`btn ${!props.appro[7] ? "hide": ""}`}
                        {...(props.appro[7] ? { onClick:(e) => {props.onToggleExtra(e,'ebe')}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="schedaEbe" />
                     </button>
                     <button
                        className={`btn ${!props.appro[8] ? "hide": ""}`}
                        {...(props.appro[8] ? { onClick:(e) => {props.onToggleExtra(e,'biancamano')}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="schedaBiancamano" />
                     </button>

                     <button
                        className={`btn ${!props.appro[5] ? "hide": ""}`}
                        {...(props.appro[5] ? { onClick:(e) => {props.onToggleExtra(e,'toti')}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="schedaToti" />
                     </button>
                     <button
                        className={`btn ${!props.appro[9] ? "hide": ""}`}
                        {...(props.appro[9] ? { onClick:(e) => {props.onToggleExtra(e,'convertiplano')}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="schedaConvertiplano" />
                     </button>
                     <button
                        className={`btn ${!props.appro[2] ? "hide": ""}`}
                        {...(props.appro[2] ? { onClick:(e) => {props.onToggleExtra(e,'v2')}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="schedaV2" />
                     </button>

                     <button
                        className={`btn ${!props.appro[1] ? "hide": ""}`}
                        {...(props.appro[1] ? { onClick:(e) => {props.onToggleExtra(e,'kreched')}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="schedaKrechet" />
                     </button>
                     <button
                        className={`btn ${!props.appro[6] ? "hide": ""}`}
                        {...(props.appro[6] ? { onClick:(e) => {props.onToggleExtra(e,'apollo11')}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="schedaApollo11" />
                     </button>
                     <button
                        className={`btn ${!props.appro[0] ? "hide": ""}`}
                        {...(props.appro[0] ? { onClick:(e) => {props.onToggleExtra(e,'icosaedro')}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="schedaIcosaedro" />
                     </button>
                     <button
                        className={`btn ${!props.appro[10] ? "hide": ""}`}
                        {...(props.appro[10] ? { onClick:(e) => {props.onToggleExtra(e,'filettatrice')}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="schedaFilettatrice" />
                     </button>
                     <button
                        className={`btn ${!props.appro[3] ? "hide": ""}`}
                        {...(props.appro[3] ? { onClick:(e) => {props.onToggleExtra(e,'programma101')}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="schedaProgramma101" />
                     </button>
                     <button
                        className={`btn ${!props.appro[4] ? "hide": ""}`}
                        {...(props.appro[4] ? { onClick:(e) => {props.onToggleExtra(e,'elea')}} : {})}>
                        <CommonContent lang={props.lang} stringToRet="schedaElea" />
                     </button>

                  </div>
               </div>
            )}

            <div className="menuList">
               <div className="accBlock">
                  <button className="clickAccbtn" onClick={(e) => handleAccordionClick(3)}>
                     <CommonContent lang={props.lang} stringToRet="lingua" />
                  </button>
                  <button className={`openAccbtn ${isActive[3] ? "clicked": ""}`} onClick={(e) => handleAccordionClick(3)}><span>></span></button>
               </div>

               <div className={`missionDesc ${isActive[3] ? "visible": ""}`}>
                  <button className="btn" onClick={(e) => props.onChangeLangue(e,'ita', false)}>Italiano</button>
                  <button className="btn" onClick={(e) => props.onChangeLangue(e,'eng', false)}>English</button>
                  <button className="btn" onClick={(e) => props.onChangeLangue(e,'fra', false)}>Français</button>
               </div>
            </div>

            {(props.mission <= 8 && props.mission >= 0 && props.mk == "guest") && (
               <div className="menuList">
                  <div className="accBlock">
                     <button className="clickAccbtn" onClick={(e) => props.onWannaBeMaster(e, "masterMenu")}>
                        <CommonContent lang={props.lang} stringToRet="diventaMaster" />
                     </button>
                  </div>
               </div>
            )}

            <div className="menuList">
               <div className="accBlock">
                  <button className="clickAccbtn"  onClick={props.onAccessibleClick}>
                     {props.accessible && (
                        <>
                           <CommonContent lang={props.lang} stringToRet="fluoStyle" />
                        </>
                     )}
                     {!props.accessible && (
                        <>
                           <CommonContent lang={props.lang} stringToRet="accessibile" />
                        </>
                     )}
                  </button>
               </div>
            </div>

            <div className="menuList">
               <div className="accBlock">
                  <button
                     className="clickAccbtn"
                     onClick={(e) => props.onToggleExtra(e,'credits')}>
                     <CommonContent lang={props.lang} stringToRet="credits" />
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
});
