import React from 'react'
import { CommonSvgContent } from "../content/CommonSvgContent"



export const MissionContent = (props) => {
   const string = props.stringToRet;
   let lang = props.lang;


   return (
   <>
      {/* MISSIONS ITA */}

      {(string === 'title_mission1' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss6" />
      )}
      {(string === 'title_mission2' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss4" />
      )}
      {(string === 'title_mission3' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss2" />
      )}
      {(string === 'title_mission4' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss8" />
      )}
      {(string === 'title_mission5' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss3" />
      )}
      {(string === 'title_mission6' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss5" />
      )}
      {(string === 'title_mission7' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss1" />
      )}
      {(string === 'title_mission8' && lang === 'ita') && (
         <CommonSvgContent stringToRet="miss7" />
      )}

      {/* MISSIONS ENG */}

      {(string === 'title_mission1' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis6" />
      )}
      {(string === 'title_mission2' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis4" />
      )}
      {(string === 'title_mission3' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis2" />
      )}
      {(string === 'title_mission4' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis8" />
      )}
      {(string === 'title_mission5' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis3" />
      )}
      {(string === 'title_mission6' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis5" />
      )}
      {(string === 'title_mission7' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis1" />
      )}
      {(string === 'title_mission8' && lang === 'eng') && (
         <CommonSvgContent stringToRet="mis7" />
      )}

      {/* MISSIONS FRA */}

      {(string === 'title_mission1' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis6" />
      )}
      {(string === 'title_mission2' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis4" />
      )}
      {(string === 'title_mission3' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis2" />
      )}
      {(string === 'title_mission4' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis8" />
      )}
      {(string === 'title_mission5' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis3" />
      )}
      {(string === 'title_mission6' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis5" />
      )}
      {(string === 'title_mission7' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis1" />
      )}
      {(string === 'title_mission8' && lang === 'fra') && (
         <CommonSvgContent stringToRet="mis7" />
      )}

      {/* MISSIONS DESC ITA */}

      {(string === 'desc_mission1' && lang === 'ita') && (
         <>
            <span><CommonSvgContent stringToRet="diff1" /> Difficoltà 1</span> - <span><CommonSvgContent stringToRet="pin" /> Leonardo Da Vinci</span>
            <h5>Una proporzione divina</h5>
            <p>L’oggetto poliedrico contenuto nella sacca emette un bagliore.
            Avete la certezza che riportando quell’oggetto nel suo luogo di origine vi possa
            fornire importanti indizi sui Glifi.  </p>
         </>
      )}
      {(string === 'desc_mission2' && lang === 'ita') && (
         <>
            <span><CommonSvgContent stringToRet="diff1" /> Difficoltà 1</span> - <span><CommonSvgContent stringToRet="pin" /> Spazio</span>
            <h5>L’oggetto fantasma</h5>
            <p>Meta Dendral vi informa di un importante Glifo presente su un oggetto esposto nella sezione Spazio.</p>
         </>
      )}
      {(string === 'desc_mission3' && lang === 'ita') && (
         <>
            <span><CommonSvgContent stringToRet="diff1" /> Difficoltà 1</span> - <span><CommonSvgContent stringToRet="pin" /> Ovunque</span>
            <h5>Per fare un missile</h5>
            <p>Nei depositi del Museo sono custoditi alcuni componenti dei razzi V2 sviluppati da Wernher von Braun; con questa arma la Germania nazista colpì Londra nel corso del secondo conflitto mondiale.</p>
         </>
      )}
      {(string === 'desc_mission4' && lang === 'ita') && (
         <>
            <span><CommonSvgContent stringToRet="diff2" /> Difficoltà 2</span> - <span><CommonSvgContent stringToRet="pin" /> Reti, industrie e consumi</span>
            <h5>Programmi & Paperclip</h5>
            <p>Nel 1965 la Olivetti realizza una calcolatrice da tavolo che viene usata dalla NASA per l’atterraggio sulla Luna. Avete la certezza che contenga qualche formula degli Antichi.</p>
         </>
      )}
      {(string === 'desc_mission5' && lang === 'ita') && (
         <>
            <span><CommonSvgContent stringToRet="diff2" /> Difficoltà 2</span> - <span><CommonSvgContent stringToRet="pin" /> Pad. Ferroviario</span>
            <h5>Rap binario</h5>
            <p>Udite in lontananza le rime di un pezzo rap che vi conducono nel padiglione ferroviario.</p>
         </>
      )}
      {(string === 'desc_mission6' && lang === 'ita') && (
         <>
            <span><CommonSvgContent stringToRet="diff3" /> Difficoltà 3</span> - <span><CommonSvgContent stringToRet="pin" /> Spazio</span>
            <h5>Verso le stelle (e strisce) e oltre</h5>
            <p>Nella sezione Spazio è esposta una piccola bandiera americana che è stata portata sulla Luna dalla missione Apollo 11. È un oggetto simbolico per gli Echelon ed è certo contenga un importante Glifo.</p>
         </>
      )}
      {(string === 'desc_mission7' && lang === 'ita') && (
         <>
            <span><CommonSvgContent stringToRet="diff3" /> Difficoltà 3</span> - <span><CommonSvgContent stringToRet="pin" /> Pad. Aeronavale</span>
            <h5>Polene, draghi e voli pindarici</h5>
            <p>Ripercorrete le tappe del mitologico viaggio di Giasone per conquistare il manto dorato dell’ariete alato Crisomallo.</p>
         </>
      )}
      {(string === 'desc_mission8' && lang === 'ita') && (
         <>
            <span><CommonSvgContent stringToRet="diff3" /> Difficoltà 3</span> - <span><CommonSvgContent stringToRet="pin" /> Leonardo Da Vinci</span>
            <h5>Un filetto preparato a puntino</h5>
            <p>Una mappa all’interno della borsa indica la posizione di una macchina di Leonardo da Vinci che contiene il primo Glifo degli Antichi di cui si abbia conoscenza.</p>
         </>
      )}

      {/* MISSIONS DESC ENG */}

      {(string === 'desc_mission1' && lang === 'eng') && (
         <>
            <span><CommonSvgContent stringToRet="diff1" /> Difficulty 1</span> - <span><CommonSvgContent stringToRet="pin" /> Leonardo Da Vinci</span>
            <h5>A golden ratio</h5>
            <p>The polyhedron in your kit glows. You can be certain that by returning it to its original place it will provide important clues about the Glyphs.</p>
         </>
      )}
      {(string === 'desc_mission2' && lang === 'eng') && (
         <>
            <span><CommonSvgContent stringToRet="diff1" /> Difficulty 1</span> - <span><CommonSvgContent stringToRet="pin" /> Space</span>
            <h5>The ghost object</h5>
            <p>Meta Dendral informs you that there is a crucial Glyph on an object displayed in the Space section.</p>
         </>
      )}
      {(string === 'desc_mission3' && lang === 'eng') && (
         <>
            <span><CommonSvgContent stringToRet="diff1" /> Difficulty 1</span> - <span><CommonSvgContent stringToRet="pin" /> Everywhere</span>
            <h5>Rocket science</h5>
            <p>The Museum’s storage rooms hold components of the V2 rockets developed by Wernher von Braun, the same rockets that Nazi Germany used to bomb London during the Second World War.</p>
         </>
      )}
      {(string === 'desc_mission4' && lang === 'eng') && (
         <>
            <span><CommonSvgContent stringToRet="diff2" /> Difficulty 2</span> - <span><CommonSvgContent stringToRet="pin" /> Networks, industry and consumption</span>
            <h5>Programs & Paperclips</h5>
            <p>In 1965, Olivetti developed a desktop calculator that NASA would use to land on the Moon. You can be certain that it contains a few of the Ancients’ secret formulas.</p>
         </>
      )}
      {(string === 'desc_mission5' && lang === 'eng') && (
         <>
            <span><CommonSvgContent stringToRet="diff2" /> Difficulty 2</span> - <span><CommonSvgContent stringToRet="pin" /> Railway Transport</span>
            <h5>Rail Rap</h5>
            <p>You hear the rhymes of a rap song in the distance, leading you into the railway pavilion.</p>
         </>
      )}
      {(string === 'desc_mission6' && lang === 'eng') && (
         <>
            <span><CommonSvgContent stringToRet="diff3" /> Difficulty 3</span> - <span><CommonSvgContent stringToRet="pin" /> Space</span>
            <h5>To the stars (and stripes) and beyond</h5>
            <p>In the Space section, there is a small American flag which was taken to the Moon by the Apollo 11 mission. It is a symbolic object for the Echelonites and it undoubtedly contains an important Glyph.</p>
         </>
      )}
      {(string === 'desc_mission7' && lang === 'eng') && (
         <>
            <span><CommonSvgContent stringToRet="diff3" /> Difficulty 3</span> - <span><CommonSvgContent stringToRet="pin" /> Air and Water Transport Building</span>
            <h5>Figureheads, dragons and flights of fancy</h5>
            <p>Trace back over the steps of Jason’s mythical journey to retrieve the golden fleece of the winged ram Chrysomallos.</p>
         </>
      )}
      {(string === 'desc_mission8' && lang === 'eng') && (
         <>
            <span><CommonSvgContent stringToRet="diff3" /> Difficulty 3</span> - <span><CommonSvgContent stringToRet="pin" /> Leonardo Da Vinci</span>
            <h5>A perfect thread</h5>
            <p>A map inside your kit reveals the location of a machine designed by Leonardo da Vinci which contains the first known Glyph of the Ancients.</p>
         </>
      )}

      {/* MISSIONS DESC FRA */}

      {(string === 'desc_mission1' && lang === 'fra') && (
         <>
            <span><CommonSvgContent stringToRet="diff1" /> Difficulté 1</span> - <span><CommonSvgContent stringToRet="pin" /> Leonardo Da Vinci</span>
            <h5>Une proportion divine</h5>
            <p>L'objet polyédrique contenu dans le sac émet une lueur. Le retour de cet objet à son lieu d'origine vous fournira avec certitude des indices importants sur les Glyphes.  </p>
         </>
      )}
      {(string === 'desc_mission2' && lang === 'fra') && (
         <>
            <span><CommonSvgContent stringToRet="diff1" /> Difficulté 1</span> - <span><CommonSvgContent stringToRet="pin" /> Espace</span>
            <h5>L'objet fantôme</h5>
            <p>Meta Dendral vous informe qu’un Glyphe important est présent sur un objet exposé dans la section Espace.  </p>
         </>
      )}
      {(string === 'desc_mission3' && lang === 'fra') && (
         <>
            <span><CommonSvgContent stringToRet="diff1" /> Difficulté 1</span> - <span><CommonSvgContent stringToRet="pin" /> Partout</span>
            <h5>Pour fabriquer une fusée</h5>
            <p>Les réserves du Musée conservent certains composants des fusées V2 développées par Wernher von Braun ; c'est avec cette arme que l'Allemagne nazie a frappé Londres pendant la Seconde Guerre mondiale.  </p>
         </>
      )}
      {(string === 'desc_mission4' && lang === 'fra') && (
         <>
            <span><CommonSvgContent stringToRet="diff2" /> Difficulté 2</span> - <span><CommonSvgContent stringToRet="pin" /> Networks, industries and consumption</span>
            <h5>Programmes et Paperclip</h5>
            <p>En 1965, Olivetti a produit une calculatrice de bureau qui a été utilisée par la NASA pour l'alunissage. Vous avez la certitude qu'il contient une formule des Anciens.  </p>
         </>
      )}
      {(string === 'desc_mission5' && lang === 'fra') && (
         <>
            <span><CommonSvgContent stringToRet="diff2" /> Difficulté 2</span> - <span><CommonSvgContent stringToRet="pin" /> Pav. Ferroviaire</span>
            <h5>Rap voie</h5>
            <p>Entendez au loin les rimes d'un morceau de rap qui vous conduisent au pavillon ferroviaire.</p>
         </>
      )}
      {(string === 'desc_mission6' && lang === 'fra') && (
         <>
            <span><CommonSvgContent stringToRet="diff3" /> Difficulté 3</span> - <span><CommonSvgContent stringToRet="pin" /> Espace</span>
            <h5>Jusqu'aux étoiles (et aux rayures) et au-delà</h5>
            <p>Dans la section Espace est exposé un petit drapeau américain qui a été transporté sur la Lune par la mission Apollo 11. Il s'agit d'un objet symbolique pour les Echelon et il est certain qu'il contient un Glyphe important.</p>
         </>
      )}
      {(string === 'desc_mission7' && lang === 'fra') && (
         <>
            <span><CommonSvgContent stringToRet="diff3" /> Difficulté 3</span> - <span><CommonSvgContent stringToRet="pin" /> Pav. Aéronavale</span>
            <h5>Figures de proue, dragons et vols pindariques</h5>
            <p>Retracez les étapes du voyage mythologique de Jason à la conquête du manteau en or du bélier ailé Chrysomalus.</p>
         </>
      )}
      {(string === 'desc_mission8' && lang === 'fra') && (
         <>
            <span><CommonSvgContent stringToRet="diff3" /> Difficulté 3</span> - <span><CommonSvgContent stringToRet="pin" /> Leonardo Da Vinci</span>
            <h5>Un filetage préparé à la perfection</h5>
            <p>Une carte à l'intérieur du sac indique l'emplacement d'une machine de Léonard de Vinci contenant le premier Glyphe des Anciens connu.  </p>
         </>
      )}
   </>
)}
