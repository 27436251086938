import React from 'react'

const MaskSvgContent = (props) => {
  return (
     <g className="mask" fill="#333" >
         <rect x="0" y="0" width="865" height="22" />
         <rect x="0" y="19" width="865" height="22" />
         <rect x="0" y="39" width="865" height="22" />
         <rect x="0" y="59" width="865" height="22" />
         <rect x="0" y="79" width="865" height="22" />
         <rect x="0" y="99" width="865" height="22" />
         <rect x="0" y="119" width="865" height="22" />
         <rect x="0" y="139" width="865" height="22" />
         <rect x="0" y="159" width="865" height="22" />
         <rect x="0" y="179" width="865" height="22" />
         <rect x="0" y="199" width="865" height="22" />
         <rect x="0" y="219" width="865" height="22" />
         <rect x="0" y="239" width="865" height="22" />
         <rect x="0" y="259" width="865" height="22" />
         <rect x="0" y="279" width="865" height="22" />
         <rect x="0" y="299" width="865" height="22" />
         <rect x="0" y="319" width="865" height="22" />
         <rect x="0" y="339" width="865" height="22" />
         <rect x="0" y="359" width="865" height="22" />
         <rect x="0" y="379" width="865" height="22" />
         <rect x="0" y="399" width="865" height="22" />
         <rect x="0" y="419" width="865" height="22" />
         <rect x="0" y="439" width="865" height="22" />
         <rect x="0" y="459" width="865" height="22" />
         <rect x="0" y="479" width="865" height="22" />
         <rect x="0" y="499" width="865" height="22" />
         <rect x="0" y="519" width="865" height="22" />
         <rect x="0" y="539" width="865" height="22" />
         <rect x="0" y="559" width="865" height="22" />
         <rect x="0" y="579" width="865" height="22" />
     </g>
 )
}


export const IconsSvgContent = (props) => {
  const string = props.stringToRet;

  return (
    <>

      {(string === 'iconM0') && (
         <svg className="iconSvg icon0Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 865 594">
             <g>
               <rect width="865" height="594" fill="none" />
               <rect x="264.06" y="107.07" width="18.92" height="342.21" fill="#cccbcb" />
               <rect x="283.59" y="464.73" width="17.74" height="49.84" fill="#cccbcb" />
               <rect x="589.77" y="466.98" width="17.63" height="49.84" fill="#cccbcb" />
               <rect x="607.96" y="107.73" width="16.66" height="341.55" fill="#cccbcb" />
               <rect x="301.33" y="72.06" width="288" height="17.07" fill="#cccbcb" />
               <rect x="283.26" y="448.69" width="324.66" height="18.09" fill="#cccbcb" />
               <rect x="283.26" y="513.49" width="324.71" height="17.11" fill="#cccbcb" />
               <rect x="319.32" y="323.92" width="243.47" height="7.81" fill="#cccbcb" />
               <rect x="472.45" y="386.75" width="87.92" height="8.32" fill="#cccbcb" />
               <rect x="301.03" y="404.63" width="31.76" height="9.64" fill="#cccbcb" />
               <rect x="319.31" y="125.7" width="242.91" height="8.42" fill="#cccbcb" />
               <rect x="310.93" y="133.61" width="7.97" height="189.3" fill="#cccbcb" />
               <rect x="562.9" y="134.4" width="8.08" height="188.67" fill="#cccbcb" />
               <rect x="282.33" y="89.37" width="19.12" height="18.38" fill="#cccbcb" />
               <rect x="588.94" y="89.37" width="19.12" height="18.38" fill="#cccbcb" />
               <rect x="436.49" y="197.46" width="18.69" height="17.94" fill="#0ad400" />
               <rect x="472.29" y="215.71" width="18.69" height="17.94" fill="#0ad400" />
               <rect x="399.76" y="215.58" width="18.69" height="17.94" fill="#0ad400" />
               <rect x="382.48" y="179.58" width="18.09" height="17.98" fill="#0ad400" />
               <rect x="400.55" y="179.58" width="18.09" height="17.98" fill="#0ad400" />
               <rect x="418.62" y="179.58" width="17.95" height="17.98" fill="#0ad400" />
               <rect x="436.13" y="179.58" width="18.09" height="17.98" fill="#0ad400" />
               <rect x="454.2" y="179.58" width="18.09" height="17.98" fill="#0ad400" />
               <rect x="472.27" y="179.58" width="17.95" height="17.98" fill="#0ad400" />
               <rect x="490.06" y="179.58" width="17.95" height="17.98" fill="#0ad400" />
               <rect x="436.49" y="215.49" width="18.69" height="17.94" fill="#0ad400" />
               <rect x="436.49" y="233.51" width="18.69" height="17.94" fill="#0ad400" />
               <rect x="436.49" y="269.73" width="17.87" height="17.94" fill="#0ad400" />
               <rect x="695.96" y="-.15" width="17.98" height="18.28" transform="translate(695.96 713.93) rotate(-90)" fill="#cccbcb" />
               <rect x="229.1" y="35.25" width="17.98" height="18.28" transform="translate(193.7 282.47) rotate(-90)" fill="#cccbcb" />
               <rect x="86.82" y="-.15" width="17.98" height="18.28" transform="translate(86.82 104.8) rotate(-90)" fill="#cccbcb" />
               <rect x="72.37" y="575.66" width="18.35" height="18.23" transform="translate(-503.23 666.31) rotate(-90)" fill="#cccbcb" />
               <rect x="773.6" y="504.52" width="17.98" height="18.28" transform="translate(268.93 1296.25) rotate(-90)" fill="#cccbcb" />
               <rect x="700.19" y="575.82" width="17.98" height="18.28" transform="translate(124.22 1294.14) rotate(-90)" fill="#cccbcb" />
             </g>
             <MaskSvgContent />
         </svg>
      )}

      {(string === 'iconM1') && (
         <svg className="iconSvg icon1Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 865 594">
             <g>
               <rect width="865" height="594" fill="none" />
               <g>
                 <rect x="219.01" y="71.93" width="126.1" height="18.03" fill="#cccbcb" />
                 <rect x="506.93" y="71.93" width="125.92" height="18.03" fill="#cccbcb" />
                 <rect x="130.12" y="287.26" width="591.65" height="17.94" transform="translate(129.71 722.18) rotate(-90)" fill="#cccbcb" />
                 <rect x="381.01" y="18.08" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="363.22" y="36.16" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="345.28" y="53.95" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="399.09" y="0" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="470.95" y="35.82" width="17.98" height="18.28" transform="translate(524.91 -434.98) rotate(90)" fill="#cccbcb" />
                 <rect x="452.87" y="18.03" width="17.98" height="18.28" transform="translate(489.04 -434.7) rotate(90)" fill="#cccbcb" />
                 <rect x="435.08" y=".09" width="17.98" height="18.28" transform="translate(453.31 -434.84) rotate(90)" fill="#cccbcb" />
                 <rect x="489.03" y="53.9" width="17.98" height="18.28" transform="translate(561.07 -434.98) rotate(90)" fill="#cccbcb" />
                 <rect x="453.45" y="125.96" width="17.98" height="18.28" transform="translate(924.88 270.2) rotate(180)" fill="#cccbcb" />
                 <rect x="434.99" y="144.04" width="17.98" height="18.28" transform="translate(887.97 306.36) rotate(180)" fill="#cccbcb" />
                 <rect x="399.21" y="144.04" width="17.98" height="18.28" transform="translate(816.41 306.36) rotate(180)" fill="#cccbcb" />
                 <rect x="471.24" y="107.88" width="17.98" height="18.28" transform="translate(960.47 234.05) rotate(180)" fill="#cccbcb" />
                 <rect x="489.18" y="90.09" width="17.98" height="18.28" transform="translate(996.34 198.46) rotate(180)" fill="#cccbcb" />
                 <rect x="579.62" y="126.34" width="17.98" height="18.28" transform="translate(1177.22 270.96) rotate(180)" fill="#cccbcb" />
                 <rect x="561.16" y="144.42" width="17.98" height="18.28" transform="translate(1140.31 307.11) rotate(180)" fill="#cccbcb" />
                 <rect x="597.41" y="108.26" width="17.98" height="18.28" transform="translate(1212.81 234.8) rotate(180)" fill="#cccbcb" />
                 <rect x="615.18" y="89.94" width="17.98" height="18.28" transform="translate(1248.34 198.16) rotate(180)" fill="#cccbcb" />
                 <rect x="632.86" y="90.46" width="17.3" height="124.83" transform="translate(1283.03 305.75) rotate(180)" fill="#cccbcb" />
                 <rect x="507.89" y="198.31" width="17.98" height="18.28" transform="translate(1033.75 414.91) rotate(180)" fill="#cccbcb" />
                 <rect x="489.43" y="216.39" width="17.98" height="18.28" transform="translate(996.85 451.06) rotate(180)" fill="#cccbcb" />
                 <rect x="525.68" y="180.24" width="17.98" height="18.28" transform="translate(1069.35 378.75) rotate(180)" fill="#cccbcb" />
                 <rect x="543.62" y="162.44" width="17.98" height="18.28" transform="translate(1105.22 343.16) rotate(180)" fill="#cccbcb" />
                 <rect x="434.91" y="270.34" width="17.98" height="18.28" transform="translate(887.8 558.96) rotate(180)" fill="#cccbcb" />
                 <rect x="452.99" y="252.26" width="17.98" height="18.28" transform="translate(923.96 522.81) rotate(180)" fill="#cccbcb" />
                 <rect x="470.92" y="234.47" width="17.98" height="18.28" transform="translate(959.83 487.22) rotate(180)" fill="#cccbcb" />
                 <rect x="363.16" y="233.84" width="17.98" height="18.28" transform="translate(129.17 615.14) rotate(-90)" fill="#cccbcb" />
                 <rect x="345.08" y="215.39" width="17.98" height="18.28" transform="translate(129.55 578.6) rotate(-90)" fill="#cccbcb" />
                 <rect x="381.24" y="251.64" width="17.98" height="18.28" transform="translate(129.45 651.01) rotate(-90)" fill="#cccbcb" />
                 <rect x="399.6" y="270.42" width="17.98" height="18.28" transform="translate(129.03 688.16) rotate(-90)" fill="#cccbcb" />
                 <rect x="291.19" y="162.11" width="17.98" height="18.28" transform="translate(128.93 471.43) rotate(-90)" fill="#cccbcb" />
                 <rect x="273.11" y="143.66" width="17.98" height="18.28" transform="translate(129.3 434.9) rotate(-90)" fill="#cccbcb" />
                 <rect x="309.26" y="179.91" width="17.98" height="18.28" transform="translate(129.21 507.3) rotate(-90)" fill="#cccbcb" />
                 <rect x="327.06" y="197.84" width="17.98" height="18.28" transform="translate(129.07 543.04) rotate(-90)" fill="#cccbcb" />
                 <rect x="237.24" y="107.21" width="17.98" height="18.28" transform="translate(129.87 362.58) rotate(-90)" fill="#cccbcb" />
                 <rect x="219.16" y="89.7" width="17.98" height="18.28" transform="translate(129.31 326.99) rotate(-90)" fill="#cccbcb" />
                 <rect x="146.59" y="144.38" width="126.21" height="18.28" transform="translate(56.18 363.21) rotate(-90)" fill="#cccbcb" />
                 <rect x="255.03" y="125.15" width="17.98" height="18.28" transform="translate(129.73 398.32) rotate(-90)" fill="#cccbcb" />
                 <rect x="363.51" y="108.21" width="17.98" height="18.28" transform="translate(255.14 489.86) rotate(-90)" fill="#cccbcb" />
                 <rect x="381.58" y="126.01" width="17.98" height="18.28" transform="translate(255.43 525.73) rotate(-90)" fill="#cccbcb" />
                 <rect x="345.43" y="90.14" width="17.98" height="18.28" transform="translate(255.14 453.7) rotate(-90)" fill="#cccbcb" />
                 <rect x="128.55" y="288.44" width="595.06" height="17.94" transform="translate(852.16 594.81) rotate(180)" fill="#cccbcb" />
                 <rect x="164.12" y="234.27" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="146.32" y="252.34" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="128.39" y="270.14" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="182.2" y="216.19" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="254.26" y="251.77" width="17.98" height="18.28" transform="translate(524.16 -2.34) rotate(90)" fill="#cccbcb" />
                 <rect x="272.33" y="270.22" width="17.98" height="18.28" transform="translate(560.69 -1.96) rotate(90)" fill="#cccbcb" />
                 <rect x="236.18" y="233.97" width="17.98" height="18.28" transform="translate(488.29 -2.05) rotate(90)" fill="#cccbcb" />
                 <rect x="218.38" y="216.04" width="17.98" height="18.28" transform="translate(452.56 -2.2) rotate(90)" fill="#cccbcb" />
                 <rect x="597.35" y="234.27" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="579.55" y="252.34" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="561.62" y="270.14" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="615.43" y="216.19" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="687.49" y="251.77" width="17.98" height="18.28" transform="translate(957.39 -435.57) rotate(90)" fill="#cccbcb" />
                 <rect x="705.56" y="270.22" width="17.98" height="18.28" transform="translate(993.92 -435.19) rotate(90)" fill="#cccbcb" />
                 <rect x="669.41" y="233.97" width="17.98" height="18.28" transform="translate(921.52 -435.28) rotate(90)" fill="#cccbcb" />
                 <rect x="651.61" y="216.04" width="17.98" height="18.28" transform="translate(885.79 -435.43) rotate(90)" fill="#cccbcb" />
                 <rect x="507.07" y="504.04" width="126.1" height="18.03" transform="translate(1140.23 1026.11) rotate(180)" fill="#cccbcb" />
                 <rect x="219.32" y="504.04" width="125.92" height="18.03" transform="translate(564.56 1026.11) rotate(180)" fill="#cccbcb" />
                 <rect x="130.4" y="288.8" width="591.65" height="17.94" transform="translate(724 -128.46) rotate(90)" fill="#cccbcb" />
                 <rect x="453.18" y="557.64" width="17.98" height="18.28" transform="translate(924.34 1133.56) rotate(180)" fill="#cccbcb" />
                 <rect x="470.97" y="539.56" width="17.98" height="18.28" transform="translate(959.93 1097.41) rotate(180)" fill="#cccbcb" />
                 <rect x="488.91" y="521.77" width="17.98" height="18.28" transform="translate(995.8 1061.82) rotate(180)" fill="#cccbcb" />
                 <rect x="435.1" y="575.72" width="17.98" height="18.28" transform="translate(888.18 1169.72) rotate(180)" fill="#cccbcb" />
                 <rect x="363.23" y="539.89" width="17.98" height="18.28" transform="translate(-176.81 921.26) rotate(-90)" fill="#cccbcb" />
                 <rect x="381.31" y="557.69" width="17.98" height="18.28" transform="translate(-176.52 957.13) rotate(-90)" fill="#cccbcb" />
                 <rect x="399.11" y="575.62" width="17.98" height="18.28" transform="translate(-176.67 992.87) rotate(-90)" fill="#cccbcb" />
                 <rect x="345.16" y="521.81" width="17.98" height="18.28" transform="translate(-176.81 885.11) rotate(-90)" fill="#cccbcb" />
                 <rect x="380.74" y="449.76" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="399.19" y="431.68" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="434.97" y="431.68" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="362.94" y="467.83" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="345.01" y="485.63" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="254.57" y="449.38" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="273.03" y="431.3" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="236.78" y="467.46" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="219.01" y="485.78" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="201.44" y="378.71" width="17.3" height="124.83" fill="#cccbcb" />
                 <rect x="326.3" y="377.4" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="344.76" y="359.33" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="308.51" y="395.48" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="290.57" y="413.28" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="399.28" y="305.38" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="381.2" y="323.45" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="363.26" y="341.25" width="17.98" height="18.28" fill="#cccbcb" />
                 <rect x="471.03" y="341.88" width="17.98" height="18.28" transform="translate(831.04 -129) rotate(90)" fill="#cccbcb" />
                 <rect x="489.1" y="360.33" width="17.98" height="18.28" transform="translate(867.57 -128.62) rotate(90)" fill="#cccbcb" />
                 <rect x="452.95" y="324.08" width="17.98" height="18.28" transform="translate(795.16 -128.72) rotate(90)" fill="#cccbcb" />
                 <rect x="434.59" y="305.29" width="17.98" height="18.28" transform="translate(758.01 -129.14) rotate(90)" fill="#cccbcb" />
                 <rect x="543" y="413.61" width="17.98" height="18.28" transform="translate(974.74 -129.25) rotate(90)" fill="#cccbcb" />
                 <rect x="561.08" y="432.06" width="17.98" height="18.28" transform="translate(1011.27 -128.87) rotate(90)" fill="#cccbcb" />
                 <rect x="524.92" y="395.81" width="17.98" height="18.28" transform="translate(938.87 -128.96) rotate(90)" fill="#cccbcb" />
                 <rect x="507.13" y="377.87" width="17.98" height="18.28" transform="translate(903.14 -129.1) rotate(90)" fill="#cccbcb" />
                 <rect x="596.95" y="468.5" width="17.98" height="18.28" transform="translate(1083.59 -128.3) rotate(90)" fill="#cccbcb" />
                 <rect x="615.03" y="486.02" width="17.98" height="18.28" transform="translate(1119.18 -128.86) rotate(90)" fill="#cccbcb" />
                 <rect x="579.09" y="431.34" width="126.21" height="18.28" transform="translate(1082.67 -201.71) rotate(90)" fill="#cccbcb" />
                 <rect x="579.16" y="450.57" width="17.98" height="18.28" transform="translate(1047.86 -128.44) rotate(90)" fill="#cccbcb" />
                 <rect x="470.68" y="467.5" width="17.98" height="18.28" transform="translate(956.32 -3.03) rotate(90)" fill="#cccbcb" />
                 <rect x="452.6" y="449.71" width="17.98" height="18.28" transform="translate(920.44 -2.75) rotate(90)" fill="#cccbcb" />
                 <rect x="488.76" y="485.58" width="17.98" height="18.28" transform="translate(992.47 -3.03) rotate(90)" fill="#cccbcb" />
                 <rect x="669.78" y="341.45" width="17.98" height="18.28" transform="translate(1357.55 701.19) rotate(180)" fill="#cccbcb" />
                 <rect x="687.58" y="323.37" width="17.98" height="18.28" transform="translate(1393.14 665.03) rotate(180)" fill="#cccbcb" />
                 <rect x="705.52" y="305.58" width="17.98" height="18.28" transform="translate(1429.02 629.44) rotate(180)" fill="#cccbcb" />
                 <rect x="651.71" y="359.53" width="17.98" height="18.28" transform="translate(1321.4 737.34) rotate(180)" fill="#cccbcb" />
                 <rect x="579.65" y="323.95" width="17.98" height="18.28" transform="translate(255.55 921.73) rotate(-90)" fill="#cccbcb" />
                 <rect x="561.57" y="305.49" width="17.98" height="18.28" transform="translate(255.93 885.2) rotate(-90)" fill="#cccbcb" />
                 <rect x="597.73" y="341.74" width="17.98" height="18.28" transform="translate(255.83 957.6) rotate(-90)" fill="#cccbcb" />
                 <rect x="615.52" y="359.68" width="17.98" height="18.28" transform="translate(255.69 993.33) rotate(-90)" fill="#cccbcb" />
                 <rect x="236.55" y="341.45" width="17.98" height="18.28" transform="translate(491.09 701.19) rotate(180)" fill="#cccbcb" />
                 <rect x="254.35" y="323.37" width="17.98" height="18.28" transform="translate(526.68 665.03) rotate(180)" fill="#cccbcb" />
                 <rect x="272.29" y="305.58" width="17.98" height="18.28" transform="translate(562.56 629.44) rotate(180)" fill="#cccbcb" />
                 <rect x="218.48" y="359.53" width="17.98" height="18.28" transform="translate(454.94 737.34) rotate(180)" fill="#cccbcb" />
                 <rect x="146.42" y="323.95" width="17.98" height="18.28" transform="translate(-177.68 488.5) rotate(-90)" fill="#cccbcb" />
                 <rect x="128.34" y="305.49" width="17.98" height="18.28" transform="translate(-177.3 451.97) rotate(-90)" fill="#cccbcb" />
                 <rect x="164.5" y="341.74" width="17.98" height="18.28" transform="translate(-177.4 524.37) rotate(-90)" fill="#cccbcb" />
                 <rect x="182.29" y="359.68" width="17.98" height="18.28" transform="translate(-177.54 560.1) rotate(-90)" fill="#cccbcb" />
               </g>
             </g>
             <MaskSvgContent />
         </svg>

      )}

      {(string === 'iconM1free') && (
         <svg className="iconSvg icon0Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 865 594">
             <g>
               <rect width="865" height="594" fill="none" />
               <rect x="264.06" y="107.07" width="18.92" height="342.21" fill="#cccbcb" />
               <rect x="283.59" y="464.73" width="17.74" height="49.84" fill="#cccbcb" />
               <rect x="589.77" y="466.98" width="17.63" height="49.84" fill="#cccbcb" />
               <rect x="607.96" y="107.73" width="16.66" height="341.55" fill="#cccbcb" />
               <rect x="301.33" y="72.06" width="288" height="17.07" fill="#cccbcb" />
               <rect x="283.26" y="448.69" width="324.66" height="18.09" fill="#cccbcb" />
               <rect x="283.26" y="513.49" width="324.71" height="17.11" fill="#cccbcb" />
               <rect x="319.32" y="323.92" width="243.47" height="7.81" fill="#cccbcb" />
               <rect x="472.45" y="386.75" width="87.92" height="8.32" fill="#cccbcb" />
               <rect x="301.03" y="404.63" width="31.76" height="9.64" fill="#cccbcb" />
               <rect x="319.31" y="125.7" width="242.91" height="8.42" fill="#cccbcb" />
               <rect x="310.93" y="133.61" width="7.97" height="189.3" fill="#cccbcb" />
               <rect x="562.9" y="134.4" width="8.08" height="188.67" fill="#cccbcb" />
               <rect x="282.33" y="89.37" width="19.12" height="18.38" fill="#cccbcb" />
               <rect x="588.94" y="89.37" width="19.12" height="18.38" fill="#cccbcb" />
               <rect x="436.49" y="197.46" width="18.69" height="17.94" fill="#0ad400" />
               <rect x="472.29" y="215.71" width="18.69" height="17.94" fill="#0ad400" />
               <rect x="399.76" y="215.58" width="18.69" height="17.94" fill="#0ad400" />
               <rect x="382.48" y="179.58" width="18.09" height="17.98" fill="#0ad400" />
               <rect x="400.55" y="179.58" width="18.09" height="17.98" fill="#0ad400" />
               <rect x="418.62" y="179.58" width="17.95" height="17.98" fill="#0ad400" />
               <rect x="436.13" y="179.58" width="18.09" height="17.98" fill="#0ad400" />
               <rect x="454.2" y="179.58" width="18.09" height="17.98" fill="#0ad400" />
               <rect x="472.27" y="179.58" width="17.95" height="17.98" fill="#0ad400" />
               <rect x="490.06" y="179.58" width="17.95" height="17.98" fill="#0ad400" />
               <rect x="436.49" y="215.49" width="18.69" height="17.94" fill="#0ad400" />
               <rect x="436.49" y="233.51" width="18.69" height="17.94" fill="#0ad400" />
               <rect x="436.49" y="269.73" width="17.87" height="17.94" fill="#0ad400" />
             </g>
         </svg>

      )}

      {(string === 'iconM2') && (
         <svg className="iconSvg icon2Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 865 594">
             <g>
               <rect width="865" height="594" fill="none" />
               <g>
                 <rect x="499.27" y="400.64" width="17.58" height="17.58" fill="#cccbcb" />
                 <rect x="499.28" y="418.21" width="17.58" height="17.58" fill="#cccbcb" />
                 <rect x="499.28" y="435.79" width="17.58" height="17.58" fill="#cccbcb" />
                 <rect x="499.29" y="453.37" width="17.58" height="17.58" fill="#cccbcb" />
                 <rect x="481.69" y="383.07" width="17.58" height="17.58" fill="#cccbcb" />
                 <rect x="481.7" y="400.64" width="17.58" height="17.58" fill="gray" />
                 <rect x="481.7" y="418.22" width="17.58" height="17.58" fill="gray" />
                 <rect x="481.71" y="435.8" width="17.58" height="17.58" fill="gray" />
                 <rect x="481.71" y="453.37" width="17.58" height="17.58" fill="gray" />
                 <rect x="464.08" y="260.04" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="464.09" y="277.62" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="464.09" y="295.19" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="464.1" y="312.77" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="464.1" y="330.35" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="464.11" y="347.92" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="464.11" y="365.5" width="17.58" height="17.58" fill="gray" />
                 <rect x="464.12" y="383.07" width="17.58" height="17.58" fill="gray" />
                 <rect x="464.12" y="400.65" width="17.58" height="17.58" fill="gray" />
                 <rect x="464.13" y="418.22" width="17.58" height="17.58" fill="gray" />
                 <rect x="464.13" y="435.8" width="17.58" height="17.58" fill="gray" />
                 <rect x="446.5" y="242.47" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="446.51" y="260.05" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="446.51" y="277.62" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="446.52" y="295.2" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="446.52" y="312.77" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="446.53" y="330.35" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="446.53" y="347.93" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="446.54" y="365.5" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="446.54" y="383.08" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="446.55" y="400.65" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="446.55" y="418.23" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="446.56" y="435.81" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="446.56" y="453.38" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="446.57" y="470.96" width="17.58" height="17.58" fill="#ec6c0a" />
                 <rect x="446.57" y="488.53" width="17.58" height="17.58" fill="#ec6c0a" />
                 <rect x="446.58" y="506.11" width="17.58" height="17.58" fill="#ec6c0a" />
                 <rect x="446.58" y="523.68" width="17.58" height="17.58" fill="#ec6c0a" />
                 <rect x="446.59" y="541.26" width="17.58" height="17.58" fill="#e52c41" />
                 <rect x="428.92" y="224.9" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="428.93" y="242.48" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="428.93" y="260.05" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="428.94" y="277.63" width="17.58" height="17.58" fill="gray" />
                 <rect x="428.94" y="295.2" width="17.58" height="17.58" fill="gray" />
                 <rect x="428.95" y="312.78" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="428.95" y="330.36" width="17.58" height="17.58" fill="gray" />
                 <rect x="428.96" y="347.93" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="428.96" y="365.51" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="428.97" y="383.08" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="428.97" y="400.66" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="428.98" y="418.23" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="428.98" y="435.81" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="428.99" y="453.39" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="428.99" y="470.96" width="17.58" height="17.58" fill="#f6a303" />
                 <rect x="429" y="488.54" width="17.58" height="17.58" fill="#f6a303" />
                 <rect x="429" y="506.11" width="17.58" height="17.58" fill="#f6a303" />
                 <rect x="429.01" y="523.69" width="17.58" height="17.58" fill="#f6a303" />
                 <rect x="429.01" y="541.27" width="17.58" height="17.58" fill="#ec6c0a" />
                 <rect x="429.02" y="558.84" width="17.58" height="17.58" fill="#e52c41" />
                 <rect x="411.35" y="224.91" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="411.35" y="242.48" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="411.36" y="260.06" width="17.58" height="17.58" fill="gray" />
                 <rect x="411.36" y="277.63" width="17.58" height="17.58" fill="gray" />
                 <rect x="411.37" y="295.21" width="17.58" height="17.58" fill="gray" />
                 <rect x="411.37" y="312.78" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="411.38" y="330.36" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="411.38" y="347.94" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="411.39" y="365.51" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="411.39" y="383.09" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="411.4" y="400.66" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="411.4" y="418.24" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="411.41" y="435.82" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="411.41" y="453.39" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="411.42" y="470.97" width="17.58" height="17.58" fill="#ffe640" />
                 <rect x="411.42" y="488.54" width="17.58" height="17.58" fill="#ffe640" />
                 <rect x="411.42" y="506.12" width="17.58" height="17.58" fill="#ffe640" />
                 <rect x="411.43" y="523.69" width="17.58" height="17.58" fill="#f6a303" />
                 <rect x="411.43" y="541.27" width="17.58" height="17.58" fill="#f6a303" />
                 <rect x="411.44" y="558.85" width="17.58" height="17.58" fill="#ec6c0a" />
                 <rect x="411.44" y="576.42" width="17.58" height="17.58" fill="#e52c41" />
                 <rect x="393.77" y="224.91" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="393.77" y="242.49" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="393.78" y="260.06" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="393.78" y="277.64" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="393.79" y="295.21" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="393.79" y="312.79" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="393.8" y="330.37" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="393.8" y="347.94" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="393.81" y="365.52" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="393.81" y="383.09" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="393.82" y="400.67" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="393.82" y="418.24" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="393.83" y="435.82" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="393.83" y="453.4" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="393.84" y="470.97" width="17.58" height="17.58" fill="#f6a303" />
                 <rect x="393.84" y="488.55" width="17.58" height="17.58" fill="#f6a303" />
                 <rect x="393.85" y="506.12" width="17.58" height="17.58" fill="#f6a303" />
                 <rect x="393.85" y="523.7" width="17.58" height="17.58" fill="#f6a303" />
                 <rect x="393.86" y="541.28" width="17.58" height="17.58" fill="#ec6c0a" />
                 <rect x="393.86" y="558.85" width="17.58" height="17.58" fill="#e52c41" />
                 <rect x="376.2" y="242.49" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="376.2" y="260.07" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="376.21" y="277.64" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="376.21" y="295.22" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="376.22" y="312.79" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="376.22" y="330.37" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="376.23" y="347.95" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="376.23" y="365.52" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="376.24" y="383.1" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="376.24" y="400.67" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="376.25" y="418.25" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="376.25" y="435.83" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="376.26" y="453.4" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="376.26" y="470.98" width="17.58" height="17.58" fill="#ec6c0a" />
                 <rect x="376.27" y="488.55" width="17.58" height="17.58" fill="#ec6c0a" />
                 <rect x="376.27" y="506.13" width="17.58" height="17.58" fill="#ec6c0a" />
                 <rect x="376.28" y="523.7" width="17.58" height="17.58" fill="#ec6c0a" />
                 <rect x="376.28" y="541.28" width="17.58" height="17.58" fill="#e52c41" />
                 <rect x="358.63" y="260.07" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="358.63" y="277.65" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="358.64" y="295.22" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="358.64" y="312.8" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="358.65" y="330.38" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="358.65" y="347.95" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="358.66" y="365.53" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="358.66" y="383.1" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="358.67" y="400.68" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="358.67" y="418.25" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="358.68" y="435.83" width="17.58" height="17.58" fill="#e9e4e0" />
                 <rect x="341.09" y="383.11" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="341.09" y="400.68" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="341.1" y="418.26" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="341.1" y="435.84" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="341.11" y="453.41" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="323.52" y="400.69" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="323.52" y="418.26" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="323.53" y="435.84" width="17.58" height="17.58" fill="#4e4d4d" />
                 <rect x="323.53" y="453.42" width="17.58" height="17.58" fill="#4e4d4d" />
               </g>
               <g>
                 <rect x="444.93" y="140.67" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="427.34" y="140.67" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="409.76" y="140.67" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="392.18" y="140.67" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="374.59" y="140.67" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="462.51" y="123.09" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="444.93" y="123.09" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="427.34" y="123.09" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="409.76" y="123.09" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="392.18" y="123.09" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="374.59" y="123.09" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="357.01" y="123.09" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="480.1" y="105.5" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="462.51" y="105.5" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="444.93" y="105.5" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="427.34" y="105.5" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="409.76" y="105.5" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="392.18" y="105.5" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="374.59" y="105.5" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="357.01" y="105.5" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="339.42" y="105.5" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="480.1" y="87.92" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="462.51" y="87.92" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="444.93" y="87.92" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="427.34" y="87.92" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="409.76" y="87.92" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="392.18" y="87.92" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="374.59" y="87.92" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="357.01" y="87.92" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="339.42" y="87.92" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="480.1" y="70.34" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="462.51" y="70.34" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="444.93" y="70.34" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="427.34" y="70.34" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="409.76" y="70.34" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="392.18" y="70.34" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="374.59" y="70.34" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="357.01" y="70.34" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="339.42" y="70.34" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="480.1" y="52.75" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="462.51" y="52.75" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="444.93" y="52.75" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="427.34" y="52.75" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="409.76" y="52.75" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="392.18" y="52.75" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="374.59" y="52.75" width="17.58" height="17.58" fill="#cbcbcb" />
                 <rect x="357.01" y="52.75" width="17.58" height="17.58" fill="#cbcbcb" />
                 <rect x="339.42" y="52.75" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="480.1" y="35.17" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="462.51" y="35.17" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="444.93" y="35.17" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="427.34" y="35.17" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="409.76" y="35.17" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="392.18" y="35.17" width="17.58" height="17.58" fill="#cbcbcb" />
                 <rect x="374.59" y="35.17" width="17.58" height="17.58" fill="#cbcbcb" />
                 <rect x="357.01" y="35.17" width="17.58" height="17.58" fill="#cbcbcb" />
                 <rect x="339.42" y="35.17" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="462.51" y="17.58" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="444.93" y="17.58" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="427.34" y="17.58" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="409.76" y="17.58" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="392.18" y="17.58" width="17.58" height="17.58" fill="#cbcbcb" />
                 <rect x="374.59" y="17.58" width="17.58" height="17.58" fill="#cbcbcb" />
                 <rect x="357.01" y="17.58" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="444.93" y="0" width="17.58" height="17.58" fill="#4d4d4d" />
                 <rect x="427.34" y="0" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="409.76" y="0" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="392.18" y="0" width="17.58" height="17.58" fill="#b2b2b2" />
                 <rect x="374.59" y="0" width="17.58" height="17.58" fill="#b2b2b2" />
               </g>
               <rect x="147.78" y="142.84" width="17.98" height="18.28" transform="translate(4.79 308.75) rotate(-90)" fill="#ffe640" />
               <rect x="628.91" y="196.96" width="17.98" height="18.28" transform="translate(431.8 844) rotate(-90)" fill="#ffe640" />
               <rect x="792.67" y="-.15" width="17.98" height="18.28" transform="translate(792.67 810.65) rotate(-90)" fill="#ffe640" />
               <rect x="572.44" y="499.74" width="17.98" height="18.28" transform="translate(72.55 1090.3) rotate(-90)" fill="#ffe640" />
               <rect x="213.28" y="357.34" width="17.98" height="18.28" transform="translate(-144.2 588.75) rotate(-90)" fill="#ffe640" />
               <rect x="57.43" y="435.36" width="17.98" height="18.28" transform="translate(-378.08 510.91) rotate(-90)" fill="#ffe640" />
             </g>
             <MaskSvgContent />

         </svg>

      )}

      {(string === 'iconM3') && (
         <svg className="iconSvg icon3Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 865 594">
             <g>
               <rect width="865" height="594" fill="none" />
               <g>
                 <rect x="374.52" y="252.36" width="17.72" height="17.8" fill="#4e4d4d" />
                 <rect x="393.06" y="287.02" width="17.72" height="136.58" fill="#4e4d4d" />
                 <rect x="373.92" y="424.94" width="17.72" height="31.14" transform="translate(765.56 881.02) rotate(-180)" fill="#4e4d4d" />
                 <rect x="357.01" y="454.3" width="17.72" height="31.14" transform="translate(731.75 939.75) rotate(-180)" fill="#4e4d4d" />
                 <rect x="339.22" y="484.11" width="17.72" height="31.14" transform="translate(696.16 999.36) rotate(-180)" fill="#4e4d4d" />
                 <rect x="322.31" y="514.81" width="17.72" height="31.14" transform="translate(662.34 1060.76) rotate(-180)" fill="#4e4d4d" />
                 <rect x="304.96" y="545.51" width="17.72" height="31.14" transform="translate(627.64 1122.16) rotate(-180)" fill="#4e4d4d" />
                 <g>
                   <rect x="322.3" y="576.2" width="216.81" height="17.8" fill="#4e4d4d" />
                   <rect x="392.76" y="269.69" width="77.33" height="17.8" fill="#4e4d4d" />
                   <rect x="392.76" y="236.3" width="77.33" height="17.8" fill="#4e4d4d" />
                   <rect x="445.26" y="195.82" width="19.02" height="17.8" fill="#e500ff" />
                   <rect x="413.9" y="155.05" width="19.02" height="17.8" fill="#e500ff" />
                   <rect x="469.58" y="253.21" width="17.72" height="17.8" fill="#4e4d4d" />
                   <rect x="451.78" y="287.02" width="17.72" height="136.58" fill="#4e4d4d" />
                   <rect x="469.58" y="424.94" width="17.72" height="31.14" fill="#4e4d4d" />
                   <rect x="322.84" y="545.5" width="216.31" height="30.77" fill="#be07d8" />
                   <rect x="338.66" y="514.35" width="182.93" height="31.49" fill="#e500ff" />
                   <rect x="355.81" y="484.56" width="148.61" height="29.92" fill="#e667f9" />
                   <rect x="372.99" y="456.2" width="113.89" height="28.8" fill="#e9e4e0" />
                   <rect x="392.2" y="425.83" width="78.64" height="30.25" fill="#e9e4e0" />
                   <rect x="408.95" y="287.76" width="44.54" height="138.51" fill="#e9e4e0" />
                   <rect x="486.93" y="454.3" width="17.72" height="31.14" fill="#4e4d4d" />
                   <rect x="504.28" y="484.11" width="17.72" height="31.14" fill="#4e4d4d" />
                   <rect x="521.18" y="514.81" width="17.72" height="31.14" fill="#4e4d4d" />
                   <rect x="538.98" y="545.51" width="17.72" height="31.14" fill="#4e4d4d" />
                   <rect x="392.76" y="254.1" width="76.82" height="16.91" fill="#e9e4e0" />
                 </g>
               </g>
               <rect x="391.93" y="79.35" width="19.02" height="17.8" fill="#e500ff" />
               <rect x="446.52" y="0" width="19.02" height="17.8" fill="#e500ff" />
             </g>
             <MaskSvgContent />
         </svg>
      )}

      {(string === 'iconM4') && (
         <svg className="iconSvg icon4Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 865 594">
          <g>
            <rect width="865" height="594" fill="none" />
            <g>
              <rect x="160.53" y="220.8" width="556.13" height="19.34" fill="#e9e4e0" />
              <rect x="160.53" y="347.78" width="557.39" height="17.86" fill="#0ad400" />
              <rect x="160.48" y="365.52" width="558.11" height="18.3" fill="#0ad400" />
              <rect x="160.53" y="383.76" width="557.62" height="18.31" fill="#4e4d4d" />
              <rect x="152.69" y="202.28" width="565.93" height="17.63" fill="#4e4d4d" />
              <rect x="160.53" y="329.22" width="557.77" height="18.53" fill="#01567d" />
              <rect x="142.81" y="202.03" width="17.72" height="200.05" fill="#4e4d4d" />
              <rect x="718.19" y="202.42" width="18.97" height="199.6" fill="#4e4d4d" />
              <rect x="178.59" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="214.73" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="250.5" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="286.64" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="322.78" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="358.92" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="395.06" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="431.2" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="466.21" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="502.36" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="538.5" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="574.64" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="610.78" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="646.92" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="682.02" y="347.87" width="18.86" height="17.77" fill="#39a935" />
              <rect x="160.53" y="311.84" width="557.39" height="17.86" fill="#0ad400" />
              <rect x="178.59" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="214.73" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="250.5" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="286.64" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="322.78" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="358.92" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="395.06" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="431.2" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="466.21" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="502.36" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="538.5" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="574.64" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="610.78" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="646.92" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="682.02" y="311.93" width="18.86" height="17.77" fill="#39a935" />
              <rect x="160.53" y="275.7" width="557.39" height="17.86" fill="#0ad400" />
              <rect x="178.59" y="275.79" width="18.86" height="17.77" fill="#39a935" />
              <rect x="214.73" y="275.79" width="18.86" height="17.77" fill="#39a935" />
              <rect x="250.5" y="275.79" width="18.86" height="17.77" fill="#39a935" />
              <rect x="286.64" y="275.79" width="18.86" height="17.77" fill="#39a935" />
              <rect x="322.78" y="275.79" width="18.86" height="17.77" fill="#39a935" />
              <rect x="358.92" y="275.79" width="18.86" height="17.77" fill="#39a935" />
              <rect x="502.36" y="275.79" width="18.86" height="17.77" fill="#39a935" />
              <rect x="538.5" y="275.79" width="18.86" height="17.77" fill="#39a935" />
              <rect x="574.64" y="275.79" width="18.86" height="17.77" fill="#39a935" />
              <rect x="610.78" y="275.79" width="18.86" height="17.77" fill="#39a935" />
              <rect x="646.92" y="275.79" width="18.86" height="17.77" fill="#39a935" />
              <rect x="682.02" y="275.79" width="18.86" height="17.77" fill="#39a935" />
              <rect x="160.53" y="293.77" width="557.39" height="17.86" fill="aqua; opacity: .52" />
              <rect x="178.59" y="293.86" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="214.73" y="293.86" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="250.5" y="293.86" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="286.64" y="293.86" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="322.78" y="293.86" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="358.92" y="293.86" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="502.36" y="293.86" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="538.5" y="293.86" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="574.64" y="293.86" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="610.78" y="293.86" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="646.92" y="293.86" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="682.02" y="293.86" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="160.53" y="240.12" width="557.39" height="17.86" fill="#0ad400" />
              <rect x="178.59" y="240.22" width="18.86" height="17.77" fill="#39a935" />
              <rect x="214.73" y="240.22" width="18.86" height="17.77" fill="#39a935" />
              <rect x="250.5" y="240.22" width="18.86" height="17.77" fill="#39a935" />
              <rect x="286.64" y="240.22" width="18.86" height="17.77" fill="#39a935" />
              <rect x="322.78" y="240.22" width="18.86" height="17.77" fill="#39a935" />
              <rect x="358.92" y="240.22" width="18.86" height="17.77" fill="#39a935" />
              <rect x="502.36" y="240.22" width="18.86" height="17.77" fill="#39a935" />
              <rect x="538.5" y="240.22" width="18.86" height="17.77" fill="#39a935" />
              <rect x="574.64" y="240.22" width="18.86" height="17.77" fill="#39a935" />
              <rect x="610.78" y="240.22" width="18.86" height="17.77" fill="#39a935" />
              <rect x="646.92" y="240.22" width="18.86" height="17.77" fill="#39a935" />
              <rect x="682.02" y="240.22" width="18.86" height="17.77" fill="#39a935" />
              <rect x="160.53" y="258.19" width="557.39" height="17.86" fill="aqua; opacity: .52" />
              <rect x="178.59" y="258.29" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="214.73" y="258.29" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="250.5" y="258.29" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="286.64" y="258.29" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="322.78" y="258.29" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="358.92" y="258.29" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="502.36" y="258.29" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="538.5" y="258.29" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="574.64" y="258.29" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="610.78" y="258.29" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="646.92" y="258.29" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="682.02" y="258.29" width="18.86" height="17.77" fill="#1d70b7" />
              <rect x="178.7" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="214.84" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="250.79" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="286.93" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="322.88" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="359.02" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="395.17" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="431.31" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="466.69" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="502.84" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="538.6" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="574.74" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="611.07" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="647.21" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
              <rect x="682.6" y="365.53" width="18.45" height="18.16" fill="#e9e4e0" />
            </g>
            <rect x="668.22" y="-.15" width="17.98" height="18.28" transform="translate(668.22 686.2) rotate(-90)" fill="#cccbcb" />
            <rect x="203.47" y="78.51" width="17.98" height="18.28" transform="translate(124.81 300.11) rotate(-90)" fill="#cccbcb" />
            <rect x="59.09" y="-.15" width="17.98" height="18.28" transform="translate(59.09 77.06) rotate(-90)" fill="#cccbcb" />
            <rect x="44.63" y="575.71" width="18.35" height="18.23" transform="translate(-531.02 638.63) rotate(-90)" fill="#cccbcb" />
            <rect x="804.88" y="478.33" width="17.98" height="18.28" transform="translate(326.41 1301.33) rotate(-90)" fill="#cccbcb" />
            <rect x="672.46" y="575.87" width="17.98" height="18.28" transform="translate(96.44 1266.46) rotate(-90)" fill="#cccbcb" />
          </g>
          <MaskSvgContent />
         </svg>
      )}

      {(string === 'iconM5') && (
         <svg className="iconSvg icon5Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 597.79 341.43">
             <g>
               <rect x="79.48" y="301.94" width="111.48" height="26.29" fill="gray" />
               <polygon points="529.44 301.94 28.89 301.94 29.28 283.96 66.6 283.96 66.6 274.27 529.44 273.83 529.44 301.94" fill="#c1272d" />
               <rect x="68.45" y="194.51" width="116.55" height="79.32" fill="#e9e4e0" />
               <rect x="181.01" y="194.51" width="286.16" height="67.84" fill="#e9e4e0" />
               <rect x="305.46" y="180.5" width="162.45" height="67.84" fill="#e9e4e0" />
               <rect x="478.36" y="194.09" width="81.22" height="67.84" fill="#e9e4e0" />
               <rect x="68.41" y="238.9" width="488.97" height="35.23" fill="gray" />
               <rect x="87.12" y="129.17" width="116.55" height="52.22" transform="translate(300.67 9.89) rotate(90)" fill="#e9e4e0" />
               <rect x="106.24" y="102.42" width="13.04" height="13.2" fill="#4e4d4d" />
               <rect x="66.14" y="154.42" width="13.04" height="33.49" fill="#4e4d4d" />
               <rect x="92.7" y="154.42" width="13.04" height="33.49" fill="#4e4d4d" />
               <rect x="119.28" y="122.22" width="13.04" height="65.69" fill="#4e4d4d" />
               <rect x="158.93" y="122.22" width="13.04" height="72.29" fill="#4e4d4d" />
               <rect x="171.32" y="194.51" width="13.04" height="107.57" fill="#4e4d4d" />
               <rect x="557.38" y="134.13" width="13.04" height="126.5" fill="#4e4d4d" />
               <rect x="465.32" y="138.54" width="13.04" height="126.5" fill="#4e4d4d" />
               <rect x="398.31" y="181.31" width="12.89" height="60.34" fill="#4e4d4d" />
               <rect x="305.46" y="181.31" width="12.85" height="55.37" fill="#4e4d4d" />
               <rect x="318.31" y="102.42" width="13.04" height="72.29" fill="#4e4d4d" />
               <rect x="385.3" y="102.42" width="13.04" height="78.89" fill="#4e4d4d" />
               <rect x="171.96" y="102.42" width="13.04" height="13.2" fill="#4e4d4d" />
               <rect x="301.98" y="168.11" width="29.29" height="13.2" fill="#4e4d4d" />
               <rect x="119.28" y="88.34" width="52.68" height="13.2" fill="#4e4d4d" />
               <rect x="119.28" y="115.62" width="52.68" height="13.2" fill="#4e4d4d" />
               <rect x="331.27" y="102.42" width="57.28" height="13.2" fill="#4e4d4d" />
               <rect x="398.33" y="168.11" width="79.64" height="13.2" fill="#4e4d4d" />
               <rect x="465.32" y="194.51" width="105.09" height="13.2" fill="#4e4d4d" />
               <rect x="464.6" y="247.42" width="38.8" height="13.2" fill="#4e4d4d" />
               <rect x="451.67" y="262.36" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="438.15" y="273.83" width="13.2" height="41.2" fill="#4e4d4d" />
               <rect x="451.67" y="313.53" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="503.44" y="313.53" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="464.6" y="326.74" width="38.8" height="13.2" fill="#4e4d4d" />
               <rect x="357.72" y="247.42" width="38.8" height="13.2" fill="#4e4d4d" />
               <rect x="344.79" y="262.36" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="396.57" y="262.36" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="331.27" y="273.83" width="13.2" height="41.2" fill="#4e4d4d" />
               <rect x="411.21" y="273.83" width="13.2" height="41.2" fill="#4e4d4d" />
               <rect x="516.24" y="273.83" width="13.2" height="41.2" fill="#4e4d4d" />
               <rect x="344.79" y="313.53" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="396.57" y="313.53" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="357.72" y="326.74" width="38.8" height="13.2" fill="#4e4d4d" />
               <rect x="252.21" y="247.42" width="38.8" height="13.2" fill="#4e4d4d" />
               <rect x="239.28" y="262.36" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="291.05" y="262.36" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="225.76" y="273.83" width="13.2" height="41.2" fill="#4e4d4d" />
               <rect x="305.26" y="273.83" width="13.2" height="41.2" fill="#4e4d4d" />
               <rect x="239.28" y="313.53" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="132.32" y="195.62" width="26.61" height="12.33" fill="#4e4d4d" />
               <rect x="184.36" y="284.88" width="13.2" height="17.06" fill="#4e4d4d" />
               <rect x="291.05" y="313.53" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="252.21" y="326.74" width="38.8" height="13.2" fill="#4e4d4d" />
               <rect x="77.58" y="315.03" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="129.35" y="315.03" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="90.51" y="328.23" width="38.8" height="13.2" fill="#4e4d4d" />
               <rect x="138.83" y="315.03" width="13.2" height="13.2" fill="#4e4d4d" />
               <rect x="190.61" y="302.44" width="18.09" height="25.78" fill="#4e4d4d" />
               <rect x="151.76" y="328.23" width="38.8" height="13.2" fill="#4e4d4d" />
               <rect x="451.67" y="128.82" width="142.35" height="13.2" fill="#4e4d4d" />
               <rect x="503.39" y="260.63" width="90.62" height="15.19" fill="#4e4d4d" />
               <rect x="451.67" y="115.62" width="15.5" height="13.2" fill="#4e4d4d" />
               <rect x="578.52" y="115.62" width="15.5" height="13.2" fill="#4e4d4d" />
               <rect x="465.32" y="102.42" width="132.46" height="13.2" fill="#4e4d4d" />
               <rect x="489.64" y="89.21" width="27.5" height="13.2" fill="#4e4d4d" />
               <rect x="517.8" y="76.01" width="27.5" height="13.2" fill="#4e4d4d" />
               <rect x="66.6" y="142.08" width="39.65" height="13.2" fill="#4e4d4d" />
               <rect x="66.6" y="181.31" width="65.25" height="13.2" fill="#4e4d4d" />
               <rect x="170.71" y="181.31" width="144.31" height="13.2" fill="#4e4d4d" />
               <rect x="67.24" y="261.71" width="104.11" height="13.2" fill="#4e4d4d" />
               <rect x="66.6" y="288.74" width="107.64" height="13.2" fill="#4e4d4d" />
               <rect y="315.03" width="79.17" height="13.2" fill="#4e4d4d" />
               <rect x="28.59" y="275.56" width="38.01" height="13.2" fill="#4e4d4d" />
               <rect x="14.93" y="288.74" width="13.65" height="13.2" fill="#4e4d4d" />
               <rect x="65.83" y="301.94" width="13.65" height="13.2" fill="#4e4d4d" />
               <rect x="52.94" y="247.42" width="13.65" height="13.2" fill="#4e4d4d" />
               <rect x="52.94" y="194.75" width="13.65" height="13.2" fill="#4e4d4d" />
               <rect x="39.59" y="207.72" width="13.65" height="40.58" fill="#4e4d4d" />
               <rect x="183.07" y="274.7" width="44.01" height="13.2" fill="#4e4d4d" />
               <rect x="301.64" y="148.6" width="116.55" height="52.22" transform="translate(534.62 -185.2) rotate(90)" fill="#e9e4e0" />
               <rect x="494.71" y="124.9" width="51.75" height="86.61" transform="translate(688.8 -352.38) rotate(90)" fill="#e9e4e0" />
               <rect x="468" y="115.51" width="118.26" height="13.3" fill="#c1272d" />
               <rect x="252.48" y="260.63" width="38.52" height="66.11" fill="gray" />
               <rect x="357.86" y="260.63" width="38.52" height="66.11" fill="gray" />
               <rect x="464.54" y="260.63" width="38.52" height="66.11" fill="gray" />
               <rect x="358.37" y="260.11" width="38.52" height="67.14" transform="translate(671.32 -83.95) rotate(90)" fill="gray" />
               <rect x="464.73" y="260.11" width="38.52" height="67.14" transform="translate(777.68 -190.31) rotate(90)" fill="gray" />
               <rect x="251.76" y="260.11" width="38.52" height="67.14" transform="translate(564.71 22.66) rotate(90)" fill="gray" />
               <rect x="262.91" y="285.74" width="240.15" height="11.17" fill="#e9e4e0" />
               <rect x="165.45" y="30.28" width="56.5" height="12.8" fill="#e9e4e0" />
               <rect x="150.23" y="42.22" width="56.5" height="12.8" fill="#e9e4e0" />
               <rect x="185" y="42.22" width="56.5" height="12.8" fill="#e9e4e0" />
               <rect x="176.82" y="55.02" width="56.5" height="12.8" fill="#e9e4e0" />
               <rect x="275.04" width="56.5" height="12.8" fill="#e9e4e0" />
               <rect x="259.83" y="11.94" width="56.5" height="12.8" fill="#e9e4e0" />
               <rect x="294.59" y="11.94" width="56.5" height="12.8" fill="#e9e4e0" />
               <rect x="286.42" y="24.74" width="56.5" height="12.8" fill="#e9e4e0" />
             </g>
             <MaskSvgContent />
         </svg>
      )}

      {(string === 'iconM5_old') && (
         <svg className="iconSvg icon5Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 865 594">
             <g>
               <rect x="0" y="0" width="865" height="594" fill="none" />
               <g>
                 <rect x="87.85" y="286.61" width="17.65" height="73.79" fill="#4e4d4d" />
                 <rect x="259.79" y="261.11" width="17.86" height="18.22" fill="#e9e4e0" />
                 <rect x="72.04" y="314.59" width="16.49" height="18.66" fill="#4e4d4d" />
                 <rect x="53.77" y="305.46" width="18.06" height="35.87" fill="#e9e4e0" />
                 <rect x="647.55" y="260.4" width="18.08" height="18.13" fill="#e9e4e0" />
                 <rect x="475.13" y="143.51" width="19.1" height="65.44" fill="#e9e4e0" />
                 <rect x="431.21" y="143.51" width="18" height="65.44" fill="#e9e4e0" />
                 <rect x="386.19" y="205.02" width="134.83" height="73.92" fill="#4e4d4d" />
                 <rect x="105.69" y="315.23" width="36.86" height="16.3" fill="#e9e4e0" />
                 <rect x="142.72" y="297.16" width="18.18" height="35.01" fill="#e9e4e0" />
                 <rect x="160.61" y="297.16" width="18.18" height="35.01" fill="#e9e4e0" />
                 <rect x="718.22" y="233.53" width="19.76" height="71.71" transform="translate(997.48 -458.72) rotate(90)" fill="#e9e4e0" />
                 <rect x="719.74" y="233.07" width="17.82" height="35.93" transform="translate(979.69 -477.62) rotate(90)" fill="#e9e4e0" />
                 <rect x="179.07" y="278.37" width="603.12" height="51.23" fill="#e9e4e0" />
                 <rect x="179.41" y="320.12" width="603.24" height="29.86" fill="gray" />
                 <rect x="268.64" y="349.93" width="369.64" height="18.1" fill="#4e4d4d" />
               </g>
               <rect x="159.89" y="142.26" width="18.19" height="18.19" fill="#6eebfb" />
               <rect x="232.74" y="142.26" width="18.19" height="18.19" fill="#6eebfb" />
               <rect x="178.52" y="124.75" width="18.19" height="18.19" fill="#6eebfb" />
               <rect x="214.95" y="124.75" width="18.19" height="18.19" fill="#6eebfb" />
               <rect x="197.1" y="143.04" width="18.19" height="18.19" fill="#6eebfb" />
               <g>
                 <rect y="487.97" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="72.85" y="487.97" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="18.64" y="470.46" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="55.06" y="470.46" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="37.21" y="488.75" width="18.19" height="18.19" fill="#6eebfb" />
               </g>
               <rect x="705.96" y="432.63" width="18.19" height="18.19" fill="#6eebfb" />
               <rect x="778.8" y="432.63" width="18.19" height="18.19" fill="#6eebfb" />
               <rect x="724.59" y="415.12" width="18.19" height="18.19" fill="#6eebfb" />
               <rect x="761.02" y="415.12" width="18.19" height="18.19" fill="#6eebfb" />
               <rect x="743.17" y="433.41" width="18.19" height="18.19" fill="#6eebfb" />
               <g>
                 <rect x="505.08" y="575.02" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="577.93" y="575.02" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="523.71" y="557.52" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="560.14" y="557.52" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="542.29" y="575.81" width="18.19" height="18.19" fill="#6eebfb" />
               </g>
               <g>
                 <rect x="773.96" y="140.11" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="846.81" y="140.11" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="792.6" y="122.6" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="829.02" y="122.6" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="811.17" y="140.89" width="18.19" height="18.19" fill="#6eebfb" />
               </g>
               <g>
                 <rect x="524.12" y="17.51" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="596.97" y="17.51" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="542.76" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="579.18" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="561.33" y="18.29" width="18.19" height="18.19" fill="#6eebfb" />
               </g>
             </g>
             <MaskSvgContent />
         </svg>

      )}

      {(string === 'iconM6') && (
         <svg className="iconSvg icon6Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 865 594">
             <g>
               <rect width="865" height="594" fill="none" />
               <rect x="119" y="141.2" width="17.98" height="18.28" transform="translate(-22.35 278.32) rotate(-90)" fill="#ffe640" />
               <rect x="637.96" y="192.58" width="17.98" height="18.28" transform="translate(445.23 848.67) rotate(-90)" fill="#ffe640" />
               <g>
                 <rect x="120.69" y="1.45" width="17.98" height="18.28" transform="translate(119.09 140.27) rotate(-90)" fill="#ffe640" />
                 <rect x="801.73" y="-.15" width="17.98" height="18.28" transform="translate(801.73 819.7) rotate(-90)" fill="#ffe640" />
               </g>
               <rect x="465.86" y="160.68" width="17.98" height="18.28" transform="translate(305.03 644.66) rotate(-90)" fill="#ffe640" />
               <rect x="363.42" y="160.68" width="17.98" height="18.28" transform="translate(202.59 542.22) rotate(-90)" fill="#ffe640" />
               <rect x="334.01" y="352.96" width="17.98" height="18.28" transform="translate(-19.1 705.1) rotate(-90)" fill="#ffe640" />
               <rect x="282.15" y="316.55" width="108.75" height="86.09" transform="translate(-23.07 696.12) rotate(-90)" fill="#ffe640" />
               <rect x="367.63" y="316.94" width="108.92" height="85.71" transform="translate(62.29 781.89) rotate(-90)" fill="#ec6c0a" />
               <rect x="450.76" y="319.5" width="108.86" height="80.65" transform="translate(145.37 865.01) rotate(-90)" fill="#f6a303" />
               <rect x="131.09" y="485.39" width="17.98" height="18.28" transform="translate(-354.45 634.61) rotate(-90)" fill="#ffe640" />
               <rect x="149.16" y="485.39" width="17.98" height="18.28" transform="translate(-336.37 652.68) rotate(-90)" fill="#ffe640" />
               <rect x="166.86" y="485.39" width="17.98" height="18.28" transform="translate(-318.68 670.37) rotate(-90)" fill="#ffe640" />
               <rect x="149.16" y="467.31" width="17.98" height="18.28" transform="translate(-318.3 634.6) rotate(-90)" fill="#ffe640" />
               <rect x="149.16" y="449.24" width="17.98" height="18.28" transform="translate(-300.23 616.53) rotate(-90)" fill="#ffe640" />
               <rect x="166.88" y="431.52" width="17.93" height="18.28" transform="translate(-264.82 616.51) rotate(-90)" fill="#ffe640" />
               <rect x="166.86" y="413.38" width="17.98" height="18.28" transform="translate(-246.67 598.36) rotate(-90)" fill="#ffe640" />
               <rect x="175.54" y="386.39" width="36.76" height="18.28" transform="translate(-201.61 589.44) rotate(-90)" fill="#ffe640" />
               <rect x="203.47" y="359.06" width="17.98" height="18.28" transform="translate(-155.75 580.66) rotate(-90)" fill="#ffe640" />
               <rect x="203.47" y="341.37" width="17.98" height="18.28" transform="translate(-138.05 562.96) rotate(-90)" fill="#ffe640" />
               <rect x="221.54" y="323.11" width="17.98" height="18.28" transform="translate(-101.72 562.77) rotate(-90)" fill="#ffe640" />
               <rect x="239.7" y="305.13" width="17.98" height="18.28" transform="translate(-65.58 562.96) rotate(-90)" fill="#ffe640" />
               <rect x="257.87" y="304.85" width="17.98" height="18.28" transform="translate(-47.13 580.84) rotate(-90)" fill="#ffe640" />
               <rect x="276.13" y="305.13" width="17.98" height="18.28" transform="translate(-29.15 599.38) rotate(-90)" fill="#ffe640" />
               <rect x="184.95" y="431.52" width="17.93" height="18.28" transform="translate(-246.74 634.58) rotate(-90)" fill="#ffe640" />
               <rect x="203.02" y="431.52" width="17.93" height="18.28" transform="translate(-228.67 652.65) rotate(-90)" fill="#ffe640" />
               <rect x="248.04" y="385.94" width="17.98" height="72.96" transform="translate(-165.39 679.44) rotate(-90)" fill="#ffe640" />
               <rect x="239.52" y="395.3" width="17.98" height="18.28" transform="translate(-155.94 652.94) rotate(-90)" fill="#ffe640" />
               <rect x="257.59" y="377.7" width="17.98" height="18.28" transform="translate(-120.26 653.41) rotate(-90)" fill="#ffe640" />
               <rect x="275.28" y="395.68" width="17.98" height="18.28" transform="translate(-120.55 689.09) rotate(-90)" fill="#ffe640" />
               <rect x="239.7" y="359.16" width="17.98" height="18.28" transform="translate(-119.6 616.99) rotate(-90)" fill="#ffe640" />
               <rect x="239.7" y="341.46" width="17.98" height="18.28" transform="translate(-101.91 599.29) rotate(-90)" fill="#ffe640" />
               <rect x="689.39" y="485.39" width="17.98" height="18.28" transform="translate(203.85 1192.9) rotate(-90)" fill="#f6a303" />
               <rect x="671.32" y="485.39" width="17.98" height="18.28" transform="translate(185.78 1174.83) rotate(-90)" fill="#f6a303" />
               <rect x="653.62" y="485.39" width="17.98" height="18.28" transform="translate(168.08 1157.14) rotate(-90)" fill="#f6a303" />
               <rect x="672.45" y="467.38" width="17.98" height="18.28" transform="translate(204.92 1157.96) rotate(-90)" fill="#f6a303" />
               <rect x="672.45" y="449.6" width="17.98" height="18.28" transform="translate(222.7 1140.17) rotate(-90)" fill="#f6a303" />
               <rect x="654.78" y="431.6" width="17.93" height="18.28" transform="translate(223.01 1104.48) rotate(-90)" fill="#f6a303" />
               <rect x="654.76" y="413.73" width="17.98" height="18.28" transform="translate(240.87 1086.62) rotate(-90)" fill="#f6a303" />
               <rect x="627.29" y="386.46" width="36.76" height="18.28" transform="translate(250.08 1041.27) rotate(-90)" fill="#f6a303" />
               <rect x="618.14" y="359.14" width="17.98" height="18.28" transform="translate(258.86 995.41) rotate(-90)" fill="#f6a303" />
               <rect x="618.14" y="341.44" width="17.98" height="18.28" transform="translate(276.55 977.71) rotate(-90)" fill="#f6a303" />
               <rect x="600.07" y="323.18" width="17.98" height="18.28" transform="translate(276.74 941.38) rotate(-90)" fill="#f6a303" />
               <rect x="581.91" y="305.2" width="17.98" height="18.28" transform="translate(276.56 905.24) rotate(-90)" fill="#f6a303" />
               <rect x="563.75" y="305.2" width="17.98" height="18.28" transform="translate(258.39 887.08) rotate(-90)" fill="#f6a303" />
               <rect x="545.49" y="305.2" width="17.98" height="18.28" transform="translate(240.13 868.82) rotate(-90)" fill="#f6a303" />
               <rect x="553.77" y="250.52" width="35.85" height="18.28" transform="translate(312.03 831.35) rotate(-90)" fill="#565952" />
               <rect x="636.71" y="431.6" width="17.93" height="18.28" transform="translate(204.94 1086.41) rotate(-90)" fill="#f6a303" />
               <rect x="618.64" y="431.6" width="17.93" height="18.28" transform="translate(186.87 1068.34) rotate(-90)" fill="#f6a303" />
               <rect x="573.24" y="385.95" width="17.98" height="73.64" transform="translate(159.45 1005) rotate(-90)" fill="#f6a303" />
               <rect x="582.1" y="395.66" width="17.98" height="18.28" transform="translate(186.29 995.88) rotate(-90)" fill="#f6a303" />
               <rect x="563.74" y="377.77" width="17.98" height="18.28" transform="translate(185.82 959.64) rotate(-90)" fill="#f6a303" />
               <rect x="545.48" y="396.04" width="17.98" height="18.28" transform="translate(149.3 959.64) rotate(-90)" fill="#f6a303" />
               <rect x="581.91" y="359.23" width="17.98" height="18.28" transform="translate(222.53 959.27) rotate(-90)" fill="#f6a303" />
               <rect x="581.91" y="341.54" width="17.98" height="18.28" transform="translate(240.23 941.57) rotate(-90)" fill="#f6a303" />
               <rect x="483.6" y="160.22" width="17.96" height="58.31" fill="#565952" />
               <rect x="466.23" y="178.93" width="17.96" height="39.6" fill="#565952" />
               <rect x="448.87" y="197.2" width="17.96" height="21.33" fill="#565952" />
               <rect x="345.54" y="160.22" width="17.96" height="58.31" transform="translate(709.04 378.75) rotate(-180)" fill="#565952" />
               <rect x="362.9" y="178.93" width="17.96" height="39.6" transform="translate(743.77 397.46) rotate(-180)" fill="#565952" />
               <rect x="380.27" y="197.2" width="17.96" height="21.33" transform="translate(778.5 415.73) rotate(-180)" fill="#565952" />
               <rect x="316.78" y="414.05" width="210.62" height="17.7" fill="#565952" />
               <rect x="392.84" y="431.07" width="18.92" height="18.55" fill="#ec6c0a" />
               <rect x="432.1" y="431.22" width="18.92" height="18.55" fill="#ec6c0a" />
               <rect x="395.64" y="485.53" width="52.91" height="18.55" fill="#ec6c0a" />
               <rect x="403.93" y="458.37" width="36.33" height="18.55" transform="translate(889.74 45.55) rotate(90)" fill="#ec6c0a" />
               <rect x="375.82" y="449.43" width="92.54" height="18.55" fill="#ec6c0a" />
               <rect x="284.75" y="218.68" width="27.66" height="17.32" fill="#565952" />
               <rect x="392.37" y="98.38" width="59.44" height="18.07" fill="gray" />
               <rect x="404.41" y="80.69" width="35.35" height="18.07" fill="gray" />
               <rect x="281.55" y="98.38" width="65.82" height="18.07" fill="gray" />
               <rect x="292.12" y="80.69" width="35.35" height="18.07" fill="gray" />
               <rect x="300.59" y="123.6" width="35.35" height="18.07" transform="translate(450.91 -185.63) rotate(90)" fill="gray" />
               <rect x="404.41" y="272.95" width="35.35" height="18.07" transform="translate(704.08 -140.1) rotate(90)" fill="#565952" />
               <rect x="381.12" y="160.5" width="17.11" height="36.7" fill="#ffe640" />
               <rect x="448.59" y="160.5" width="17.11" height="36.7" fill="#ffe640" />
               <rect x="755.99" y="546.09" width="17.98" height="18.28" transform="translate(209.75 1320.2) rotate(-90)" fill="gray" />
               <rect x="107.14" y="575.87" width="17.98" height="18.28" transform="translate(-468.88 701.14) rotate(-90)" fill="gray" />
               <rect x="518.71" y="243.87" width="69.26" height="18.28" transform="translate(300.33 806.35) rotate(-90)" fill="#e9e4e0" />
               <rect x="518.83" y="197.41" width="21.33" height="20.92" transform="translate(321.63 737.36) rotate(-90)" fill="#e9e4e0" />
               <rect x="467.76" y="167.2" width="84.39" height="18.28" transform="translate(333.62 686.29) rotate(-90)" fill="#e9e4e0" />
               <rect x="294.86" y="167.2" width="84.39" height="18.28" transform="translate(160.72 513.39) rotate(-90)" fill="#e9e4e0" />
               <rect x="399.73" y="59.53" width="44.71" height="158.35" transform="translate(283.39 560.8) rotate(-90)" fill="#e9e4e0" />
               <rect x="395.12" y="161.06" width="53.94" height="57.32" fill="#e9e4e0" />
               <rect x="411.92" y="332.52" width="20.34" height="116.68" fill="#e9e4e0" />
               <rect x="321.93" y="287.18" width="51.57" height="17.32" fill="#e9e4e0" />
               <rect x="321.93" y="270.24" width="51.57" height="17.32" fill="#e9e4e0" />
               <rect x="301.97" y="253.11" width="72.02" height="17.32" fill="#e9e4e0" />
               <rect x="310.5" y="235.8" width="63.49" height="17.32" fill="#e9e4e0" />
               <rect x="310.7" y="218.85" width="63.29" height="17.32" fill="#e9e4e0" />
               <rect x="372.4" y="218.55" width="171.67" height="85.95" fill="#e9e4e0" />
               <rect x="396.58" y="304.59" width="51.01" height="18.07" fill="#e9e4e0" />
             </g>
             <MaskSvgContent />

         </svg>
      )}

      {(string === 'iconM7') && (
         <svg className="iconSvg icon7Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 865 594">
             <g>
               <rect y="0" width="865" height="594" fill="none" />
               <g>
                 <rect x="0" y="496.44" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="72.85" y="496.44" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="18.64" y="478.93" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="55.06" y="478.93" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="37.21" y="497.22" width="18.19" height="18.19" fill="#6eebfb" />
               </g>
               <g>
                 <rect x="773.96" y="498.13" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="846.81" y="498.13" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="792.6" y="480.63" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="829.02" y="480.63" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="811.17" y="498.92" width="18.19" height="18.19" fill="#6eebfb" />
               </g>
               <g>
                 <rect x="574.2" y="573.33" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="647.05" y="573.33" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="592.84" y="555.82" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="629.26" y="555.82" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="611.41" y="574.11" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="162.53" y="575.02" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="235.38" y="575.02" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="181.17" y="557.52" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="217.59" y="557.52" width="18.19" height="18.19" fill="#6eebfb" />
                 <rect x="199.74" y="575.81" width="18.19" height="18.19" fill="#6eebfb" />
               </g>
               <g>
                 <polygon points="639.23 359.92 639.23 395.6 504.46 395.6 504.46 430.99 199.67 430.99 199.67 424.69 157.78 424.69 157.78 468.19 199.67 468.19 199.67 486.09 504.46 486.09 508.2 486.09 639.23 486.09 641.13 486.09 742.08 486.09 742.08 359.92 639.23 359.92" fill="#e9e4e0" />
                 <g>
                   <rect x="136.04" y="413.67" width="612.29" height="18.87" fill="gray" />
                   <rect x="505.46" y="378.35" width="241.83" height="18.83" fill="gray" />
                   <rect x="181.49" y="467.98" width="18.11" height="18.1" fill="#4e4d4d" />
                   <rect x="144.84" y="450.04" width="36.57" height="18.15" fill="#4e4d4d" />
                   <rect x="126.67" y="432.46" width="36.02" height="18.47" fill="#4e4d4d" />
                   <rect x="199.07" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="235.21" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="271.63" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="307.78" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="343.35" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="379.49" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="415.35" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="451.49" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="486.79" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="522.93" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="559.07" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="595.21" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="631.63" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="667.77" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="703.35" y="432.65" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="522.93" y="397.07" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="559.07" y="397.07" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="595.21" y="397.07" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="631.63" y="397.07" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="667.77" y="397.07" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="703.35" y="397.07" width="17.25" height="17.47" fill="#4e4d4d" />
                   <rect x="657.57" width="18.31" height="351.19" fill="gray" />
                   <rect x="532.62" y="342.35" width="143.56" height="17.61" fill="gray" />
                   <rect x="315.77" y="342.35" width="143.56" height="17.61" fill="gray" />
                   <rect x="640.51" y=".09" width="17.08" height="18.19" fill="gray" />
                   <rect x="622.6" y="17.73" width="18.87" height="37.05" fill="gray" />
                   <rect x="603.97" y="54.16" width="18.87" height="37.05" fill="gray" />
                   <rect x="585.61" y="90.3" width="18.87" height="37.05" fill="gray" />
                   <rect x="567.54" y="126.16" width="18.87" height="37.05" fill="gray" />
                   <rect x="549.47" y="162.3" width="18.87" height="37.05" fill="gray" />
                   <rect x="531.68" y="198.44" width="18.87" height="37.05" fill="gray" />
                   <rect x="513.33" y="234.86" width="19.29" height="107.49" fill="gray" />
                   <rect x="441" width="18.31" height="396.84" fill="gray" />
                   <rect x="423.66" y=".09" width="17.08" height="18.19" fill="gray" />
                   <rect x="405.75" y="17.73" width="18.87" height="37.05" fill="gray" />
                   <rect x="387.12" y="54.16" width="18.87" height="37.05" fill="gray" />
                   <rect x="368.77" y="90.3" width="18.87" height="37.05" fill="gray" />
                   <rect x="350.7" y="126.16" width="18.87" height="37.05" fill="gray" />
                   <rect x="332.63" y="162.3" width="18.87" height="37.05" fill="gray" />
                   <rect x="314.84" y="198.44" width="18.87" height="37.05" fill="gray" />
                   <rect x="296.48" y="234.86" width="19.29" height="107.49" fill="gray" />
                   <rect x="119.25" y="342.35" width="143.56" height="17.61" fill="gray" />
                   <rect x="244.49" width="18.31" height="396.84" fill="gray" />
                   <rect x="227.14" y=".09" width="17.08" height="18.19" fill="gray" />
                   <rect x="209.24" y="17.73" width="18.87" height="37.05" fill="gray" />
                   <rect x="190.6" y="54.16" width="18.87" height="37.05" fill="gray" />
                   <rect x="172.25" y="90.3" width="18.87" height="37.05" fill="gray" />
                   <rect x="154.18" y="126.16" width="18.87" height="37.05" fill="gray" />
                   <rect x="136.11" y="162.3" width="18.87" height="37.05" fill="gray" />
                   <rect x="118.32" y="198.44" width="18.87" height="37.05" fill="gray" />
                   <rect x="99.97" y="234.86" width="19.29" height="107.49" fill="gray" />
                   <rect x="99.68" y="396.39" width="82.88" height="17.85" fill="#c1272d" />
                   <rect x="45.99" y="377.86" width="72.52" height="19.06" fill="#c1272d" />
                   <rect x="28.13" y="360.76" width="71.62" height="17.25" fill="#c1272d" />
                   <rect x="182.55" y="396.45" width="322.77" height="18.01" fill="#4e4d4d" />
                   <rect x="619.88" y="342.54" width="136.55" height="17.42" fill="#4e4d4d" />
                   <rect x="523.71" y="359.92" width="115.52" height="18.72" fill="#4e4d4d" />
                   <rect x="199.67" y="486.09" width="542.4" height="17.88" fill="#4e4d4d" />
                   <rect x="739.52" y="359.01" width="16.77" height="145.1" fill="#4e4d4d" />
                 </g>
                 <rect x="119.25" y="234.86" width="126.07" height="107.68" fill="#e9e4e0" />
                 <rect x="136.19" y="199.29" width="108.98" height="35.7" fill="#e9e4e0" />
                 <rect x="154.23" y="163.71" width="90.94" height="35.7" fill="#e9e4e0" />
                 <rect x="172.76" y="127.85" width="72.41" height="35.7" fill="#e9e4e0" />
                 <rect x="190.13" y="91.71" width="55.04" height="35.7" fill="#e9e4e0" />
                 <rect x="209.44" y="54.44" width="35.73" height="39.23" fill="#e9e4e0" />
                 <rect x="227.31" y="18.01" width="17.86" height="36.57" fill="#e9e4e0" />
                 <rect x="316.62" y="234.86" width="126.07" height="107.69" fill="#e9e4e0" />
                 <rect x="333.56" y="199.29" width="108.98" height="35.7" fill="#e9e4e0" />
                 <rect x="351.6" y="163.71" width="90.94" height="35.7" fill="#e9e4e0" />
                 <rect x="370.12" y="127.85" width="72.41" height="35.7" fill="#e9e4e0" />
                 <rect x="387.49" y="91.71" width="55.04" height="35.7" fill="#e9e4e0" />
                 <rect x="406.81" y="54.44" width="35.73" height="39.23" fill="#e9e4e0" />
                 <rect x="424.67" y="18.01" width="17.86" height="36.57" fill="#e9e4e0" />
                 <rect x="533.46" y="234.58" width="126.07" height="107.29" fill="#e9e4e0" />
                 <rect x="550.41" y="199" width="108.98" height="35.7" fill="#e9e4e0" />
                 <rect x="568.44" y="163.43" width="90.94" height="35.7" fill="#e9e4e0" />
                 <rect x="586.97" y="127.57" width="72.41" height="35.7" fill="#e9e4e0" />
                 <rect x="604.34" y="91.71" width="55.04" height="35.7" fill="#e9e4e0" />
                 <rect x="623.65" y="54.44" width="35.73" height="39.23" fill="#e9e4e0" />
                 <rect x="641.52" y="18.01" width="17.86" height="36.57" fill="#e9e4e0" />
               </g>
             </g>
             <MaskSvgContent />

         </svg>
      )}

      {(string === 'iconM8') && (
         <svg className="iconSvg icon8Svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 865 594">
             <g>
               <rect width="865" height="594" fill="none" />
               <g>
                 <rect x="341.91" y="390.34" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="321.5" y="390.34" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="301.1" y="390.34" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="280.69" y="390.34" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="260.28" y="390.34" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="362.31" y="369.93" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.91" y="369.93" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="321.5" y="369.93" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="301.1" y="369.93" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="280.69" y="369.93" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="260.28" y="369.93" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="239.88" y="369.93" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.72" y="349.62" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="362.31" y="349.62" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.91" y="349.62" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="321.5" y="349.62" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="301.1" y="349.62" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="280.69" y="349.62" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="260.28" y="349.62" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="239.88" y="349.62" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="219.47" y="349.62" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.72" y="330.09" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="362.31" y="330.09" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.91" y="330.09" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="321.5" y="330.09" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="280.69" y="330.09" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="260.28" y="330.09" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="239.88" y="330.09" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="219.47" y="330.09" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.72" y="309.68" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="362.31" y="309.68" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.91" y="309.68" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="260.28" y="309.68" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="239.88" y="309.68" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="219.47" y="309.68" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.72" y="289.28" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="362.31" y="289.28" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.91" y="289.28" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="321.5" y="289.28" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="280.69" y="289.28" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="260.28" y="289.28" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="239.88" y="289.28" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="219.47" y="289.28" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.72" y="268.87" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="362.31" y="268.87" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.91" y="268.87" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="321.5" y="268.87" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="301.1" y="268.87" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="280.69" y="268.87" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="260.28" y="268.87" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="239.88" y="268.87" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="219.47" y="268.87" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="362.31" y="248.46" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.91" y="248.46" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="321.5" y="248.46" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="301.1" y="248.46" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="280.69" y="248.46" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="260.28" y="248.46" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="239.88" y="248.46" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.91" y="228.06" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="321.5" y="228.06" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="301.1" y="228.06" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="280.69" y="228.06" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="260.28" y="228.06" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="300.83" y="207.79" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="300.83" y="187.51" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="260.28" y="207.79" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.91" y="207.79" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="402.59" y="311.49" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="422.87" y="311.49" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="402.59" y="268.87" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="402.59" y="349.62" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="199.5" y="309.07" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="179.22" y="309.07" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="199.5" y="349.62" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="199.5" y="268.87" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="300.86" y="410.81" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="300.86" y="431.08" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.91" y="410.81" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="260.28" y="410.81" width="20.41" height="20.41" fill="#e9e4e0" />
                 <g>
                   <rect x="529.17" y="532.85" width="20.41" height="20.41" fill="gray" />
                   <rect x="508.77" y="532.85" width="20.41" height="20.41" fill="gray" />
                   <rect x="488.36" y="532.85" width="20.41" height="20.41" fill="gray" />
                   <rect x="467.95" y="532.85" width="20.41" height="20.41" fill="gray" />
                   <rect x="447.55" y="532.85" width="20.41" height="20.41" fill="gray" />
                   <rect x="549.58" y="512.45" width="20.41" height="20.41" fill="gray" />
                   <rect x="529.17" y="512.45" width="20.41" height="20.41" fill="gray" />
                   <rect x="508.77" y="512.45" width="20.41" height="20.41" fill="gray" />
                   <rect x="488.36" y="512.45" width="20.41" height="20.41" fill="gray" />
                   <rect x="467.95" y="512.45" width="20.41" height="20.41" fill="gray" />
                   <rect x="447.55" y="512.45" width="20.41" height="20.41" fill="gray" />
                   <rect x="427.14" y="512.45" width="20.41" height="20.41" fill="gray" />
                   <rect x="569.98" y="492.13" width="20.41" height="20.41" fill="gray" />
                   <rect x="549.58" y="492.13" width="20.41" height="20.41" fill="gray" />
                   <rect x="529.17" y="492.13" width="20.41" height="20.41" fill="gray" />
                   <rect x="508.77" y="492.13" width="20.41" height="20.41" fill="gray" />
                   <rect x="488.36" y="492.13" width="20.41" height="20.41" fill="gray" />
                   <rect x="467.95" y="492.13" width="20.41" height="20.41" fill="gray" />
                   <rect x="447.55" y="492.13" width="20.41" height="20.41" fill="gray" />
                   <rect x="427.14" y="492.13" width="20.41" height="20.41" fill="gray" />
                   <rect x="406.73" y="492.13" width="20.41" height="20.41" fill="gray" />
                   <rect x="569.98" y="472.6" width="20.41" height="20.41" fill="gray" />
                   <rect x="549.58" y="472.6" width="20.41" height="20.41" fill="gray" />
                   <rect x="529.17" y="472.6" width="20.41" height="20.41" fill="gray" />
                   <rect x="508.77" y="472.6" width="20.41" height="20.41" fill="gray" />
                   <rect x="467.95" y="472.6" width="20.41" height="20.41" fill="gray" />
                   <rect x="447.55" y="472.6" width="20.41" height="20.41" fill="gray" />
                   <rect x="427.14" y="472.6" width="20.41" height="20.41" fill="gray" />
                   <rect x="406.73" y="472.6" width="20.41" height="20.41" fill="gray" />
                   <rect x="569.98" y="452.2" width="20.41" height="20.41" fill="gray" />
                   <rect x="549.58" y="452.2" width="20.41" height="20.41" fill="gray" />
                   <rect x="529.17" y="452.2" width="20.41" height="20.41" fill="gray" />
                   <rect x="447.55" y="452.2" width="20.41" height="20.41" fill="gray" />
                   <rect x="427.14" y="452.2" width="20.41" height="20.41" fill="gray" />
                   <rect x="406.73" y="452.2" width="20.41" height="20.41" fill="gray" />
                   <rect x="569.98" y="431.79" width="20.41" height="20.41" fill="gray" />
                   <rect x="549.58" y="431.79" width="20.41" height="20.41" fill="gray" />
                   <rect x="529.17" y="431.79" width="20.41" height="20.41" fill="gray" />
                   <rect x="508.77" y="431.79" width="20.41" height="20.41" fill="gray" />
                   <rect x="467.95" y="431.79" width="20.41" height="20.41" fill="gray" />
                   <rect x="447.55" y="431.79" width="20.41" height="20.41" fill="gray" />
                   <rect x="427.14" y="431.79" width="20.41" height="20.41" fill="gray" />
                   <rect x="406.73" y="431.79" width="20.41" height="20.41" fill="gray" />
                   <rect x="569.98" y="411.39" width="20.41" height="20.41" fill="gray" />
                   <rect x="549.58" y="411.39" width="20.41" height="20.41" fill="gray" />
                   <rect x="529.17" y="411.39" width="20.41" height="20.41" fill="gray" />
                   <rect x="508.77" y="411.39" width="20.41" height="20.41" fill="gray" />
                   <rect x="488.36" y="411.39" width="20.41" height="20.41" fill="gray" />
                   <rect x="467.95" y="411.39" width="20.41" height="20.41" fill="gray" />
                   <rect x="447.55" y="411.39" width="20.41" height="20.41" fill="gray" />
                   <rect x="427.14" y="411.39" width="20.41" height="20.41" fill="gray" />
                   <rect x="406.73" y="411.39" width="20.41" height="20.41" fill="gray" />
                   <rect x="549.58" y="390.98" width="20.41" height="20.41" fill="gray" />
                   <rect x="529.17" y="390.98" width="20.41" height="20.41" fill="gray" />
                   <rect x="508.77" y="390.98" width="20.41" height="20.41" fill="gray" />
                   <rect x="488.36" y="390.98" width="20.41" height="20.41" fill="gray" />
                   <rect x="467.95" y="390.98" width="20.41" height="20.41" fill="gray" />
                   <rect x="447.55" y="390.98" width="20.41" height="20.41" fill="gray" />
                   <rect x="427.14" y="390.98" width="20.41" height="20.41" fill="gray" />
                   <rect x="480.71" y="385.11" width="20.41" height="20.41" fill="gray" />
                   <rect x="508.77" y="370.57" width="20.41" height="20.41" fill="gray" />
                   <rect x="488.36" y="370.57" width="20.41" height="20.41" fill="gray" />
                   <rect x="467.95" y="370.57" width="20.41" height="20.41" fill="gray" />
                   <rect x="447.55" y="370.57" width="20.41" height="20.41" fill="gray" />
                   <rect x="488.09" y="350.3" width="20.41" height="20.41" fill="gray" />
                   <rect x="488.09" y="329.7" width="20.41" height="20.41" fill="gray" />
                   <rect x="447.55" y="350.3" width="20.41" height="20.41" fill="gray" />
                   <rect x="527.99" y="370.74" width="20.41" height="20.41" fill="gray" />
                   <rect x="527.99" y="351.43" width="20.41" height="20.41" fill="gray" />
                   <rect x="589.86" y="454.01" width="20.41" height="20.41" fill="gray" />
                   <rect x="610.13" y="454.01" width="20.41" height="20.41" fill="gray" />
                   <rect x="589.86" y="411.39" width="20.41" height="20.41" fill="gray" />
                   <rect x="589.86" y="492.13" width="20.41" height="20.41" fill="gray" />
                   <rect x="386.76" y="451.59" width="20.41" height="20.41" fill="gray" />
                   <rect x="366.49" y="451.59" width="20.41" height="20.41" fill="gray" />
                   <rect x="386.76" y="492.13" width="20.41" height="20.41" fill="gray" />
                   <rect x="386.76" y="411.39" width="20.41" height="20.41" fill="gray" />
                   <rect x="488.12" y="553.32" width="20.41" height="20.41" fill="gray" />
                   <rect x="488.12" y="573.59" width="20.41" height="20.41" fill="gray" />
                   <rect x="529.17" y="553.32" width="20.41" height="20.41" fill="gray" />
                   <rect x="447.55" y="553.32" width="20.41" height="20.41" fill="gray" />
                 </g>
                 <rect x="463.76" y="202.83" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="443.36" y="202.83" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="422.95" y="202.83" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="402.54" y="202.83" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.14" y="202.83" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="484.17" y="182.42" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="463.76" y="182.42" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="443.36" y="182.42" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="422.95" y="182.42" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="402.54" y="182.42" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.14" y="182.42" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="361.73" y="182.42" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="504.58" y="162.11" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="484.17" y="162.11" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="463.76" y="162.11" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="443.36" y="162.11" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="422.95" y="162.11" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="402.54" y="162.11" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.14" y="162.11" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="361.73" y="162.11" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.33" y="162.11" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="504.58" y="142.58" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="484.17" y="142.58" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="463.76" y="142.58" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="443.36" y="142.58" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="402.54" y="142.58" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.14" y="142.58" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="361.73" y="142.58" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.33" y="142.58" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="504.58" y="122.17" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="484.17" y="122.17" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="463.76" y="122.17" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.14" y="122.17" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="361.73" y="122.17" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.33" y="122.17" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="504.58" y="101.76" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="484.17" y="101.76" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="463.76" y="101.76" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="443.36" y="101.76" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="402.54" y="101.76" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.14" y="101.76" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="361.73" y="101.76" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.33" y="101.76" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="504.58" y="81.36" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="484.17" y="81.36" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="463.76" y="81.36" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="443.36" y="81.36" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="422.95" y="81.36" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="402.54" y="81.36" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.14" y="81.36" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="361.73" y="81.36" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="341.33" y="81.36" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="484.17" y="60.95" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="463.76" y="60.95" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="443.36" y="60.95" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="422.95" y="60.95" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="402.54" y="60.95" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.14" y="60.95" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="361.73" y="60.95" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="463.76" y="40.55" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="443.36" y="40.55" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="422.95" y="40.55" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="402.54" y="40.55" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.14" y="40.55" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="422.68" y="20.27" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="422.68" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.14" y="20.27" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="463.76" y="20.27" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="524.45" y="123.98" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="544.72" y="123.98" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="524.45" y="81.36" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="524.45" y="162.11" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="321.35" y="121.56" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="301.08" y="121.56" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="321.35" y="162.11" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="321.35" y="81.36" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="422.72" y="223.29" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="422.72" y="243.57" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="463.76" y="223.29" width="20.41" height="20.41" fill="#e9e4e0" />
                 <rect x="382.14" y="223.29" width="20.41" height="20.41" fill="#e9e4e0" />
               </g>
             </g>
             <MaskSvgContent />

         </svg>
      )}


    </>
  )
}
