import React from "react";
import SimpleBarReact from 'simplebar-react';
import { ApproContent } from "./content/ApproContent"

import "./../assets/scss/Appro.scss";
import 'simplebar-react/dist/simplebar.min.css';


export const Appro = (props) => {
   return (
      <div className={`extra ${props.content} ${props.open ? "open" : ""}`}>
         <div className='approContent'>
            {props.content === 'icosaedro' && (
               <Icosaedro lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />
            )}
            {props.content === 'filettatrice' && (
               <Filettatrice lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />
            )}
            {props.content === 'elea' && (
               <Elea lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />
            )}
            {props.content === 'programma101' && (
               <Programma101 lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />
            )}
            {props.content === 'apollo11' && (
               <Apollo11 lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />
            )}
            {props.content === 'kreched' && (
               <Kreched lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />
            )}
            {props.content === 'toti' && (
               <Toti lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />
            )}
            {props.content === 'ebe' && (
               <Ebe lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />
            )}
            {props.content === 'biancamano' && (
               <Biancamano lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />
            )}
            {props.content === 'convertiplano' && (
               <Convertiplano lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />
            )}
            {props.content === 'v2' && (
               <V2 lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />
            )}
            {props.content === 'credits' && (
               <Credits lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />
            )}
         </div>

         <button className="cta close" onClick={props.onClose}>
            <svg className="closesvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 74">
               <rect className="cls-1" width="74" height="74" x1="18.87" y1="30.25"/>
               <line className="cls-2" x1="18.87" y1="30.25" x2="56.87" y2="30.25"/>
               <line className="cls-3" x1="18.87" y1="38.04" x2="56.87" y2="38.04"/>
               <line className="cls-4" x1="18.87" y1="45.84" x2="56.87" y2="45.84"/>
            </svg>
         </button>
      </div>
   );
};

const Icosaedro = (props) => {
   return (
      <div className="col-12 extra-right">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12 col-text">
                  <ApproContent lang={props.lang} stringToRet="icosaedroTitle" />
                  <img src={require('../assets/images/appro/icosaedro.jpg')} alt="Icosaedro" />
                  <ApproContent lang={props.lang} stringToRet="icosaedroSubtitle" />
                  <ApproContent lang={props.lang} stringToRet="icosaedroText" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}

const Credits = (props) => {
   return (
      <div className="col-12 extra-right">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12 col-text">
                  <ApproContent lang={props.lang} stringToRet="creditsTitle" />
                  <ApproContent lang={props.lang} stringToRet="creditsText" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}

const Filettatrice = (props) => {
   return (
      <div className="col-12 extra-right">
      <ApproContent lang={props.lang} stringToRet="filettatriceTitle" />
         <SimpleBarReact style={{ maxHeight: 'calc(100% - 60px)' }}>
            <div className="row">
               <div className="col-12 col-text">

                  <img src={require('../assets/images/appro/filettatrice.jpg')} alt="filettatrice" />
                  <ApproContent lang={props.lang} stringToRet="filettatriceSubtitle" />
                  <ApproContent lang={props.lang} stringToRet="filettatriceText" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}

const Elea = (props) => {
   return (
      <div className="col-12 extra-right">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12 col-text">
                  <ApproContent lang={props.lang} stringToRet="eleaTitle" />
                  <img src={require('../assets/images/appro/elea.jpg')} alt="elea" />
                  <ApproContent lang={props.lang} stringToRet="eleaSubtitle" />
                  <ApproContent lang={props.lang} stringToRet="eleaText" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}

const Programma101 = (props) => {
   return (
      <div className="col-12 extra-right">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12 col-text">
                  <ApproContent lang={props.lang} stringToRet="programma101Title" />
                  <img src={require('../assets/images/appro/programma101.jpg')} alt="programma 101" />
                  <ApproContent lang={props.lang} stringToRet="programma101Subtitle" />
                  <ApproContent lang={props.lang} stringToRet="programma101Text" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}

const Apollo11 = (props) => {
   return (
      <div className="col-12 extra-right">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12 col-text">
                  <ApproContent lang={props.lang} stringToRet="apollo11Title" />
                  <img src={require('../assets/images/appro/apollo11.jpg')} alt="apollo 11" />
                  <ApproContent lang={props.lang} stringToRet="apollo11Subtitle" />
                  <ApproContent lang={props.lang} stringToRet="apollo11Text" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}

const Kreched = (props) => {
   return (
      <div className="col-12 extra-right">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12 col-text">
                  <ApproContent lang={props.lang} stringToRet="krechedTitle" />
                  <img src={require('../assets/images/appro/kreched.jpg')} alt="Kreched" />
                  <ApproContent lang={props.lang} stringToRet="krechedSubtitle" />
                  <ApproContent lang={props.lang} stringToRet="krechedText" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}

const Toti = (props) => {
   return (
      <div className="col-12 extra-right">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12 col-text">
                  <ApproContent lang={props.lang} stringToRet="totiTitle" />
                  <img src={require('../assets/images/appro/s685.jpg')} alt="Toti" />
                  <ApproContent lang={props.lang} stringToRet="totiSubtitle" />
                  <ApproContent lang={props.lang} stringToRet="totiText" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}

const Ebe = (props) => {
   return (
      <div className="col-12 extra-right">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12 col-text">
                  <ApproContent lang={props.lang} stringToRet="ebeTitle" />
                  <img src={require('../assets/images/appro/ebe.jpg')} alt="Ebe" />
                  <ApproContent lang={props.lang} stringToRet="ebeSubtitle" />
                  <ApproContent lang={props.lang} stringToRet="ebeText" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}

const Biancamano = (props) => {
   return (
      <div className="col-12 extra-right">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12 col-text">
                  <ApproContent lang={props.lang} stringToRet="biancamanoTitle" />
                  <img src={require('../assets/images/appro/biancamano.jpg')} alt="Biancamano" />
                  <ApproContent lang={props.lang} stringToRet="biancamanoSubtitle" />
                  <ApproContent lang={props.lang} stringToRet="biancamanoText" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}

const Convertiplano = (props) => {
   return (
      <div className="col-12 extra-right">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12 col-text">
                  <ApproContent lang={props.lang} stringToRet="convertiplanoTitle" />
                  <img src={require('../assets/images/appro/convertiplano.jpg')} alt="Convertiplano" />
                  <ApproContent lang={props.lang} stringToRet="convertiplanoSubtitle" />
                  <ApproContent lang={props.lang} stringToRet="convertiplanoText" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}

const V2 = (props) => {
   return (
      <div className="col-12 extra-right">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12 col-text">
                  <ApproContent lang={props.lang} stringToRet="v2Title" />
                  <img src={require('../assets/images/appro/v2.jpg')} alt="V2" />
                  <ApproContent lang={props.lang} stringToRet="v2Subtitle" />
                  <ApproContent lang={props.lang} stringToRet="v2Text" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}
